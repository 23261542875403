import Obfuscate from "react-obfuscate";
import styled, { css } from "styled-components";
import theme from "styled-theming";
import { Link as MuiLink } from "@mui/material";
import { Link as ReactRouterDomLink } from "react-router-dom";

const linkColor = theme("theme", {
  undefined: "--color-cyan-d05",
  default: "--color-cyan-d05",
  white: "--color-cyan-d05",
  lightgray: "--color-cyan-d05",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

const borderColor = theme("theme", {
  undefined: "--color-cyan-d05-o30",
  default: "--color-cyan-d05-o30",
  white: "--color-cyan-d05-o30",
  lightgray: "--color-cyan-d05-o30",
  blue: "--color-cyan-l60-o30",
  cyan: "--color-blue-o30",
});

export const base = css<StyledLinkProps>`
  && {
    color: var(${linkColor});
    cursor: pointer;
    transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;

    span {
      transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
    }

    ${(props) =>
      props.$underline !== "always" &&
      css`
        text-decoration: none;
      `}

    ${(props) =>
      props.$hoverSpan &&
      css`
        text-decoration: none;
        span {
          border-bottom: 1px solid var(${borderColor});
          text-decoration: none;
        }
      `}

    &.active {
      ${(props) =>
        props.$hoverSpan &&
        css`
          span {
            border-bottom: 1px solid var(${borderColor});
          }
        `}
    }

    &:hover {
      color: inherit;
      ${(props) =>
        props.$hoverSpan &&
        css`
          span {
            border-bottom-color: var(${linkColor});
          }
        `}
    }
  }
`;

export const StyledLink = styled(MuiLink)<StyledLinkProps>`
  ${base}
`;

export const ReactRouterLink = styled(ReactRouterDomLink)<StyledLinkProps>`
  ${base}
`;

export const ObfuscatedLink = styled(Obfuscate)<StyledLinkProps>`
  ${base}
`;

interface StyledLinkProps {
  $hoverSpan?: boolean;
  $underline?: "always" | "hover" | "none";
}
