import React, { useEffect, ReactElement } from "react";
import StockChartProps from "./StockChartProps";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
  DefaultStockChartsOptions,
  LocalizationEnglish,
  LocalizationSwedish,
  HighChartsTheme,
} from "utils/settings";
import { merge } from "lodash";
import Section from "components/Section";
import { BREAKPOINT_VALUE } from "style/breakpoints";
import Skeleton from "components/Skeleton";

const smallScreenSizeOptions: Highcharts.Options = {
  chart: {
    spacingRight: 0,
    spacingLeft: 4,
  },
  yAxis: { labels: { x: 4 } },
  legend: { margin: 8 },
};

const largeScreenSizeOptions: Highcharts.Options = {
  chart: {
    spacingRight: 10,
    spacingLeft: 10,
  },
  yAxis: { labels: { x: 15 } },
  legend: { margin: 30 },
};

const StockChart = ({
  customOptions,
  culture,
  loading,
  contentId,
  className,
  anchorName,
}: StockChartProps): ReactElement => {
  switch (culture) {
    case "en-US":
      HighChartsTheme.lang = LocalizationEnglish;
      break;
    case "sv-SE":
    default:
      HighChartsTheme.lang = LocalizationSwedish;
      break;
  }

  Highcharts.setOptions(HighChartsTheme);

  const smallScreen = matchMedia(`(max-width: ${BREAKPOINT_VALUE.M}px)`);

  const options = React.useMemo(() => {
    const resizeOptions = smallScreen.matches
      ? smallScreenSizeOptions
      : largeScreenSizeOptions;

    return merge({}, DefaultStockChartsOptions, resizeOptions, customOptions);
  }, [smallScreen.matches, customOptions]);

  return (
    <Section
      className={className}
      id={contentId || anchorName || "chart-container"}
      style={{ display: "grid" }}
    >
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height="400px" />
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={options}
        />
      )}
    </Section>
  );
};
export default React.memo(StockChart);
