/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from "react";
import LinkProps, { OfuscatedLinkProps } from "./LinkProps";
import classNames from "classnames";
import { ReactRouterLink, ObfuscatedLink, StyledLink } from "./Link.styled";
import { useLocation } from "react-router-dom";

const Link = ({
  children,
  url,
  className,
  hoverSpan = false,
  ariaCurrent,
  href,
  ariaLabel,
  ...restProps
}: LinkProps): ReactElement => {
  restProps.to = href || url || restProps.to || "";
  const classes = classNames(className, { hoverspan: hoverSpan });

  let isReactRouterDom = false;
  try {
    const path = useLocation().pathname;
    isReactRouterDom = path !== undefined;
  } catch (e) {}

  if (!isReactRouterDom || restProps.component) {
    return (
      <StyledLink
        {...restProps}
        href={href || restProps.to}
        target={restProps.target || "_self"}
        className={classes}
        $hoverSpan={hoverSpan}
        $underline={restProps.underline || "always"}
        aria-current={ariaCurrent}
        aria-label={ariaLabel}
      >
        {children}
      </StyledLink>
    );
  } else {
    return (
      <ReactRouterLink
        to={restProps.to}
        className={classes}
        target={restProps.target || "_self"}
        onClick={restProps.onClick}
        onMouseEnter={restProps.onMouseEnter}
        onMouseLeave={restProps.onMouseLeave}
        onTouchStart={restProps.onTouchStart}
        onTouchEnd={restProps.onTouchEnd}
        aria-label={ariaLabel}
        aria-current={ariaCurrent}
        $hoverSpan={hoverSpan}
        $underline={restProps.underline || "always"}
        title={restProps.title}
        style={restProps.style}
        id={restProps.id}
      >
        {children}
      </ReactRouterLink>
    );
  }
};

export const Obfuscated = ({
  ariaLabel = "focus to reveal obfuscated content",
  ariaCurrent,
  title,
  id,
  email,
  tel,
  obfuscateChildren = true,
  children,
  hoverSpan = false,
  className,
  style = {},
  tabIndex = 0,
  ...restProps
}: OfuscatedLinkProps): ReactElement => {
  style = { direction: "ltr", ...style };
  const classes = classNames(className, { hoverspan: hoverSpan });

  // Fix strange mailto links
  email =
    email && email.replace(/:25$/, "").replace("mailto:", "").replace("/", "");

  return (
    <ObfuscatedLink
      className={classes}
      email={email}
      tel={tel}
      aria-label={ariaLabel}
      aria-current={ariaCurrent}
      title={title || ""}
      style={style}
      tabIndex={tabIndex}
      $hoverSpan={hoverSpan}
      $underline={restProps.underline || "always"}
      obfuscateChildren={obfuscateChildren}
      id={id}
    >
      {children}
    </ObfuscatedLink>
  );
};

export default React.memo(Link);
