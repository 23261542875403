import { useMediaQuery } from "@mui/material";

import { BREAKPOINT } from "../style/breakpoints";

const useBreakpoint = (
  checkBreakpoints: string[] | undefined = undefined //optional, if not provided, will check all breakpoints
): string | undefined => {
  const breakpoints = Object.keys(BREAKPOINT);
  const breakpointValues = Object.values(BREAKPOINT);

  return breakpoints
    .filter(
      (breakpoint, index) =>
        (!checkBreakpoints || checkBreakpoints.includes(breakpoint)) &&
        useMediaQuery(`@media (min-width: ${breakpointValues[index]})`) // eslint-disable-line react-hooks/rules-of-hooks
    )
    .pop();
};

export default useBreakpoint;
