import { FormHelperText, Checkbox, FormControlLabel } from "@mui/material";
import styled, { css } from "styled-components";
import theme from "styled-theming";
import { Error28 } from "icons";

import {
  styleBodyM,
  styleBodyS,
  styleBodyXS,
} from "style/components/Typography";

const labelColor = theme("theme", {
  undefined: "--text-on-neutral-secondary",
  lightgray: "--text-on-neutral-secondary",
  white: "--text-on-neutral-secondary",
  blue: "--text-on-blue-secondary",
  cyan: "--text-on-cyan-secondary",
  black: "--text-on-blue-secondary",
});

const disabledColor = theme("theme", {
  undefined: "--text-on-neutral-disabled",
  lightgray: "--text-on-neutral-disabled",
  white: "--text-on-neutral-disabled",
  blue: "--text-on-blue-disabled",
  cyan: "--text-on-cyan-disabled",
  black: "--text-on-blue-disabled",
});

const textColor = theme("theme", {
  undefined: "--text-on-neutral-primary",
  lightgray: "--text-on-neutral-primary",
  white: "--text-on-neutral-primary",
  blue: "--text-on-blue-primary",
  cyan: "--text-on-cyan-primary",
  black: "--text-on-blue-primary",
});

const markedColor = theme("theme", {
  undefined: "--color-blue",
  lightgray: "--color-blue",
  white: "--color-blue",
  blue: "--color-cyan-l40",
  cyan: "--color-blue",
  black: "--color-cyan-l40",
});

const borderColor = theme("theme", {
  undefined: "--field-on-neutral-default-border",
  lightgray: "--field-on-neutral-default-border",
  white: "--field-on-neutral-default-border",
  blue: "--field-on-blue-default-border",
  cyan: "--field-on-cyan-default-border",
  black: "--field-on-blue-default-border",
});

const borderDisabledColor = theme("theme", {
  undefined: "--field-on-neutral-disabled-border",
  lightgray: "--field-on-neutral-disabled-border",
  white: "--field-on-neutral-disabled-border",
  blue: "--field-on-blue-disabled-border",
  cyan: "--field-on-cyan-disabled-border",
  black: "--field-on-blue-disabled-border",
});

const borderError = theme("theme", {
  undefined: "--field-on-blue-error-border",
  lightgray: "--field-on-blue-error-border",
  white: "--field-on-blue-error-border",
  blue: "--field-on-blue-error-border",
  cyan: "--field-on-blue-error-border",
  black: "--field-on-blue-error-border",
});

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.4375rem;
`;

export const ErrorIcon = styled(Error28)`
  color: var(${borderError});
`;

export const CheckboxIcon = styled.div<IconProps>`
  border: 0.0625rem solid var(${borderColor});
  border-radius: 0.25rem;
  display: block;
  position: relative;
  transition: border-color 0.2s ease-in-out;
  height: ${theme("size", {
    small: "1rem",
    medium: "1.5rem",
  })};
  width: ${theme("size", {
    small: "1rem",
    medium: "1.5rem",
  })};

  &::before,
  &::after {
    background: transparent;
    border-radius: 0.125rem;
    content: "";
    display: none;
    position: absolute;
  }

  &::before {
    background: var(${markedColor});
    transition: inset 0.1s ease-in-out;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: var(${borderError});
      &&&& {
        margin-top: 0rem;
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      &::before {
        display: block;
        inset: ${theme("size", {
          small: "0.20rem",
          medium: "0.39rem",
        })};
      }
    `}

    ${(props) =>
    props.indeterminate &&
    css`
      &::before {
        display: block;
        inset: ${theme("size", {
          small: "0.38rem 0.20rem",
          medium: "0.6rem 0.39rem;",
        })};
      }
    `}
`;

export const CheckboxLabel = styled(FormControlLabel)<LabelProps>`
  && {
    align-items: flex-start;
    margin-left: 0;
    ${(props) =>
      !props.$hasLabel &&
      css`
        margin-right: 0;
      `}

    .MuiFormControlLabel-label {
      ${theme("size", {
        small: styleBodyS,
        medium: styleBodyM,
      })};
      color: var(${textColor});
      
      ${(props) =>
        props.$error &&
        css`
          margin-top: ${theme("size", {
            small: "0.3rem;",
          })};
        `}

      &.Mui-disabled {
        color: var(${disabledColor});
      }
    }

    &:hover:not(.Mui-disabled) {
      ${CheckboxIcon} {
        border-color: var(--color-cyan-l40);

        ${(props) =>
          !props.$indeterminate &&
          css`
            &::before {
              border-radius: 0.125rem;
              display: block;
              inset: ${theme("size", {
                small: "0.3rem",
                medium: "0.49rem",
              })};

              ${props.checked &&
              css`
                border-radius: 0.125rem;
                inset: ${theme("size", {
                  small: "0.25rem",
                  medium: "0.45rem",
                })};
              `}
            }
          `}

          ${(props) =>
            props.$indeterminate &&
            css`
              &::before {
                border-radius: 0.125rem;
                display: block;
                inset: ${theme("size", {
                  small: "0.38rem 0.25rem",
                  medium: "0.6rem 0.45rem;",
                })};
              }
            `}
    }
  }
`;

export const CheckboxControl = styled(Checkbox)`
  && {
    margin-right: 0.875rem;
    padding: 0;
    ${CheckboxIcon} {
      margin-top: 0.1rem;
    }
    &:has(> input:focus) {
      ${CheckboxIcon} {
        box-shadow: 0 0 0 0.4375rem var(--field-focus);
      }
    }

    &:hover {
      background: none;
    }

    &.Mui-disabled {
      ${CheckboxIcon} {
        border-color: var(${borderDisabledColor});

        &::before {
          background-color: var(${disabledColor});
        }
      }
    }

    &.Mui-error {
      ${CheckboxIcon} {
        border-color: var(${borderError});

        &::before {
          background-color: var(${borderError});
        }
      }
    }
  }
`;

export const Description = styled(FormHelperText)`
  && {
    ${theme("size", {
      small: styleBodyXS,
      medium: styleBodyS,
    })};
    color: var(${labelColor});
    letter-spacing: normal;
    margin-left: 0;

    &.Mui-disabled {
      color: var(${disabledColor});
    }
  }
`;

interface IconProps {
  tabindex?: number | string;
  checked: boolean;
  error: boolean;
  indeterminate: boolean;
}

interface LabelProps {
  $indeterminate?: boolean;
  $error?: boolean;
  $hasLabel?: boolean;
}
