import styled from "styled-components";

import { styleHeadingM } from "style/components/Typography";
import { columnGap, GUTTER } from "style/grid";
import { MQ } from "style/mediaQueries";
import theme from "styled-theming";

const backgroundColor = theme("theme", {
  undefined: "transparent",
  default: "--color-black-o05",
  white: "--color-white",
  lightgray: "--color-black-l95",
  blue: "--color-blue-d10",
  cyan: "--color-cyan-l80",
  black: "--color-black",
});

export const TitleHeading = styled.h2`
  ${styleHeadingM}
  align-self: flex-start;
  margin: 0rem;
  padding-bottom: ${GUTTER.SM};

  ${MQ.FROM_M} {
    padding-bottom: ${GUTTER.MD};
  }

  ${MQ.FROM_XL} {
    padding-bottom: ${GUTTER.XL};
  }
`;

export const PageCardItems = styled.div`
  ${columnGap};
  display: grid;
  grid-template-columns: repeat(4, [col-start] 1fr);
  row-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  ${MQ.FROM_M} {
    row-gap: 1rem;
    grid-template-columns: repeat(12, [col-start] 1fr);
  }

  ${MQ.FROM_XL} {
    row-gap: 1.5rem;
    grid-template-columns: repeat(16, [col-start] 1fr);
  }
`;

export const CardWrapper = styled.div`
  grid-column: auto / span 4;

  ${MQ.FROM_M} {
    grid-column: auto / span 6;
  }

  ${MQ.FROM_L} {
    grid-column: auto / span 4;
  }
`;

export const ContentContainer = styled.div`
  background: var(${backgroundColor});
  width: 100%;
`;
