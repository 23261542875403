import styled, { css } from "styled-components";

import { LogoSmallSolid56 } from "icons";
import Link from "components/Link";
import Section from "components/Section";
import { Container as GridContainer } from "style/components/Page";
import { styleBodyM, styleLabelM } from "style/components/Typography";
import { PADDING, columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
  ${columnGap}
  align-items: flex-start;
  color: var(--color-white);
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(4, [col-start] 1fr);
  row-gap: 3.5rem;
  padding-bottom: 2.625rem;
  padding-top: 3.5rem;
  padding-left: ${PADDING.S};
  padding-right: ${PADDING.S};

  ${MQ.FROM_M} {
    grid-template-columns: repeat(12, [col-start] 1fr);
    padding-bottom: 4.375rem;
    padding-left: ${PADDING.M};
    padding-right: ${PADDING.M};
  }
  ${MQ.FROM_XL} {
    padding-bottom: 6.125rem;
    padding-left: ${PADDING.XL};
    padding-right: ${PADDING.XL};
  }
  ${MQ.FROM_XXXL} {
    padding-left: ${PADDING.XXXL};
    padding-right: ${PADDING.XXXL};
  }
  ${MQ.FROM_XXXXL} {
    padding-left: ${PADDING.XXXXL};
    padding-right: ${PADDING.XXXXL};
  }
`;

export const LogoWrapper = styled.div`
  grid-column: 4 / span 1;
  grid-row: 1 / span 2;
  justify-self: right;

  ${MQ.FROM_M} {
    grid-column: 11 / span 2;
  }

  ${MQ.FROM_L} {
    grid-row: 1;
  }
`;

export const Logo = styled(LogoSmallSolid56)`
  height: 3.5rem;
`;

export const Links = styled.div`
  ${styleBodyM};
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  grid-column: 1 / span 3;
  justify-items: flex-start;

  ${MQ.FROM_M} {
    grid-row: 1 / span 2;
    grid-column: auto / span 4;

    &:nth-child(2) {
      grid-row: 1;
    }
  }

  ${MQ.FROM_L} {
    grid-row: 1;
    grid-column: auto / span 3;
  }

  ${MQ.FROM_XL} {
    grid-column: auto / span 2;
  }
`;

export const LinkItem = styled(Link)`
  && {
    border-bottom: 1px solid var(--color-white-o00);
    color: var(--color-white);

    &:hover {
      border-bottom: 1px solid var(--color-white);
    }
  }
`;

export const LinksHeading = styled.div`
  ${styleLabelM};
  color: var(--color-cyan-l60-o60);
  margin-bottom: 0.875rem;
`;

export const SocialLinks = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 14px;
  grid-column: 1 / span 3;
  justify-items: flex-start;

  ${MQ.FROM_M} {
    grid-column: 5 / span 4;
    grid-row: 2;
  }

  ${MQ.FROM_L} {
    grid-column: auto / span 3;
    grid-row: 1;
    margin-top: 3.5rem;
  }

  ${MQ.FROM_XL} {
    grid-column: auto / span 2;
  }
`;

export const SocialLink = styled(Link)`
  && {
    border: none;
    color: var(--color-white);

    &:hover {
      color: var(--color-white-o50);
    }
  }
`;

export const BottomContainer = styled(GridContainer)`
  align-items: center;
  padding-bottom: 1.3125rem;
  padding-top: 1.3125rem;
`;

export const BottomInnerContainer = styled.div`
  ${styleBodyM};
  align-items: center;
  ${columnGap};
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(4, [col-start] 1fr);
  justify-items: flex-start;
  position: relative;

  ${MQ.FROM_M} {
    grid-template-columns: repeat(12, [col-start] 1fr);
  }
`;

export const BottomSection = styled(Section)`
  align-items: center;
  color: var(--color-white);

  ${MQ.FROM_L} {
    background-color: var(--color-black-o15);
  }
`;

export const BottomSectionResponsive = styled(BottomSection)`
  background-color: var(--color-black-o15);

  ${MQ.FROM_L} {
    display: none;
  }
`;

export const BottomLinks = styled.div`
  align-items: center;
  column-gap: 1.75rem;
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 12;
  justify-self: center;
  row-gap: 0.875rem;

  ${MQ.FROM_M} {
    flex-direction: row;
  }
`;

export const BottomLink = styled(Link)`
  && {
    color: var(--color-white-o50);

    span {
      border-bottom: 1px solid var(--color-white-o00);
    }

    &:hover {
      span {
        border-bottom: 1px solid var(--color-white);
        color: var(--color-white);
      }
    }
  }
`;

export const FooterWrapper = styled.div<FooterWrapperProps>`
  ${(props) =>
    props.$showTopDivider &&
    css`
      && {
        border-top: 1px solid var(--color-black-l95);
      }
    `}
`;

interface FooterWrapperProps {
  readonly $showTopDivider?: boolean;
}
