import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import React, { ReactElement, useState } from "react";
import { ThemeProvider } from "styled-components";

import {
  CheckboxLabel,
  CheckboxControl,
  CheckboxIcon,
  Description,
  IconWrapper,
  ErrorIcon,
} from "./Checkbox.styled";
import CheckboxProps from "./CheckboxProps";

const Checkbox = ({
  label,
  id,
  disabled = false,
  size = "medium",
  description,
  required = false,
  className,
  error = false,
  checked,
  contentId,
  anchorName,
  ...restProps
}: CheckboxProps): ReactElement => {
  const [isChecked, setIsChecked] = useState<boolean>(
    restProps.defaultChecked || false
  );
  const checkBoxIcon = (
    checked: boolean,
    error: boolean,
    indeterminate: boolean
  ): JSX.Element => (
    <IconWrapper>
      {error && <ErrorIcon />}
      {
        <CheckboxIcon
          tabindex="0"
          checked={checked}
          error={error}
          indeterminate={indeterminate}
        />
      }
    </IconWrapper>
  );

  const handleOnchange = (event: any) => {
    if (restProps.onChange) {
      restProps.onChange(event, event.target.checked);
    }
    setIsChecked((prev) => !prev);
  };

  return (
    <MuiThemeProvider theme={createTheme()}>
      <ThemeProvider
        theme={{
          size,
        }}
      >
        <CheckboxLabel
          $error={error}
          id={contentId || anchorName}
          className={className}
          labelPlacement="end"
          checked={checked === undefined ? isChecked : checked}
          $indeterminate={restProps.indeterminate}
          $hasLabel={!!label || !!description}
          label={
            <>
              {label}
              {description && (
                <Description disabled={disabled} required={required}>
                  {description}
                </Description>
              )}
            </>
          }
          disabled={disabled}
          control={
            <CheckboxControl
              {...restProps}
              disableRipple
              required={required}
              checkedIcon={checkBoxIcon(true, !!error, false)}
              icon={checkBoxIcon(false, !!error, false)}
              indeterminateIcon={checkBoxIcon(true, !!error, true)}
              checked={checked === undefined ? isChecked : checked}
              onChange={handleOnchange}
            />
          }
        />
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default React.memo(Checkbox);
