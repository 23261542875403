import React, { ReactElement, useState } from "react";
import { ThemeProvider } from "styled-components";

import {
  Arrow,
  Collapsed,
  Container,
  Home,
  Link,
  Links,
  LinkWrapper,
  Section
} from "./Breadcrumbs.styled";
import BreadcrumbsProps from "./BreadcrumbsProps";

const Breadcrumbs = ({
  theme = "lightgray",
  identifier,
  items,
  component,
  contentId,
  className,
  anchorName,
}: BreadcrumbsProps): ReactElement => {
  const [isCollapsed, setCollapsed] = useState<boolean>(true);

  const toggleCollapsed = (): void => {
    setCollapsed(!isCollapsed);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement>
  ): void => {
    if (event?.key === "Enter") {
      event.preventDefault();
      toggleCollapsed();
    }
  };

  return (
    <ThemeProvider theme={{ theme: theme }}>
      <Section id={contentId || contentId || anchorName} className={className}>
        <Container
          role="navigation"
          aria-label="Breadcrumbs"
          key={identifier || `BreadcrumbsContent`}
        >
          <Links as="ol">
            {items?.map(
              (navItem, index, items) =>
                (navItem?.url || index === items.length - 1) && (
                  <LinkWrapper
                    as="li"
                    key={`navItem-${index}`}
                    position={
                      index === 0
                        ? "first"
                        : index === items.length - 1
                        ? "last"
                        : "middle"
                    }
                    collapseItems={isCollapsed}
                  >
                    {index !== 0 && <Arrow />}
                    {index < items.length - 1 || items.length == 1 ? (
                      <Link
                        {...navItem.properties}
                        to={navItem.url}
                        title={navItem.displayName}
                        aria-current={
                          items.length - 1 === index ? "page" : undefined
                        }
                        underline="none"
                        target={navItem.target}
                        component={component}
                      >
                        {index === 0 ? (
                          <>
                            <Home />
                          </>
                        ) : (
                          <span>{navItem.displayName}</span>
                        )}
                      </Link>
                    ) : (
                      <span>{navItem.displayName}</span>
                    )}
                    {items.length > 2 && index === 0 && (
                      <Collapsed
                        show={isCollapsed}
                        onClick={() => toggleCollapsed()}
                        onKeyDown={(event) => handleKeyDown(event)}
                      >
                        <Arrow />
                        ...
                      </Collapsed>
                    )}
                  </LinkWrapper>
                )
            )}
          </Links>
        </Container>
      </Section>
    </ThemeProvider>
  );
};

export default React.memo(Breadcrumbs);
