import styled, { css } from "styled-components";
import theme from "styled-theming";

import { LongArrowRight28 } from "icons";
import Image from "components/Image";
import Link from "components/Link";
import Card from "components/Card";
import { Obfuscated } from "components/Link/Link";

import {
  BodyM,
  styleBodyM,
  styleHeadingL,
  styleHeadingS,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const textColor = theme("theme", {
  lightgray: "--color-black",
  white: "--color-black",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

const hoverColor = theme("theme", {
  lightgray: "--color-black",
  white: "--color-black",
  blue: "--color-coral",
  cyan: "--color-blue",
});

const linkColor = theme("theme", {
  lightgray: "--color-cyan-d05",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
  white: "--color-cyan",
});

const initialsColor = theme("theme", {
  lightgray: "--color-black-o05",
  blue: "--color-cyan-o20",
  cyan: "--color-black-o05",
  white: "--color-black-l90",
});

const backgroundColor = theme("theme", {
  undefined: "transparent",
  default: "transparent",
  white: "var(--color-white)",
  lightgray: "var(--color-black-l95)",
  blue: "var(--color-blue-d10)",
  cyan: "var(--color-cyan-l80)",
  black: "var(--color-black)",
});

export const Container = styled(Card)`
  background-color: ${backgroundColor};
  color: var(${textColor});
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  ${theme("size", {
    small: css`
      gap: 0.875rem;
    `,
    medium: css`
      gap: 1.3125rem;
    `,
    large: css`
      gap: 1.3125rem;
    `,
  })}
`;

export const Content = styled.div`
  flex: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 5.25rem);
  ${theme("size", {
    small: css`
      width: calc(100% - 3.5rem);
    `,
    medium: css`
      ${MQ.FROM_M} {
        width: calc(100% - 6.125rem);
      }
      ${MQ.FROM_XL} {
        width: calc(100% - 6.5625rem);
      }
    `,
    large: css`
      ${MQ.FROM_M} {
        width: calc(100% - 9.1875rem);
      }
      ${MQ.FROM_XL} {
        width: calc(100% - 9.625rem);
      }
    `,
  })}
`;

export const Heading = styled.div`
  ${styleHeadingS}
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Email = styled(Obfuscated)`
  && {
    ${styleBodyM};
    color: var(${linkColor});
    display: block;
    margin-top: 0.4375rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Phone = styled(Obfuscated)`
  && {
    ${styleBodyM};
    color: var(${linkColor});
    margin-top: 0.4375rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;

export const PhoneSection = styled(BodyM)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 0.4375rem;
`;

export const Description = styled(BodyM)`
  margin-top: 0.4375rem;
`;

const imageOrInitialSize = css`
  height: 5.25rem;
  width: 5.25rem;
  ${theme("size", {
    small: css`
      height: 3.5rem;
      width: 3.5rem;
    `,
    medium: css`
      ${MQ.FROM_M} {
        height: 6.125rem;
        width: 6.125rem;
      }

      ${MQ.FROM_XL} {
        height: 6.5625rem;
        width: 6.5625rem;
      }
    `,
    large: css`
      ${MQ.FROM_M} {
        height: 9.1875rem;
        width: 9.1875rem;
      }

      ${MQ.FROM_XL} {
        height: 9.625rem;
        width: 9.625rem;
      }
    `,
  })}
`;

export const Initials = styled.div`
  ${theme("size", {
    small: styleHeadingS,
    medium: styleHeadingL,
    large: styleHeadingL,
  })}
  align-items: center;
  aspect-ratio: 1;
  background: var(${initialsColor});
  display: flex;
  justify-content: center;
  text-align: center;
  ${imageOrInitialSize}
`;

export const HeadingArrow = styled(LongArrowRight28)`
  color: var(${textColor});
  margin-left: 0.875rem;
  margin-right: 0.875rem;
  transition: margin-left 0.2s ease-in-out, margin-right 0.2s ease-in-out,
    color 0.2s ease-in-out;
  vertical-align: middle;
`;

export const HeadingUrl = styled(Link)`
  && {
    ${styleHeadingS}
    border: none;

    &:hover {
      color: var(${hoverColor});

      ${HeadingArrow} {
        margin-left: 1.75rem;
        margin-right: 0;
      }
    }
  }
`;

export const ProfileImage = styled(Image)`
  && {
    aspect-ratio: 1;
    ${imageOrInitialSize}
  }
`;

export const ProfileLink = styled(Link)`
  && {
    border: none;
    color: inherit;
  }
`;

export const Offices = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;

  ${MQ.FROM_M} {
    margin-top: 0.875rem;
  }
`;

export const Office = styled.div`
  ${styleBodyM};
  padding-left: 2.1875rem;
  position: relative;

  svg {
    left: 0;
    position: absolute;
  }
`;
