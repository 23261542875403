import * as React from "react";
const BigArrowLeft28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.854 4.146a.5.5 0 0 1 0 .708L10.207 14.5l9.647 9.646a.5.5 0 0 1-.708.708l-10-10a.5.5 0 0 1 0-.708l10-10a.5.5 0 0 1 .708 0"
      clipRule="evenodd"
    />
  </svg>
);
export default BigArrowLeft28;
