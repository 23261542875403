import React, { ReactElement } from "react";

import TypographyProperties from "./TypographyProps";
import {
  Typography as MuiTypograhy,
  ThemeProvider as MuiThemeProvider
} from "@mui/material";
import useMaterialUIBaseTheme from "hooks/useMaterialUIBaseTheme";

const Typography = ({ ...restProps }: TypographyProperties): ReactElement => {
  return (
    <MuiThemeProvider theme={useMaterialUIBaseTheme()}>
      <MuiTypograhy {...restProps}>{restProps.children}</MuiTypograhy>
    </MuiThemeProvider>
  );
};

export default React.memo(Typography);
