import { ThemeType } from "types/Theme";

const getCardTheme = (theme: ThemeType | undefined): ThemeType => {
  let cardTheme = "default" as ThemeType;
  switch (theme) {
    case "white":
      cardTheme = "lightgray" as ThemeType;
      break;
    case "lightgray":
      cardTheme = "white" as ThemeType;
      break;
    case "blue":
      cardTheme = "cyan" as ThemeType;
      break;
    default:
      cardTheme = "default" as ThemeType;
      break;
  }

  return cardTheme;
};

export default getCardTheme;
