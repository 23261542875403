import * as React from "react";
const Error28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.59 2.146A.5.5 0 0 1 9.944 2h9.112a.5.5 0 0 1 .354.146l6.444 6.444a.5.5 0 0 1 .146.354v9.112a.5.5 0 0 1-.146.354l-6.444 6.444a.5.5 0 0 1-.354.146H9.944a.5.5 0 0 1-.354-.146L3.146 18.41A.5.5 0 0 1 3 18.056V8.944a.5.5 0 0 1 .146-.354zm.561.854L4 9.151v8.698L10.151 24h8.698L25 17.849V9.15L18.849 3zM14.5 8a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0v-6a.5.5 0 0 1 .5-.5m1 10.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
      clipRule="evenodd"
    />
  </svg>
);
export default Error28;
