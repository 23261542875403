import React, { ReactElement, useState, useEffect } from "react";
import { BASE_ICON_URL } from "utils/settings";

import IconProps from "./IconProps";
import { BaseIcon } from "./Icon.styled";

const Icon = ({
  icon,
  className,
  height,
  width,
  contentId,
  anchorName,
}: IconProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [svgElement, setSvgElement] = useState<HTMLElement>();

  const parseSVG = (svgString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgString, "image/svg+xml");
    return doc.documentElement;
  };

  useEffect(() => {
    if (!icon) return;

    const importIcon = async () => {
      try {
        setLoading(true);

        const response: Response = await fetch(`${BASE_ICON_URL}${icon}.svg`);
        const data = await response.text();
        setSvgElement(parseSVG(data));
      } catch (err) {
        process.env.NODE_ENV === "development" &&
          console.warn(`Icon type ${String(icon)} is missing`);
        return <></>;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
    return () => {
      setLoading(false);
    };
  }, [icon]);

  if (!loading && svgElement) {
    return (
      <BaseIcon
        id={contentId || anchorName}
        className={className}
        width={width || svgElement.getAttribute("width") || undefined}
        height={height || svgElement.getAttribute("height") || undefined}
        icon={{
          body: svgElement.innerHTML,
          width: Number(svgElement.getAttribute("width")) ?? undefined,
          height: Number(svgElement.getAttribute("height")) ?? undefined,
        }}
      />
    );
  } else {
    return <></>;
  }
};

export default React.memo(Icon);
