import styled, { css } from "styled-components";

import { MQ } from "style/mediaQueries";
import theme from "styled-theming";

const borderColor = theme("theme", {
  white: "--color-black-l90",
  lightgray: "--color-black-l40",
  cyan: "--color-blue",
  blue: "--color-cyan-l60",
});

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
`;

export const KeyFigures = styled.div<KeyFiguresProps>`
  ${(props) =>
    props.display === "flex"
      ? css`
          display: flex;
          flex-wrap: wrap;
          column-gap: 1.5rem;
        `
      : css`
          column-gap: 1.5rem;
          row-gap: 1rem;
          display: grid;
          grid-template-columns: repeat(1, [col-start] 1fr);
          ${MQ.FROM_M} {
            display: flex;
            flex-wrap: wrap;
            > div {
              border-right: 1px solid var(${borderColor});
              padding-right: 1.5rem;
            }
          }
        `}
`;

interface KeyFiguresProps {
  readonly display?: string;
}
