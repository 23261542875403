import styled from "styled-components";

import { styleHeadingM } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled.div`
  ${columnGap}
  position: relative;
`;

export const Heading = styled.h2`
  ${styleHeadingM};
  align-self: start;
  margin-top: 0;
  margin-bottom: 0.75rem;

  ${MQ.FROM_L} {
    margin-bottom: 1rem;
    transition: top 0.2s ease-in-out;
  }

  ${MQ.FROM_XXXL} {
    margin-bottom: 1.5rem;
  }
`;
