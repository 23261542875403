import * as React from "react";
const Home28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 6a.5.5 0 0 1 .366.16l6.5 7a.5.5 0 0 1 .134.34v8a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5V18h-2v3.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .134-.34l6.5-7A.5.5 0 0 1 14 6m-6 7.696V21h4v-3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V21h4v-7.304l-6-6.461z"
      clipRule="evenodd"
    />
  </svg>
);
export default Home28;
