import React, { ReactElement, useContext } from "react";

import {
  StyledAccordionItem,
  AccordionSummary,
  AccordionDetails,
} from "./AccordionItem.styled";
import AccordionItemProps from "./AccordionItemProps";
import { ArrowDown28 } from "icons";
import { ThemeContext, ThemeProvider } from "styled-components";

const AccordionItem = ({
  expanded,
  id,
  theme,
  contentTheme,
  expandedButtonLeft = false,
  slim = false,
  styleVariant = "outlined",
  children: childrenProp,
  onChange,
  contentId,
  className,
  anchorName,
  ...restProps
}: AccordionItemProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};
  const [summary, ...children] = React.Children.toArray(childrenProp);

  const handleChange =
    () => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (onChange) onChange(event, isExpanded);
    };

  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
        contentTheme: contentTheme || exitingTheme["contentTheme"],
      }}
    >
      <StyledAccordionItem
        {...restProps}
        expanded={expanded}
        key={`Accordion${id}`}
        id={id || contentId || anchorName}
        className={className}
        disableGutters
        elevation={0}
        square
        $variant={styleVariant}
        onChange={handleChange()}
      >
        <AccordionSummary
          expandIcon={<ArrowDown28 aria-hidden="true" />}
          aria-controls={`panel-content-${id}`}
          aria-label="accordion-summary"
          id={`panel-header-${id}`}
          $expandedButtonLeft={expandedButtonLeft}
          $slim={slim}
        >
          {summary}
        </AccordionSummary>
        <AccordionDetails $slim={slim} $expandedButtonLeft={expandedButtonLeft}>
          {children}
        </AccordionDetails>
      </StyledAccordionItem>
    </ThemeProvider>
  );
};

export default React.memo(AccordionItem);
