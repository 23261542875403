import React, { Children, ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import { Container, PageContainer, StyledCarousel } from "./Carousel.styled";
import CarouselProps from "./CarouselProps";
import Section from "components/Section";
import { BigArrowLeft28, BigArrowRight28 } from "icons";
import { DefaultCarouselOptions } from "utils/settings";

const Carousel = ({
  contentTheme,
  theme,
  height,
  animation,
  navButtonsAlwaysVisible,
  cycleNavigation,
  duration,
  autoPlay,
  children,
  contentId,
  className,
  anchorName,
}: CarouselProps): ReactElement => {
  return (
    <ThemeProvider theme={{ theme: theme, contentTheme: contentTheme }}>
      <Section theme={theme} className={className} id={contentId || anchorName}>
        <Container>
          <StyledCarousel
            {...DefaultCarouselOptions}
            NextIcon={<BigArrowRight28 />}
            PrevIcon={<BigArrowLeft28 />}
            animation={animation || DefaultCarouselOptions.animation}
            navButtonsAlwaysVisible={
              navButtonsAlwaysVisible ??
              DefaultCarouselOptions.navButtonsAlwaysVisible
            }
            cycleNavigation={
              cycleNavigation ?? DefaultCarouselOptions.cycleNavigation
            }
            duration={duration || DefaultCarouselOptions.duration}
            autoPlay={autoPlay ?? DefaultCarouselOptions.autoPlay}
          >
            {Children.map(children, (child, index) => (
              <PageContainer style={{ height: height }} key={index}>
                {child}
              </PageContainer>
            ))}
          </StyledCarousel>
        </Container>
      </Section>
    </ThemeProvider>
  );
};

export default React.memo(Carousel);
