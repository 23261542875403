import * as React from "react";
const LogoSmallSolid56 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "45",
  height = "56",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 45 56"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      d="M25.967 10.434c-.68 2.326-.893 4.232-1.213 7.4-.003.015-.056.378.335.456q.414.058.531-.332c2.113-7.299 7.378-11.238 10.155-12.414.2-.094.29-.259.247-.453-.064-.288-.419-.597-.881-.597q-.157 0-.314.047l-.012.004V4.53c-.027-.914-1.097-1.977-2.435-1.977q-.49 0-.984.178l-.023.008.01-.022c.118-.257.006-.482-.166-.57a.3.3 0 0 0-.14-.03c-.234 0-.591.206-1.034.6-1.47 1.303-3.108 4.403-4.076 7.716"
    />
    <path
      fill="currentColor"
      d="M42.898 7.333a11.3 11.3 0 0 0-2.636-.32c-5.375 0-9.016 3.99-9.965 5.527-.003.003-.16.227-.093.394q.038.118.228.102l.24-.038c.693-.112 1.853-.298 3.248-.298.7 0 1.392.047 2.057.14 1.825.254 5.422 1.207 7.327 3.167.116.118.252.115.368-.01.165-.177.256-.548.23-.945-.061-.916-.395-1.212-.732-1.437l-.016-.01.017-.008c.431-.197.736-.954.742-1.838.005-.778-.233-1.725-.917-2.05l-.02-.01.02-.009c.508-.22.878-.855.826-1.418-.044-.479-.372-.813-.924-.94M35.548 49.013c.196 0 .357-.075.443-.206.158-.24.108-.529-.122-.716-2.434-2.055-4-3.432-5.436-5.76l-.274-.443c-1.32-2.118-2.564-4.12-4.497-11.118-.125-.33-.347-.38-.468-.38a.5.5 0 0 0-.106.013c-.015.003-.388.088-.332.575.023.204.05.534.084.952.188 2.259.577 6.959 1.663 10.646 1.289 4.379 2.182 6.506 3.983 9.484.115.196.281.304.467.304a.5.5 0 0 0 .381-.183.44.44 0 0 0 .092-.378l-.003-.014.014.003a3.6 3.6 0 0 0 3.085-1.007c.518-.525.81-1.179.802-1.792v-.014l.013.004q.103.03.211.03"
    />
    <path
      fill="currentColor"
      d="M39.507 40.04c-6.625-1.075-8.9-5.004-10.73-8.16a37 37 0 0 0-.933-1.556c-.001-.003-.164-.246-.4-.246a.4.4 0 0 0-.14.03c-.015.002-.306.074-.217.569l.024.113c.689 3.35 2.08 6.587 4.132 9.624 1.587 2.18 3.222 4.263 5.776 5.786.296.156.609.079.743-.165a.47.47 0 0 0-.014-.48l-.006-.01.01-.004c1.405-.688 2.507-2.328 1.894-4.13l-.005-.014h.014c.253-.014.498-.304.525-.618.015-.172-.022-.588-.673-.738"
    />
    <path
      fill="currentColor"
      d="M39.406 37.971a8 8 0 0 0 .407-.01c.357-.04.63-.259.712-.571.08-.307-.04-.621-.317-.82l-.008-.006.006-.008c.693-1.016.656-1.907.502-2.476-.199-.74-.716-1.386-1.348-1.687l-.01-.005.006-.01c.22-.397.22-.837.003-1.207a1.15 1.15 0 0 0-.984-.576q-.196 0-.388.07c-1.289.373-2.26.604-3.118.604-1.342 0-2.427-.546-4.583-1.79-.207-.104-.375-.156-.513-.156a.44.44 0 0 0-.365.182c-.19.235.02.707.023.711.034.08 3.585 7.755 9.975 7.755M43.542 19.425c.003 0 .005 0 .019.005.036.014.133.051.23.051a.23.23 0 0 0 .161-.053.393.393 0 0 0 .07-.516c-.307-.474-.663-.893-1.574-1.634-2.729-2.223-6.616-3.014-9.343-3.014-2.12 0-3.79.458-4.471 1.227-1.046 1.299-1.642 3.189-1.731 4.117l-.003.032-.018.208-.001.03c-.004.073-.011.21.067.292.079.084.232.094.448.028a1 1 0 0 0 .075-.026 15 15 0 0 1 5.381-1.006c4.318 0 8.022 1.867 9.882 3.467.003.003.315.297.593.297a.32.32 0 0 0 .2-.066c.002 0 .093-.07.106-.191q.016-.145-.12-.304l-.008-.009.01-.006c.444-.287.57-.784.596-1.152.048-.674-.192-1.449-.569-1.777"
    />
    <path
      fill="currentColor"
      d="M43.587 28.684c.174-.478.056-1.072-.35-1.768l-.018-.03.031.016q.05.026.109.026a.3.3 0 0 0 .193-.078c.116-.102.064-.303.019-.48-.05-.192-.166-.422-.52-1.024-1.342-2.283-5.925-4.729-10.608-4.729-1.445 0-2.799.231-4.024.688-1.11.413-1.5.792-1.594.899q-.206.232-.312.739c-.227 1.01-.07 4.254.253 5.26 0 .001.071.203.236.23.19.032.306-.133.307-.135 1.87-2.382 3.977-3.59 6.264-3.59 3.675 0 6.978 3.128 8.62 4.993.203.218.336.311.444.311a.3.3 0 0 0 .151-.052.32.32 0 0 0 .143-.206c.028-.158-.081-.31-.082-.312l-.008-.011.012-.005c.005-.002.534-.194.734-.742M12.731 2.554c-1.338 0-2.408 1.063-2.435 1.977v.014l-.013-.004a1 1 0 0 0-.313-.047c-.463 0-.817.31-.881.597-.043.194.047.36.247.453 2.777 1.176 8.042 5.115 10.154 12.414.079.26.25.37.532.332.39-.078.337-.441.334-.456-.32-3.169-.534-5.074-1.213-7.4-.968-3.313-2.606-6.413-4.075-7.716-.541-.481-.955-.681-1.173-.57-.173.089-.285.313-.167.57l.01.023-.022-.008a2.9 2.9 0 0 0-.985-.18"
    />
    <path
      fill="currentColor"
      d="M14.821 12.54c-.95-1.537-4.59-5.526-9.965-5.526-.87 0-1.757.107-2.636.318-.553.127-.881.46-.926.942-.051.561.319 1.198.827 1.417l.02.009-.02.01c-.685.325-.923 1.272-.918 2.05.006.885.312 1.64.743 1.838l.016.008-.015.01c-.337.225-.671.52-.732 1.437-.025.397.065.77.23.946.116.124.252.128.368.009 1.905-1.96 5.501-2.913 7.327-3.167.665-.092 1.358-.14 2.057-.14 1.396 0 2.555.186 3.248.298l.24.039c.122.01.202-.024.228-.102.067-.169-.09-.393-.092-.395M13.78 52.18a.5.5 0 0 0 .381.184c.185 0 .351-.108.466-.305 1.802-2.978 2.695-5.105 3.983-9.484 1.086-3.687 1.476-8.387 1.663-10.646.034-.418.062-.748.084-.952.056-.487-.317-.572-.332-.575a.5.5 0 0 0-.106-.012c-.12 0-.343.05-.468.38-1.933 6.998-3.178 8.999-4.496 11.118l-.275.443c-1.436 2.327-3.002 3.705-5.435 5.759-.23.188-.28.476-.122.716.086.132.247.207.442.207a.7.7 0 0 0 .211-.031l.013-.004v.014c-.008.613.284 1.267.802 1.793a3.6 3.6 0 0 0 3.084 1.006l.015-.003-.003.014a.45.45 0 0 0 .093.378"
    />
    <path
      fill="currentColor"
      d="M8.097 46.2c2.554-1.523 4.189-3.606 5.775-5.786 2.054-3.037 3.444-6.275 4.133-9.624l.024-.114c.089-.495-.203-.566-.215-.569a.4.4 0 0 0-.143-.03c-.235 0-.397.243-.4.246-.31.48-.612 1.002-.932 1.556-1.83 3.157-4.105 7.086-10.73 8.16-.651.151-.688.567-.673.739.026.314.272.604.525.618h.014l-.006.015c-.613 1.803.489 3.442 1.893 4.13l.01.006-.005.01a.47.47 0 0 0-.013.479c.134.244.446.321.743.165"
    />
    <path
      fill="currentColor"
      d="M15.683 30.218c.002-.005.213-.477.023-.71-.189-.238-.451-.245-.879-.026-2.155 1.243-3.24 1.788-4.583 1.788-.858 0-1.83-.23-3.117-.604a1.1 1.1 0 0 0-.39-.07c-.397 0-.774.22-.983.576-.217.37-.217.81.003 1.208l.005.01-.009.004c-.632.301-1.149.948-1.349 1.687-.153.569-.19 1.46.503 2.477l.006.008-.008.005c-.276.199-.397.512-.317.82.08.312.354.53.712.57q.204.011.407.011c6.39-.001 9.94-7.676 9.976-7.754M22.558 18.253c.396 0 .688-.1.867-.292.202-.216.186-.477.186-.48.264-5.12.834-7.92 2.344-11.522.53-1.261.905-1.914 2.12-3.842a.31.31 0 0 0 .038-.27c-.056-.184-.266-.36-.606-.508a1.8 1.8 0 0 0-.696-.119c-.503 0-1.047.13-1.531.366l-.01.006-.003-.011C24.719.044 22.817 0 22.602 0h-.092c-.215 0-2.117.044-2.664 1.58l-.004.012-.01-.006A3.6 3.6 0 0 0 18.3 1.22c-.277 0-.518.041-.695.119-.34.148-.55.324-.606.508a.32.32 0 0 0 .038.27c1.215 1.928 1.59 2.581 2.12 3.842 1.51 3.601 2.08 6.401 2.343 11.522 0 .003-.015.265.186.48.18.193.473.292.868.292l.003.01v-.01M26.506 54.41c.644 0 1.269-.203 1.712-.557.49-.446.133-1.017.129-1.023-4.238-7.702-4.603-18.09-4.722-21.503l-.001-.046c-.022-.63-.38-.95-1.064-.95-.688 0-1.046.32-1.068.95v.046c-.12 3.413-.485 13.8-4.723 21.503-.003.006-.36.577.13 1.023.443.354 1.067.558 1.711.558.292 0 .558-.043.77-.126l.01-.003.003.01c.404 1.627 2.615 1.709 3.054 1.709.067 0 .107-.002.111-.002q.01.001.113.002c.44 0 2.65-.082 3.054-1.708l.002-.011.011.003c.211.083.477.126.768.126M1.589 22.865a.32.32 0 0 0 .201.066c.278 0 .59-.294.594-.297 1.86-1.6 5.563-3.467 9.881-3.467 1.836 0 3.646.338 5.381 1.006 0 0 .029.012.075.026.216.065.369.055.448-.028.078-.082.07-.219.067-.293l-.001-.03-.018-.207-.003-.032c-.089-.928-.685-2.818-1.731-4.117-.68-.769-2.352-1.227-4.471-1.227-2.728 0-6.615.79-9.344 3.014-.91.741-1.267 1.16-1.574 1.634a.393.393 0 0 0 .07.516q.06.053.162.053a.7.7 0 0 0 .248-.056c-.376.328-.617 1.104-.568 1.778.027.368.153.866.598 1.153l.01.006-.01.008q-.137.159-.12.304c.013.12.104.19.105.19"
    />
    <path
      fill="currentColor"
      d="M2.321 29.962c.05.027.095.05.151.05.107 0 .241-.092.443-.31 1.643-1.865 4.946-4.992 8.621-4.992 2.287 0 4.395 1.207 6.264 3.589.001.002.118.166.307.135.165-.027.236-.229.237-.23.322-1.006.479-4.251.252-5.26q-.106-.506-.311-.74c-.093-.106-.485-.485-1.595-.898-1.225-.456-2.579-.688-4.024-.688-4.682 0-9.265 2.445-10.608 4.729-.354.602-.47.832-.52 1.023-.044.178-.096.378.02.481.088.078.208.1.301.05l.032-.016-.018.031c-.407.696-.524 1.291-.35 1.768.2.548.728.74.734.743l.012.005-.008.01c-.001.002-.11.155-.083.313a.32.32 0 0 0 .143.207"
    />
  </svg>
);
export default LogoSmallSolid56;
