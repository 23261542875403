export const stockChartOptions = {
  series: [
    {
      name: "Private",
      type: "spline",
      data: [
        [1147651200000, 23.15],
        [1147737600000, 23.01],
        [1147824000000, 22.73],
        [1147910400000, 22.83],
        [1147996800000, 22.56],
        [1148256000000, 22.88],
        [1148342400000, 22.79],
        [1148428800000, 23.5],
        [1148515200000, 23.74],
        [1148601600000, 23.72],
        [1148947200000, 23.15],
        [1149033600000, 22.65],
        [1149120000000, 22.82],
        [1149206400000, 22.76],
        [1149465600000, 22.5],
        [1149552000000, 22.13],
        [1149638400000, 22.04],
        [1149724800000, 22.11],
        [1149811200000, 21.92],
        [1150070400000, 21.71],
        [1150156800000, 21.51],
        [1150243200000, 21.88],
        [1150329600000, 22.07],
        [1150416000000, 22.1],
        [1150675200000, 22.55],
        [1150761600000, 22.56],
        [1150848000000, 23.08],
        [1150934400000, 22.88],
        [1151020800000, 22.5],
        [1151280000000, 22.82],
        [1151366400000, 22.86],
        [1151452800000, 23.16],
        [1151539200000, 23.47],
        [1151625600000, 23.3],
        [1151884800000, 23.7],
        [1152057600000, 23.35],
        [1152144000000, 23.48],
        [1152230400000, 23.3],
        [1152489600000, 23.5],
        [1152576000000, 23.1],
        [1152662400000, 22.64],
        [1152748800000, 22.26],
        [1152835200000, 22.29],
        [1153094400000, 22.48],
        [1153180800000, 22.74],
        [1153267200000, 23.4],
        [1153353600000, 22.85],
        [1153440000000, 23.87],
        [1153699200000, 24],
        [1153785600000, 24.22],
        [1153872000000, 24.37],
        [1153958400000, 23.87],
        [1154044800000, 24.25],
        [1154304000000, 24.06],
        [1154390400000, 23.99],
        [1154476800000, 24.3],
        [1154563200000, 24.21],
        [1154649600000, 24.29],
        [1154908800000, 24.22],
        [1154995200000, 24.34],
        [1155081600000, 24.44],
        [1155168000000, 24.46],
        [1155254400000, 24.43],
        [1155513600000, 24.53],
        [1155600000000, 24.62],
        [1155686400000, 24.7],
        [1155772800000, 24.7],
        [1155859200000, 25.79],
        [1156118400000, 26.12],
        [1156204800000, 25.62],
        [1156291200000, 25.67],
        [1156377600000, 25.74],
        [1156464000000, 25.85],
        [1156723200000, 25.95],
        [1156809600000, 25.84],
        [1156896000000, 25.8],
        [1156982400000, 25.7],
        [1157068800000, 25.84],
        [1157414400000, 25.61],
        [1157500800000, 25.61],
        [1157587200000, 25.43],
        [1157673600000, 25.6],
        [1157932800000, 25.91],
        [1158019200000, 25.93],
        [1158105600000, 25.98],
        [1158192000000, 26.33],
        [1158278400000, 26.85],
        [1158537600000, 26.79],
        [1158624000000, 26.86],
        [1158710400000, 27.18],
        [1158796800000, 26.9],
        [1158883200000, 26.66],
        [1159142400000, 26.95],
        [1159228800000, 27.2],
        [1159315200000, 27.44],
        [1159401600000, 27.4],
        [1159488000000, 27.35],
        [1159747200000, 27.36],
        [1159833600000, 27.37],
        [1159920000000, 27.94],
        [1160006400000, 27.92],
        [1160092800000, 27.87],
        [1160352000000, 27.72],
        [1160438400000, 27.69],
        [1160524800000, 27.54],
        [1160611200000, 28.22],
        [1160697600000, 28.37],
        [1160956800000, 28.45],
        [1161043200000, 28.44],
        [1161129600000, 28.52],
        [1161216000000, 28.29],
        [1161302400000, 28.43],
        [1161561600000, 28.45],
        [1161648000000, 28.28],
        [1161734400000, 28.31],
        [1161820800000, 28.35],
        [1161907200000, 28.34],
        [1162166400000, 28.53],
        [1162252800000, 28.71],
        [1162339200000, 28.81],
        [1162425600000, 28.77],
        [1162512000000, 28.73],
        [1162771200000, 28.84],
        [1162857600000, 28.95],
        [1162944000000, 28.98],
        [1163030400000, 29.26],
        [1163116800000, 29.24],
        [1163376000000, 29.35],
        [1163462400000, 29.23],
        [1163548800000, 29.12],
        [1163635200000, 29.47],
        [1163721600000, 29.4],
        [1163980800000, 29.89],
        [1164067200000, 29.92],
        [1164153600000, 29.92],
        [1164326400000, 29.76],
        [1164585600000, 29.48],
        [1164672000000, 29.39],
        [1164758400000, 29.57],
        [1164844800000, 29.36],
        [1164931200000, 29.12],
        [1165190400000, 29.33],
        [1165276800000, 29.13],
        [1165363200000, 28.99],
        [1165449600000, 28.85],
        [1165536000000, 29.4],
        [1165795200000, 29.54],
        [1165881600000, 29.43],
        [1165968000000, 29.55],
        [1166054400000, 30.07],
        [1166140800000, 30.19],
        [1166400000000, 29.89],
        [1166486400000, 29.99],
        [1166572800000, 30.09],
        [1166659200000, 29.98],
        [1166745600000, 29.64],
        [1167091200000, 29.99],
        [1167177600000, 30.02],
        [1167264000000, 29.98],
        [1167350400000, 29.86],
        [1167782400000, 29.86],
        [1167868800000, 29.81],
        [1167955200000, 29.64],
        [1168214400000, 29.93],
        [1168300800000, 29.96],
        [1168387200000, 29.66],
        [1168473600000, 30.7],
        [1168560000000, 31.21],
        [1168905600000, 31.16],
        [1168992000000, 31.1],
        [1169078400000, 31],
        [1169164800000, 31.11],
        [1169424000000, 30.72],
        [1169510400000, 30.74],
        [1169596800000, 31.09],
        [1169683200000, 30.45],
        [1169769600000, 30.6],
        [1170028800000, 30.53],
        [1170115200000, 30.48],
        [1170201600000, 30.86],
        [1170288000000, 30.56],
        [1170374400000, 30.19],
        [1170633600000, 29.61],
        [1170720000000, 29.51],
        [1170806400000, 29.37],
        [1170892800000, 29.26],
        [1170979200000, 28.98],
        [1171238400000, 28.94],
        [1171324800000, 29.03],
        [1171411200000, 29.4],
        [1171497600000, 29.46],
        [1171584000000, 28.74],
        [1171929600000, 28.83],
        [1172016000000, 29.35],
        [1172102400000, 29.39],
        [1172188800000, 28.9],
        [1172448000000, 29.07],
        [1172534400000, 27.87],
        [1172620800000, 28.17],
        [1172707200000, 28.09],
        [1172793600000, 27.76],
        [1173052800000, 27.55],
        [1173139200000, 27.83],
        [1173225600000, 27.61],
        [1173312000000, 27.32],
        [1173398400000, 27.29],
        [1173657600000, 27.44],
        [1173744000000, 26.72],
        [1173830400000, 27.4],
        [1173916800000, 27.28],
        [1174003200000, 27.33],
        [1174262400000, 27.83],
        [1174348800000, 27.84],
        [1174435200000, 28.52],
        [1174521600000, 28.27],
        [1174608000000, 28.02],
        [1174867200000, 28.22],
        [1174953600000, 27.72],
        [1175040000000, 27.64],
        [1175126400000, 27.75],
        [1175212800000, 27.87],
        [1175472000000, 27.74],
        [1175558400000, 27.87],
        [1175644800000, 28.5],
        [1175731200000, 28.55],
        [1176076800000, 28.57],
        [1176163200000, 28.4],
        [1176249600000, 28.11],
        [1176336000000, 28.54],
        [1176422400000, 28.61],
        [1176681600000, 28.73],
        [1176768000000, 28.85],
        [1176854400000, 28.6],
        [1176940800000, 28.69],
        [1177027200000, 29.02],
        [1177286400000, 28.78],
        [1177372800000, 28.79],
        [1177459200000, 28.99],
        [1177545600000, 29.1],
        [1177632000000, 30.12],
        [1177891200000, 29.94],
        [1177977600000, 30.4],
        [1178064000000, 30.61],
        [1178150400000, 30.97],
        [1178236800000, 30.56],
        [1178496000000, 30.71],
        [1178582400000, 30.75],
        [1178668800000, 30.78],
        [1178755200000, 30.58],
        [1178841600000, 30.89],
        [1179100800000, 30.97],
        [1179187200000, 30.9],
        [1179273600000, 31.07],
        [1179360000000, 30.98],
        [1179446400000, 30.83],
        [1179705600000, 31.05],
        [1179792000000, 30.69],
        [1179878400000, 30.58],
        [1179964800000, 30.17],
        [1180051200000, 30.48],
        [1180396800000, 30.79],
        [1180483200000, 31.11],
        [1180569600000, 30.69],
        [1180656000000, 30.59],
        [1180915200000, 30.72],
        [1181001600000, 30.58],
        [1181088000000, 30.29],
        [1181174400000, 29.62],
        [1181260800000, 30.05],
        [1181520000000, 30.02],
        [1181606400000, 29.85],
        [1181692800000, 30.39],
        [1181779200000, 30.52],
        [1181865600000, 30.49],
        [1182124800000, 30.51],
        [1182211200000, 30.46],
        [1182297600000, 30.01],
        [1182384000000, 30.22],
        [1182470400000, 29.49],
        [1182729600000, 29.49],
        [1182816000000, 29.52],
        [1182902400000, 29.87],
        [1182988800000, 29.83],
        [1183075200000, 29.47],
        [1183334400000, 29.74],
        [1183420800000, 30.02],
        [1183593600000, 29.99],
        [1183680000000, 29.97],
        [1183939200000, 29.87],
        [1184025600000, 29.33],
        [1184112000000, 29.49],
        [1184198400000, 30.07],
        [1184284800000, 29.82],
        [1184544000000, 30.03],
        [1184630400000, 30.78],
        [1184716800000, 30.92],
        [1184803200000, 31.51],
        [1184889600000, 31.16],
        [1185148800000, 31.19],
        [1185235200000, 30.8],
        [1185321600000, 30.71],
        [1185408000000, 29.98],
        [1185494400000, 29.39],
        [1185753600000, 29.4],
        [1185840000000, 28.99],
        [1185926400000, 29.3],
        [1186012800000, 29.52],
        [1186099200000, 28.96],
        [1186358400000, 29.54],
        [1186444800000, 29.55],
        [1186531200000, 30],
        [1186617600000, 29.3],
        [1186704000000, 28.71],
        [1186963200000, 28.63],
        [1187049600000, 28.27],
        [1187136000000, 28.1],
        [1187222400000, 27.81],
        [1187308800000, 28.25],
        [1187568000000, 28.26],
        [1187654400000, 28.07],
        [1187740800000, 28.22],
        [1187827200000, 28.3],
        [1187913600000, 28.81],
        [1188172800000, 28.49],
        [1188259200000, 27.93],
        [1188345600000, 28.59],
        [1188432000000, 28.45],
        [1188518400000, 28.73],
        [1188864000000, 28.81],
        [1188950400000, 28.48],
        [1189036800000, 28.91],
        [1189123200000, 28.44],
        [1189382400000, 28.48],
        [1189468800000, 28.93],
        [1189555200000, 28.93],
        [1189641600000, 29.16],
        [1189728000000, 29.04],
        [1189987200000, 28.72],
        [1190073600000, 28.93],
        [1190160000000, 28.67],
        [1190246400000, 28.42],
        [1190332800000, 28.65],
        [1190592000000, 29.08],
        [1190678400000, 29.56],
        [1190764800000, 29.5],
        [1190851200000, 29.49],
        [1190937600000, 29.46],
        [1191196800000, 29.77],
        [1191283200000, 29.7],
        [1191369600000, 29.45],
        [1191456000000, 29.71],
        [1191542400000, 29.84],
        [1191801600000, 29.84],
        [1191888000000, 30.1],
        [1191974400000, 30.23],
        [1192060800000, 29.91],
        [1192147200000, 30.17],
        [1192406400000, 30.04],
        [1192492800000, 30.32],
        [1192579200000, 31.08],
        [1192665600000, 31.16],
        [1192752000000, 30.17],
        [1193011200000, 30.51],
        [1193097600000, 30.9],
        [1193184000000, 31.25],
        [1193270400000, 31.99],
        [1193356800000, 35.03],
        [1193616000000, 34.57],
        [1193702400000, 35.57],
        [1193788800000, 36.81],
        [1193875200000, 37.06],
        [1193961600000, 37.06],
        [1194220800000, 36.73],
        [1194307200000, 36.41],
        [1194393600000, 35.52],
        [1194480000000, 34.74],
        [1194566400000, 33.73],
        [1194825600000, 33.38],
        [1194912000000, 34.46],
        [1194998400000, 33.93],
        [1195084800000, 33.76],
        [1195171200000, 34.09],
        [1195430400000, 33.96],
        [1195516800000, 34.58],
        [1195603200000, 34.23],
        [1195776000000, 34.11],
        [1196035200000, 32.97],
        [1196121600000, 33.06],
        [1196208000000, 33.7],
        [1196294400000, 33.59],
        [1196380800000, 33.6],
        [1196640000000, 32.92],
        [1196726400000, 32.77],
        [1196812800000, 34.15],
        [1196899200000, 34.55],
        [1196985600000, 34.53],
        [1197244800000, 34.76],
        [1197331200000, 34.1],
        [1197417600000, 34.47],
        [1197504000000, 35.22],
        [1197590400000, 35.31],
        [1197849600000, 34.39],
        [1197936000000, 34.74],
        [1198022400000, 34.79],
        [1198108800000, 35.52],
        [1198195200000, 36.06],
        [1198454400000, 36.58],
        [1198627200000, 36.61],
        [1198713600000, 35.97],
        [1198800000000, 36.12],
        [1199059200000, 35.6],
        [1199232000000, 35.22],
        [1199318400000, 35.37],
        [1199404800000, 34.38],
        [1199664000000, 34.61],
        [1199750400000, 33.45],
        [1199836800000, 34.44],
        [1199923200000, 34.33],
        [1200009600000, 33.91],
        [1200268800000, 34.39],
        [1200355200000, 34],
        [1200441600000, 33.23],
        [1200528000000, 33.11],
        [1200614400000, 33.01],
        [1200960000000, 31.99],
        [1201046400000, 31.93],
        [1201132800000, 33.25],
        [1201219200000, 32.94],
        [1201478400000, 32.72],
        [1201564800000, 32.6],
        [1201651200000, 32.2],
        [1201737600000, 32.6],
        [1201824000000, 30.45],
        [1202083200000, 30.19],
        [1202169600000, 29.07],
        [1202256000000, 28.52],
        [1202342400000, 28.12],
        [1202428800000, 28.56],
        [1202688000000, 28.21],
        [1202774400000, 28.34],
        [1202860800000, 28.96],
        [1202947200000, 28.5],
        [1203033600000, 28.42],
        [1203379200000, 28.17],
        [1203465600000, 28.22],
        [1203552000000, 28.1],
        [1203638400000, 27.68],
        [1203897600000, 27.84],
        [1203984000000, 28.38],
        [1204070400000, 28.26],
        [1204156800000, 27.93],
        [1204243200000, 27.2],
        [1204502400000, 26.99],
        [1204588800000, 27.59],
        [1204675200000, 28.12],
        [1204761600000, 27.57],
        [1204848000000, 27.87],
        [1205107200000, 28.05],
        [1205193600000, 29.28],
        [1205280000000, 28.63],
        [1205366400000, 28.62],
        [1205452800000, 27.96],
        [1205712000000, 28.3],
        [1205798400000, 29.42],
        [1205884800000, 28.62],
        [1205971200000, 29.18],
        [1206316800000, 29.17],
        [1206403200000, 29.14],
        [1206489600000, 28.56],
        [1206576000000, 28.05],
        [1206662400000, 27.91],
        [1206921600000, 28.38],
        [1207008000000, 29.5],
        [1207094400000, 29.16],
        [1207180800000, 29],
        [1207267200000, 29.16],
        [1207526400000, 29.16],
        [1207612800000, 28.75],
        [1207699200000, 28.89],
        [1207785600000, 29.11],
        [1207872000000, 28.28],
        [1208131200000, 28.06],
        [1208217600000, 28.25],
        [1208304000000, 28.95],
        [1208390400000, 29.22],
        [1208476800000, 30],
        [1208736000000, 30.42],
        [1208822400000, 30.25],
        [1208908800000, 31.45],
        [1208995200000, 31.8],
        [1209081600000, 29.83],
        [1209340800000, 28.99],
        [1209427200000, 28.64],
        [1209513600000, 28.52],
        [1209600000000, 29.4],
        [1209686400000, 29.24],
        [1209945600000, 29.08],
        [1210032000000, 29.7],
        [1210118400000, 29.21],
        [1210204800000, 29.27],
        [1210291200000, 29.39],
        [1210550400000, 29.99],
        [1210636800000, 29.78],
        [1210723200000, 29.93],
        [1210809600000, 30.45],
        [1210896000000, 29.99],
        [1211155200000, 29.46],
        [1211241600000, 28.76],
        [1211328000000, 28.25],
        [1211414400000, 28.47],
        [1211500800000, 28.05],
        [1211846400000, 28.44],
        [1211932800000, 28.18],
        [1212019200000, 28.31],
        [1212105600000, 28.32],
        [1212364800000, 27.8],
        [1212451200000, 27.31],
        [1212537600000, 27.54],
        [1212624000000, 28.3],
        [1212710400000, 27.49],
        [1212969600000, 27.71],
        [1213056000000, 27.89],
        [1213142400000, 27.12],
        [1213228800000, 28.24],
        [1213315200000, 29.07],
        [1213574400000, 28.93],
        [1213660800000, 28.8],
        [1213747200000, 28.46],
        [1213833600000, 28.93],
        [1213920000000, 28.23],
        [1214179200000, 27.97],
        [1214265600000, 27.73],
        [1214352000000, 28.35],
        [1214438400000, 27.75],
        [1214524800000, 27.63],
        [1214784000000, 27.51],
        [1214870400000, 26.87],
        [1214956800000, 25.88],
        [1215043200000, 25.98],
        [1215388800000, 26.03],
        [1215475200000, 25.85],
        [1215561600000, 25.23],
        [1215648000000, 25.45],
        [1215734400000, 25.25],
        [1215993600000, 25.15],
        [1216080000000, 26.15],
        [1216166400000, 27.26],
        [1216252800000, 27.52],
        [1216339200000, 25.86],
        [1216598400000, 25.64],
        [1216684800000, 25.8],
        [1216771200000, 26.43],
        [1216857600000, 25.44],
        [1216944000000, 26.16],
        [1217203200000, 25.5],
        [1217289600000, 26.11],
        [1217376000000, 26.23],
        [1217462400000, 25.72],
        [1217548800000, 25.44],
        [1217808000000, 25.28],
        [1217894400000, 26.21],
        [1217980800000, 27.02],
        [1218067200000, 27.39],
        [1218153600000, 28.13],
        [1218412800000, 27.9],
        [1218499200000, 28.12],
        [1218585600000, 27.91],
        [1218672000000, 27.91],
        [1218758400000, 27.81],
        [1219017600000, 27.69],
        [1219104000000, 27.32],
        [1219190400000, 27.29],
        [1219276800000, 27.18],
        [1219363200000, 27.84],
        [1219622400000, 27.66],
        [1219708800000, 27.27],
        [1219795200000, 27.56],
        [1219881600000, 27.94],
        [1219968000000, 27.29],
        [1220313600000, 27.1],
        [1220400000000, 26.9],
        [1220486400000, 26.35],
        [1220572800000, 25.65],
        [1220832000000, 26.12],
        [1220918400000, 26.1],
        [1221004800000, 26.44],
        [1221091200000, 27.34],
        [1221177600000, 27.62],
        [1221436800000, 26.82],
        [1221523200000, 25.99],
        [1221609600000, 24.57],
        [1221696000000, 25.26],
        [1221782400000, 25.16],
        [1222041600000, 25.4],
        [1222128000000, 25.44],
        [1222214400000, 25.72],
        [1222300800000, 26.61],
        [1222387200000, 27.4],
        [1222646400000, 25.01],
        [1222732800000, 26.69],
        [1222819200000, 26.48],
        [1222905600000, 26.25],
        [1222992000000, 26.32],
        [1223251200000, 24.91],
        [1223337600000, 23.23],
        [1223424000000, 23.01],
        [1223510400000, 22.3],
        [1223596800000, 21.5],
        [1223856000000, 25.5],
        [1223942400000, 24.1],
        [1224028800000, 22.66],
        [1224115200000, 24.19],
        [1224201600000, 23.93],
        [1224460800000, 24.72],
        [1224547200000, 23.36],
        [1224633600000, 21.53],
        [1224720000000, 22.32],
        [1224806400000, 21.96],
        [1225065600000, 21.18],
        [1225152000000, 23.1],
        [1225238400000, 23],
        [1225324800000, 22.63],
        [1225411200000, 22.33],
        [1225670400000, 22.62],
        [1225756800000, 23.53],
        [1225843200000, 22.08],
        [1225929600000, 20.88],
        [1226016000000, 21.5],
        [1226275200000, 21.3],
        [1226361600000, 21.2],
        [1226448000000, 20.3],
        [1226534400000, 21.25],
        [1226620800000, 20.06],
        [1226880000000, 19.32],
        [1226966400000, 19.62],
        [1227052800000, 18.29],
        [1227139200000, 17.53],
        [1227225600000, 19.68],
        [1227484800000, 20.69],
        [1227571200000, 19.99],
        [1227657600000, 20.49],
        [1227744000000, 20.49],
        [1227830400000, 20.22],
        [1228089600000, 18.61],
        [1228176000000, 19.15],
        [1228262400000, 19.87],
        [1228348800000, 19.11],
        [1228435200000, 19.87],
        [1228694400000, 21.01],
        [1228780800000, 20.6],
        [1228867200000, 20.61],
        [1228953600000, 19.45],
        [1229040000000, 19.36],
        [1229299200000, 19.04],
        [1229385600000, 20.11],
        [1229472000000, 19.66],
        [1229558400000, 19.3],
        [1229644800000, 19.12],
        [1229904000000, 19.18],
        [1229990400000, 19.28],
        [1230076800000, 19.17],
        [1230163200000, 19.17],
        [1230249600000, 19.13],
        [1230508800000, 18.96],
        [1230595200000, 19.34],
        [1230681600000, 19.44],
        [1230768000000, 19.44],
        [1230854400000, 20.33],
        [1231113600000, 20.52],
        [1231200000000, 20.76],
        [1231286400000, 19.51],
        [1231372800000, 20.12],
        [1231459200000, 19.52],
        [1231718400000, 19.47],
        [1231804800000, 19.82],
        [1231891200000, 19.09],
        [1231977600000, 19.24],
        [1232064000000, 19.71],
        [1232409600000, 18.48],
        [1232496000000, 19.38],
        [1232582400000, 17.11],
        [1232668800000, 17.2],
        [1232928000000, 17.63],
        [1233014400000, 17.66],
        [1233100800000, 18.04],
        [1233187200000, 17.59],
        [1233273600000, 17.1],
        [1233532800000, 17.83],
        [1233619200000, 18.5],
        [1233705600000, 18.63],
        [1233792000000, 19.04],
        [1233878400000, 19.66],
        [1234137600000, 19.44],
        [1234224000000, 18.8],
        [1234310400000, 19.21],
        [1234396800000, 19.26],
        [1234483200000, 19.09],
        [1234828800000, 18.09],
        [1234915200000, 18.12],
        [1235001600000, 17.91],
        [1235088000000, 18],
        [1235347200000, 17.21],
        [1235433600000, 17.17],
        [1235520000000, 16.96],
        [1235606400000, 16.42],
        [1235692800000, 16.15],
        [1235952000000, 15.79],
        [1236038400000, 15.88],
        [1236124800000, 16.12],
        [1236211200000, 15.27],
        [1236297600000, 15.28],
        [1236556800000, 15.15],
        [1236643200000, 16.48],
        [1236729600000, 17.11],
        [1236816000000, 17.01],
        [1236902400000, 16.65],
        [1237161600000, 16.25],
        [1237248000000, 16.9],
        [1237334400000, 16.96],
        [1237420800000, 17.14],
        [1237507200000, 17.06],
        [1237766400000, 18.33],
        [1237852800000, 17.93],
        [1237939200000, 17.88],
        [1238025600000, 18.83],
        [1238112000000, 18.13],
        [1238371200000, 17.48],
        [1238457600000, 18.37],
        [1238544000000, 19.31],
        [1238630400000, 19.29],
        [1238716800000, 18.75],
        [1238976000000, 18.76],
        [1239062400000, 18.76],
        [1239148800000, 19.19],
        [1239235200000, 19.67],
        [1239321600000, 19.67],
        [1239580800000, 19.59],
        [1239667200000, 19.35],
        [1239753600000, 18.83],
        [1239840000000, 19.76],
        [1239926400000, 19.2],
        [1240185600000, 18.61],
        [1240272000000, 18.97],
        [1240358400000, 18.78],
        [1240444800000, 18.92],
        [1240531200000, 20.91],
        [1240790400000, 20.4],
        [1240876800000, 19.93],
        [1240963200000, 20.25],
        [1241049600000, 20.26],
        [1241136000000, 20.24],
        [1241395200000, 20.19],
        [1241481600000, 19.79],
        [1241568000000, 19.79],
        [1241654400000, 19.32],
        [1241740800000, 19.42],
        [1242000000000, 19.32],
        [1242086400000, 19.89],
        [1242172800000, 19.75],
        [1242259200000, 20.06],
        [1242345600000, 20.22],
        [1242604800000, 20.6],
        [1242691200000, 20.31],
        [1242777600000, 20.38],
        [1242864000000, 19.82],
        [1242950400000, 19.75],
        [1243296000000, 20.34],
        [1243382400000, 20.13],
        [1243468800000, 20.45],
        [1243555200000, 20.89],
        [1243814400000, 21.4],
        [1243900800000, 21.4],
        [1243987200000, 21.73],
        [1244073600000, 21.83],
        [1244160000000, 22.14],
        [1244419200000, 22.05],
        [1244505600000, 22.08],
        [1244592000000, 22.55],
        [1244678400000, 22.83],
        [1244764800000, 23.33],
        [1245024000000, 23.42],
        [1245110400000, 23.45],
        [1245196800000, 23.68],
        [1245283200000, 23.5],
        [1245369600000, 24.07],
        [1245628800000, 23.28],
        [1245715200000, 23.34],
        [1245801600000, 23.47],
        [1245888000000, 23.79],
        [1245974400000, 23.35],
        [1246233600000, 23.86],
        [1246320000000, 23.77],
        [1246406400000, 24.04],
        [1246492800000, 23.37],
        [1246579200000, 23.37],
        [1246838400000, 23.2],
        [1246924800000, 22.53],
        [1247011200000, 22.56],
        [1247097600000, 22.44],
        [1247184000000, 22.39],
        [1247443200000, 23.23],
        [1247529600000, 23.11],
        [1247616000000, 24.12],
        [1247702400000, 24.44],
        [1247788800000, 24.29],
        [1248048000000, 24.53],
        [1248134400000, 24.83],
        [1248220800000, 24.8],
        [1248307200000, 25.56],
        [1248393600000, 23.45],
        [1248652800000, 23.11],
        [1248739200000, 23.47],
        [1248825600000, 23.8],
        [1248912000000, 23.81],
        [1248998400000, 23.52],
        [1249257600000, 23.83],
        [1249344000000, 23.77],
        [1249430400000, 23.81],
        [1249516800000, 23.46],
        [1249603200000, 23.56],
        [1249862400000, 23.42],
        [1249948800000, 23.13],
        [1250035200000, 23.53],
        [1250121600000, 23.62],
        [1250208000000, 23.69],
        [1250467200000, 23.25],
        [1250553600000, 23.58],
        [1250640000000, 23.65],
        [1250726400000, 23.67],
        [1250812800000, 24.41],
        [1251072000000, 24.64],
        [1251158400000, 24.64],
        [1251244800000, 24.55],
        [1251331200000, 24.69],
        [1251417600000, 24.68],
        [1251676800000, 24.65],
        [1251763200000, 24],
        [1251849600000, 23.86],
        [1251936000000, 24.11],
        [1252022400000, 24.62],
        [1252368000000, 24.82],
        [1252454400000, 24.78],
        [1252540800000, 25],
        [1252627200000, 24.86],
        [1252886400000, 25],
        [1252972800000, 25.2],
        [1253059200000, 25.2],
        [1253145600000, 25.3],
        [1253232000000, 25.26],
        [1253491200000, 25.3],
        [1253577600000, 25.77],
        [1253664000000, 25.71],
        [1253750400000, 25.94],
        [1253836800000, 25.55],
        [1254096000000, 25.83],
        [1254182400000, 25.75],
        [1254268800000, 25.72],
        [1254355200000, 24.88],
        [1254441600000, 24.96],
        [1254700800000, 24.64],
        [1254787200000, 25.11],
        [1254873600000, 25.1],
        [1254960000000, 25.67],
        [1255046400000, 25.55],
        [1255305600000, 25.72],
        [1255392000000, 25.81],
        [1255478400000, 25.96],
        [1255564800000, 26.71],
        [1255651200000, 26.5],
        [1255910400000, 26.36],
        [1255996800000, 26.37],
        [1256083200000, 26.58],
        [1256169600000, 26.59],
        [1256256000000, 28.02],
        [1256515200000, 28.68],
        [1256601600000, 28.59],
        [1256688000000, 28.02],
        [1256774400000, 28.22],
        [1256860800000, 27.73],
        [1257120000000, 27.88],
        [1257206400000, 27.53],
        [1257292800000, 28.06],
        [1257379200000, 28.47],
        [1257465600000, 28.52],
        [1257724800000, 28.99],
        [1257811200000, 29.01],
        [1257897600000, 29.12],
        [1257984000000, 29.36],
        [1258070400000, 29.63],
        [1258329600000, 29.54],
        [1258416000000, 30],
        [1258502400000, 30.11],
        [1258588800000, 29.78],
        [1258675200000, 29.62],
        [1258934400000, 29.94],
        [1259020800000, 29.91],
        [1259107200000, 29.79],
        [1259193600000, 29.79],
        [1259280000000, 29.22],
        [1259539200000, 29.41],
        [1259625600000, 30.01],
        [1259712000000, 29.78],
        [1259798400000, 29.83],
        [1259884800000, 29.98],
        [1260144000000, 29.79],
        [1260230400000, 29.57],
        [1260316800000, 29.71],
        [1260403200000, 29.87],
        [1260489600000, 29.85],
        [1260748800000, 30.11],
        [1260835200000, 30.02],
        [1260921600000, 30.1],
        [1261008000000, 29.6],
        [1261094400000, 30.36],
        [1261353600000, 30.52],
        [1261440000000, 30.82],
        [1261526400000, 30.92],
        [1261612800000, 31],
        [1261699200000, 31],
        [1261958400000, 31.17],
        [1262044800000, 31.39],
        [1262131200000, 30.96],
        [1262217600000, 30.48],
        [1262304000000, 30.48],
        [1262563200000, 30.95],
        [1262649600000, 30.96],
        [1262736000000, 30.77],
        [1262822400000, 30.45],
        [1262908800000, 30.66],
        [1263168000000, 30.27],
        [1263254400000, 30.07],
        [1263340800000, 30.35],
        [1263427200000, 30.96],
        [1263513600000, 30.86],
        [1263772800000, 30.86],
        [1263859200000, 31.1],
        [1263945600000, 30.58],
        [1264032000000, 30.01],
        [1264118400000, 28.96],
        [1264377600000, 29.32],
        [1264464000000, 29.5],
        [1264550400000, 29.67],
        [1264636800000, 29.16],
        [1264723200000, 28.18],
        [1264982400000, 28.41],
        [1265068800000, 28.46],
        [1265155200000, 28.63],
        [1265241600000, 27.84],
        [1265328000000, 28.02],
        [1265587200000, 27.72],
        [1265673600000, 28.01],
        [1265760000000, 27.99],
        [1265846400000, 28.12],
        [1265932800000, 27.93],
        [1266192000000, 27.93],
        [1266278400000, 28.35],
        [1266364800000, 28.59],
        [1266451200000, 28.97],
        [1266537600000, 28.77],
        [1266796800000, 28.73],
        [1266883200000, 28.33],
        [1266969600000, 28.63],
        [1267056000000, 28.6],
        [1267142400000, 28.67],
        [1267401600000, 29.02],
        [1267488000000, 28.46],
        [1267574400000, 28.46],
        [1267660800000, 28.63],
        [1267747200000, 28.59],
        [1268006400000, 28.63],
        [1268092800000, 28.8],
        [1268179200000, 28.97],
        [1268265600000, 29.18],
        [1268352000000, 29.27],
        [1268611200000, 29.29],
        [1268697600000, 29.37],
        [1268784000000, 29.63],
        [1268870400000, 29.61],
        [1268956800000, 29.59],
        [1269216000000, 29.6],
        [1269302400000, 29.88],
        [1269388800000, 29.65],
        [1269475200000, 30.01],
        [1269561600000, 29.66],
        [1269820800000, 29.59],
        [1269907200000, 29.77],
        [1269993600000, 29.29],
        [1270080000000, 29.16],
        [1270166400000, 29.16],
        [1270425600000, 29.27],
        [1270512000000, 29.32],
        [1270598400000, 29.35],
        [1270684800000, 29.92],
        [1270771200000, 30.34],
        [1271030400000, 30.32],
        [1271116800000, 30.45],
        [1271203200000, 30.82],
        [1271289600000, 30.87],
        [1271376000000, 30.67],
        [1271635200000, 31.04],
        [1271721600000, 31.36],
        [1271808000000, 31.33],
        [1271894400000, 31.39],
        [1271980800000, 30.96],
        [1272240000000, 31.11],
        [1272326400000, 30.84],
        [1272412800000, 30.91],
        [1272499200000, 31],
        [1272585600000, 30.54],
        [1272844800000, 30.86],
        [1272931200000, 30.13],
        [1273017600000, 29.85],
        [1273104000000, 28.98],
        [1273190400000, 28.21],
        [1273449600000, 28.94],
        [1273536000000, 28.88],
        [1273622400000, 29.44],
        [1273708800000, 29.24],
        [1273795200000, 28.93],
        [1274054400000, 28.94],
        [1274140800000, 28.6],
        [1274227200000, 28.24],
        [1274313600000, 27.11],
        [1274400000000, 26.84],
        [1274659200000, 26.27],
        [1274745600000, 26.07],
        [1274832000000, 25.01],
        [1274918400000, 26],
        [1275004800000, 25.8],
        [1275264000000, 25.8],
        [1275350400000, 25.89],
        [1275436800000, 26.46],
        [1275523200000, 26.86],
        [1275609600000, 25.79],
        [1275868800000, 25.29],
        [1275955200000, 25.11],
        [1276041600000, 24.79],
        [1276128000000, 25],
        [1276214400000, 25.66],
        [1276473600000, 25.5],
        [1276560000000, 26.58],
        [1276646400000, 26.32],
        [1276732800000, 26.37],
        [1276819200000, 26.44],
        [1277078400000, 25.95],
        [1277164800000, 25.77],
        [1277251200000, 25.31],
        [1277337600000, 25],
        [1277424000000, 24.53],
        [1277683200000, 24.31],
        [1277769600000, 23.31],
        [1277856000000, 23.01],
        [1277942400000, 23.16],
        [1278028800000, 23.27],
        [1278288000000, 23.27],
        [1278374400000, 23.82],
        [1278460800000, 24.3],
        [1278547200000, 24.41],
        [1278633600000, 24.27],
        [1278892800000, 24.83],
        [1278979200000, 25.13],
        [1279065600000, 25.44],
        [1279152000000, 25.51],
        [1279238400000, 24.89],
        [1279497600000, 25.23],
        [1279584000000, 25.48],
        [1279670400000, 25.12],
        [1279756800000, 25.84],
        [1279843200000, 25.81],
        [1280102400000, 26.1],
        [1280188800000, 26.16],
        [1280275200000, 25.95],
        [1280361600000, 26.03],
        [1280448000000, 25.81],
        [1280707200000, 26.33],
        [1280793600000, 26.16],
        [1280880000000, 25.73],
        [1280966400000, 25.37],
        [1281052800000, 25.55],
        [1281312000000, 25.61],
        [1281398400000, 25.07],
        [1281484800000, 24.86],
        [1281571200000, 24.49],
        [1281657600000, 24.4],
        [1281916800000, 24.5],
        [1282003200000, 24.71],
        [1282089600000, 24.82],
        [1282176000000, 24.44],
        [1282262400000, 24.23],
        [1282521600000, 24.28],
        [1282608000000, 24.04],
        [1282694400000, 24.1],
        [1282780800000, 23.82],
        [1282867200000, 23.93],
        [1283126400000, 23.64],
        [1283212800000, 23.46],
        [1283299200000, 23.9],
        [1283385600000, 23.94],
        [1283472000000, 24.29],
        [1283731200000, 24.29],
        [1283817600000, 23.96],
        [1283904000000, 23.93],
        [1283990400000, 24.01],
        [1284076800000, 23.85],
        [1284336000000, 25.11],
        [1284422400000, 25.03],
        [1284508800000, 25.12],
        [1284595200000, 25.33],
        [1284681600000, 25.22],
        [1284940800000, 25.43],
        [1285027200000, 25.15],
        [1285113600000, 24.61],
        [1285200000000, 24.43],
        [1285286400000, 24.78],
        [1285545600000, 24.73],
        [1285632000000, 24.68],
        [1285718400000, 24.5],
        [1285804800000, 24.49],
        [1285891200000, 24.38],
        [1286150400000, 23.91],
        [1286236800000, 24.35],
        [1286323200000, 24.43],
        [1286409600000, 24.53],
        [1286496000000, 24.57],
        [1286755200000, 24.59],
        [1286841600000, 24.83],
        [1286928000000, 25.34],
        [1287014400000, 25.23],
        [1287100800000, 25.54],
        [1287360000000, 25.82],
        [1287446400000, 25.1],
        [1287532800000, 25.31],
        [1287619200000, 25.42],
        [1287705600000, 25.38],
        [1287964800000, 25.19],
        [1288051200000, 25.9],
        [1288137600000, 26.05],
        [1288224000000, 26.28],
        [1288310400000, 26.66],
        [1288569600000, 26.95],
        [1288656000000, 27.39],
        [1288742400000, 27.03],
        [1288828800000, 27.14],
        [1288915200000, 26.85],
        [1289174400000, 26.81],
        [1289260800000, 26.95],
        [1289347200000, 26.94],
        [1289433600000, 26.68],
        [1289520000000, 26.27],
        [1289779200000, 26.2],
        [1289865600000, 25.81],
        [1289952000000, 25.57],
        [1290038400000, 25.84],
        [1290124800000, 25.69],
        [1290384000000, 25.73],
        [1290470400000, 25.12],
        [1290556800000, 25.37],
        [1290729600000, 25.25],
        [1290988800000, 25.31],
        [1291075200000, 25.26],
        [1291161600000, 26.04],
        [1291248000000, 26.89],
        [1291334400000, 27.02],
        [1291593600000, 26.84],
        [1291680000000, 26.87],
        [1291766400000, 27.23],
        [1291852800000, 27.08],
        [1291939200000, 27.34],
        [1292198400000, 27.24],
        [1292284800000, 27.62],
        [1292371200000, 27.85],
        [1292457600000, 27.99],
        [1292544000000, 27.9],
        [1292803200000, 27.81],
        [1292889600000, 28.07],
        [1292976000000, 28.19],
        [1293062400000, 28.3],
        [1293408000000, 28.07],
        [1293494400000, 28.01],
        [1293580800000, 27.97],
        [1293667200000, 27.85],
        [1293753600000, 27.91],
        [1294012800000, 27.98],
        [1294099200000, 28.09],
        [1294185600000, 28],
        [1294272000000, 28.82],
        [1294358400000, 28.6],
        [1294617600000, 28.22],
        [1294704000000, 28.11],
        [1294790400000, 28.55],
        [1294876800000, 28.19],
        [1294963200000, 28.3],
        [1295308800000, 28.66],
        [1295395200000, 28.47],
        [1295481600000, 28.35],
        [1295568000000, 28.02],
        [1295827200000, 28.38],
        [1295913600000, 28.45],
        [1296000000000, 28.78],
        [1296086400000, 28.87],
        [1296172800000, 27.75],
        [1296432000000, 27.72],
        [1296518400000, 27.99],
        [1296604800000, 27.94],
        [1296691200000, 27.65],
        [1296777600000, 27.77],
        [1297036800000, 28.2],
        [1297123200000, 28.28],
        [1297209600000, 27.97],
        [1297296000000, 27.5],
        [1297382400000, 27.25],
        [1297641600000, 27.23],
        [1297728000000, 26.96],
        [1297814400000, 27.02],
        [1297900800000, 27.21],
        [1297987200000, 27.06],
        [1298332800000, 26.59],
        [1298419200000, 26.59],
        [1298505600000, 26.77],
        [1298592000000, 26.55],
        [1298851200000, 26.58],
        [1298937600000, 26.16],
        [1299024000000, 26.08],
        [1299110400000, 26.2],
        [1299196800000, 25.95],
        [1299456000000, 25.72],
        [1299542400000, 25.91],
        [1299628800000, 25.89],
        [1299715200000, 25.41],
        [1299801600000, 25.68],
        [1300060800000, 25.69],
        [1300147200000, 25.39],
        [1300233600000, 24.79],
        [1300320000000, 24.78],
        [1300406400000, 24.8],
        [1300665600000, 25.33],
        [1300752000000, 25.3],
        [1300838400000, 25.54],
        [1300924800000, 25.81],
        [1301011200000, 25.62],
        [1301270400000, 25.41],
        [1301356800000, 25.49],
        [1301443200000, 25.61],
        [1301529600000, 25.39],
        [1301616000000, 25.48],
        [1301875200000, 25.55],
        [1301961600000, 25.78],
        [1302048000000, 26.15],
        [1302134400000, 26.2],
        [1302220800000, 26.07],
        [1302480000000, 25.98],
        [1302566400000, 25.64],
        [1302652800000, 25.63],
        [1302739200000, 25.42],
        [1302825600000, 25.37],
        [1303084800000, 25.08],
        [1303171200000, 25.15],
        [1303257600000, 25.76],
        [1303344000000, 25.52],
        [1303689600000, 25.61],
        [1303776000000, 26.19],
        [1303862400000, 26.38],
        [1303948800000, 26.71],
        [1304035200000, 25.92],
        [1304294400000, 25.66],
        [1304380800000, 25.81],
        [1304467200000, 26.06],
        [1304553600000, 25.79],
        [1304640000000, 25.87],
        [1304899200000, 25.83],
        [1304985600000, 25.67],
        [1305072000000, 25.36],
        [1305158400000, 25.32],
        [1305244800000, 25.03],
        [1305504000000, 24.57],
        [1305590400000, 24.52],
        [1305676800000, 24.69],
        [1305763200000, 24.72],
        [1305849600000, 24.49],
        [1306108800000, 24.17],
        [1306195200000, 24.15],
        [1306281600000, 24.19],
        [1306368000000, 24.67],
        [1306454400000, 24.76],
        [1306800000000, 25.01],
        [1306886400000, 24.43],
        [1306972800000, 24.22],
        [1307059200000, 23.9],
        [1307318400000, 24.01],
        [1307404800000, 24.06],
        [1307491200000, 23.94],
        [1307577600000, 23.96],
        [1307664000000, 23.7],
        [1307923200000, 24.04],
        [1308009600000, 24.22],
        [1308096000000, 23.74],
        [1308182400000, 24],
        [1308268800000, 24.26],
        [1308528000000, 24.47],
        [1308614400000, 24.76],
        [1308700800000, 24.65],
        [1308787200000, 24.63],
        [1308873600000, 24.3],
        [1309132800000, 25.2],
        [1309219200000, 25.8],
        [1309305600000, 25.62],
        [1309392000000, 26],
        [1309478400000, 26.02],
        [1309824000000, 26.03],
        [1309910400000, 26.33],
        [1309996800000, 26.77],
        [1310083200000, 26.92],
        [1310342400000, 26.63],
        [1310428800000, 26.54],
        [1310515200000, 26.63],
        [1310601600000, 26.47],
        [1310688000000, 26.78],
        [1310947200000, 26.59],
        [1311033600000, 27.54],
        [1311120000000, 27.06],
        [1311206400000, 27.1],
        [1311292800000, 27.53],
        [1311552000000, 27.91],
        [1311638400000, 28.08],
        [1311724800000, 27.33],
        [1311811200000, 27.72],
        [1311897600000, 27.4],
        [1312156800000, 27.27],
        [1312243200000, 26.64],
        [1312329600000, 26.92],
        [1312416000000, 25.94],
        [1312502400000, 25.68],
        [1312761600000, 24.48],
        [1312848000000, 25.58],
        [1312934400000, 24.2],
        [1313020800000, 25.19],
        [1313107200000, 25.1],
        [1313366400000, 25.51],
        [1313452800000, 25.35],
        [1313539200000, 25.24],
        [1313625600000, 24.67],
        [1313712000000, 24.05],
        [1313971200000, 23.98],
        [1314057600000, 24.72],
        [1314144000000, 24.9],
        [1314230400000, 24.57],
        [1314316800000, 25.25],
        [1314576000000, 25.84],
        [1314662400000, 26.23],
        [1314748800000, 26.6],
        [1314835200000, 26.21],
        [1314921600000, 25.8],
        [1315267200000, 25.51],
        [1315353600000, 26],
        [1315440000000, 26.22],
        [1315526400000, 25.74],
        [1315785600000, 25.89],
        [1315872000000, 26.04],
        [1315958400000, 26.5],
        [1316044800000, 26.99],
        [1316131200000, 27.12],
        [1316390400000, 27.21],
        [1316476800000, 26.98],
        [1316563200000, 25.99],
        [1316649600000, 25.06],
        [1316736000000, 25.06],
        [1316995200000, 25.44],
        [1317081600000, 25.67],
        [1317168000000, 25.58],
        [1317254400000, 25.45],
        [1317340800000, 24.89],
        [1317600000000, 24.53],
        [1317686400000, 25.34],
        [1317772800000, 25.89],
        [1317859200000, 26.34],
        [1317945600000, 26.25],
        [1318204800000, 26.94],
        [1318291200000, 27],
        [1318377600000, 26.96],
        [1318464000000, 27.18],
        [1318550400000, 27.27],
        [1318809600000, 26.98],
        [1318896000000, 27.31],
        [1318982400000, 27.13],
        [1319068800000, 27.04],
        [1319155200000, 27.16],
        [1319414400000, 27.19],
        [1319500800000, 26.81],
        [1319587200000, 26.59],
        [1319673600000, 27.25],
        [1319760000000, 26.98],
        [1320019200000, 26.63],
        [1320105600000, 25.99],
        [1320192000000, 26.01],
        [1320278400000, 26.53],
        [1320364800000, 26.25],
        [1320624000000, 26.8],
        [1320710400000, 27.16],
        [1320796800000, 26.2],
        [1320883200000, 26.28],
        [1320969600000, 26.91],
        [1321228800000, 26.76],
        [1321315200000, 26.74],
        [1321401600000, 26.07],
        [1321488000000, 25.54],
        [1321574400000, 25.3],
        [1321833600000, 25],
        [1321920000000, 24.79],
        [1322006400000, 24.47],
        [1322179200000, 24.3],
        [1322438400000, 24.87],
        [1322524800000, 24.84],
        [1322611200000, 25.58],
        [1322697600000, 25.28],
        [1322784000000, 25.22],
        [1323043200000, 25.7],
        [1323129600000, 25.66],
        [1323216000000, 25.6],
        [1323302400000, 25.4],
        [1323388800000, 25.7],
        [1323648000000, 25.51],
        [1323734400000, 25.76],
        [1323820800000, 25.59],
        [1323907200000, 25.56],
        [1323993600000, 26],
        [1324252800000, 25.53],
        [1324339200000, 26.02],
        [1324425600000, 25.76],
        [1324512000000, 25.81],
        [1324598400000, 26.03],
        [1324944000000, 26.04],
        [1325030400000, 25.82],
        [1325116800000, 26.02],
        [1325203200000, 25.96],
        [1325548800000, 26.76],
        [1325635200000, 27.4],
        [1325721600000, 27.68],
        [1325808000000, 28.1],
        [1326067200000, 27.74],
        [1326153600000, 27.84],
        [1326240000000, 27.72],
        [1326326400000, 28],
        [1326412800000, 28.25],
        [1326758400000, 28.26],
        [1326844800000, 28.23],
        [1326931200000, 28.12],
        [1327017600000, 29.71],
        [1327276800000, 29.73],
        [1327363200000, 29.34],
        [1327449600000, 29.56],
        [1327536000000, 29.5],
        [1327622400000, 29.23],
        [1327881600000, 29.61],
        [1327968000000, 29.53],
        [1328054400000, 29.89],
        [1328140800000, 29.95],
        [1328227200000, 30.24],
        [1328486400000, 30.2],
        [1328572800000, 30.35],
        [1328659200000, 30.66],
        [1328745600000, 30.77],
        [1328832000000, 30.5],
        [1329091200000, 30.58],
        [1329177600000, 30.25],
        [1329264000000, 30.05],
        [1329350400000, 31.28],
        [1329436800000, 31.25],
        [1329782400000, 31.44],
        [1329868800000, 31.27],
        [1329955200000, 31.37],
        [1330041600000, 31.48],
        [1330300800000, 31.35],
        [1330387200000, 31.87],
        [1330473600000, 31.74],
        [1330560000000, 32.29],
        [1330646400000, 32.08],
        [1330905600000, 31.8],
        [1330992000000, 31.56],
        [1331078400000, 31.84],
        [1331164800000, 32.01],
        [1331251200000, 31.99],
        [1331510400000, 32.04],
        [1331596800000, 32.67],
        [1331683200000, 32.77],
        [1331769600000, 32.85],
        [1331856000000, 32.6],
        [1332115200000, 32.2],
        [1332201600000, 31.99],
        [1332288000000, 31.91],
        [1332374400000, 32],
        [1332460800000, 32.01],
        [1332720000000, 32.59],
        [1332806400000, 32.52],
        [1332892800000, 32.19],
        [1332979200000, 32.12],
        [1333065600000, 32.26],
        [1333324800000, 32.29],
        [1333411200000, 31.94],
        [1333497600000, 31.21],
        [1333584000000, 31.52],
        [1333929600000, 31.1],
        [1334016000000, 30.47],
        [1334102400000, 30.34],
        [1334188800000, 30.98],
        [1334275200000, 30.81],
        [1334534400000, 31.08],
        [1334620800000, 31.44],
        [1334707200000, 31.14],
        [1334793600000, 31.01],
        [1334880000000, 32.42],
        [1335139200000, 32.12],
        [1335225600000, 31.92],
        [1335312000000, 32.2],
        [1335398400000, 32.11],
        [1335484800000, 31.98],
        [1335744000000, 32.02],
        [1335830400000, 32.01],
        [1335916800000, 31.8],
        [1336003200000, 31.76],
        [1336089600000, 30.98],
        [1336348800000, 30.65],
        [1336435200000, 30.5],
        [1336521600000, 30.76],
        [1336608000000, 30.74],
        [1336694400000, 31.16],
        [1336953600000, 30.68],
        [1337040000000, 30.21],
        [1337126400000, 29.9],
        [1337212800000, 29.72],
        [1337299200000, 29.27],
        [1337558400000, 29.75],
        [1337644800000, 29.76],
        [1337731200000, 29.11],
        [1337817600000, 29.07],
        [1337904000000, 29.06],
        [1338249600000, 29.56],
        [1338336000000, 29.34],
        [1338422400000, 29.19],
        [1338508800000, 28.45],
        [1338768000000, 28.55],
        [1338854400000, 28.5],
        [1338940800000, 29.35],
        [1339027200000, 29.23],
        [1339113600000, 29.65],
        [1339372800000, 28.9],
        [1339459200000, 29.29],
        [1339545600000, 29.13],
        [1339632000000, 29.34],
        [1339718400000, 30.02],
        [1339977600000, 29.84],
        [1340064000000, 30.7],
        [1340150400000, 30.93],
        [1340236800000, 30.14],
        [1340323200000, 30.7],
        [1340582400000, 29.86],
        [1340668800000, 30.02],
        [1340755200000, 30.17],
        [1340841600000, 29.91],
        [1340928000000, 30.59],
        [1341187200000, 30.56],
        [1341273600000, 30.76],
        [1341446400000, 30.7],
        [1341532800000, 30.18],
        [1341792000000, 30],
        [1341878400000, 29.74],
        [1341964800000, 29.3],
        [1342051200000, 28.63],
        [1342137600000, 29.39],
        [1342396800000, 29.44],
        [1342483200000, 29.66],
        [1342569600000, 30.45],
        [1342656000000, 30.66],
        [1342742400000, 30.12],
        [1343001600000, 29.28],
        [1343088000000, 29.15],
        [1343174400000, 28.83],
        [1343260800000, 29.16],
        [1343347200000, 29.76],
        [1343606400000, 29.64],
        [1343692800000, 29.47],
        [1343779200000, 29.41],
        [1343865600000, 29.19],
        [1343952000000, 29.75],
        [1344211200000, 29.95],
        [1344297600000, 30.26],
        [1344384000000, 30.33],
        [1344470400000, 30.5],
        [1344556800000, 30.42],
        [1344816000000, 30.39],
        [1344902400000, 30.13],
        [1344988800000, 30.2],
        [1345075200000, 30.78],
        [1345161600000, 30.9],
        [1345420800000, 30.74],
        [1345507200000, 30.8],
        [1345593600000, 30.54],
        [1345680000000, 30.26],
        [1345766400000, 30.56],
        [1346025600000, 30.69],
        [1346112000000, 30.63],
        [1346198400000, 30.65],
        [1346284800000, 30.32],
        [1346371200000, 30.82],
        [1346716800000, 30.38],
        [1346803200000, 30.39],
        [1346889600000, 31.34],
        [1346976000000, 30.95],
        [1347235200000, 30.72],
        [1347321600000, 30.79],
        [1347408000000, 30.78],
        [1347494400000, 30.94],
        [1347580800000, 31.21],
        [1347840000000, 31.21],
        [1347926400000, 31.18],
        [1348012800000, 31.05],
        [1348099200000, 31.45],
        [1348185600000, 31.19],
        [1348444800000, 30.78],
        [1348531200000, 30.39],
        [1348617600000, 30.16],
        [1348704000000, 30.16],
        [1348790400000, 29.76],
        [1349049600000, 29.49],
        [1349136000000, 29.66],
        [1349222400000, 29.86],
        [1349308800000, 30.03],
        [1349395200000, 29.85],
        [1349654400000, 29.78],
        [1349740800000, 29.28],
        [1349827200000, 28.98],
        [1349913600000, 28.95],
        [1350000000000, 29.2],
        [1350259200000, 29.51],
        [1350345600000, 29.49],
        [1350432000000, 29.59],
        [1350518400000, 29.5],
        [1350604800000, 28.64],
        [1350864000000, 28],
        [1350950400000, 28.05],
        [1351036800000, 27.9],
        [1351123200000, 27.88],
        [1351209600000, 28.21],
        [1351641600000, 28.54],
        [1351728000000, 29.52],
        [1351814400000, 29.5],
        [1352073600000, 29.63],
        [1352160000000, 29.86],
        [1352246400000, 29.08],
        [1352332800000, 28.81],
        [1352419200000, 28.83],
        [1352678400000, 28.22],
        [1352764800000, 27.09],
        [1352851200000, 26.84],
        [1352937600000, 26.66],
        [1353024000000, 26.52],
        [1353283200000, 26.73],
        [1353369600000, 26.71],
        [1353456000000, 26.95],
        [1353628800000, 27.7],
        [1353888000000, 27.38],
        [1353974400000, 27.08],
        [1354060800000, 27.36],
        [1354147200000, 26.95],
        [1354233600000, 26.62],
        [1354492800000, 26.43],
        [1354579200000, 26.37],
        [1354665600000, 26.67],
        [1354752000000, 26.72],
        [1354838400000, 26.46],
        [1355097600000, 26.94],
        [1355184000000, 27.32],
        [1355270400000, 27.24],
        [1355356800000, 27.11],
        [1355443200000, 26.81],
        [1355702400000, 27.1],
        [1355788800000, 27.56],
        [1355875200000, 27.31],
        [1355961600000, 27.68],
        [1356048000000, 27.45],
        [1356307200000, 27.06],
        [1356480000000, 26.86],
        [1356566400000, 26.96],
        [1356652800000, 26.55],
        [1356912000000, 26.71],
        [1357084800000, 27.62],
        [1357171200000, 27.25],
        [1357257600000, 26.74],
        [1357516800000, 26.69],
        [1357603200000, 26.55],
        [1357689600000, 26.7],
        [1357776000000, 26.46],
        [1357862400000, 26.83],
        [1358121600000, 26.89],
        [1358208000000, 27.21],
        [1358294400000, 27.04],
        [1358380800000, 27.25],
        [1358467200000, 27.25],
        [1358812800000, 27.15],
        [1358899200000, 27.61],
        [1358985600000, 27.63],
        [1359072000000, 27.88],
        [1359331200000, 27.91],
        [1359417600000, 28.01],
        [1359504000000, 27.85],
        [1359590400000, 27.45],
        [1359676800000, 27.93],
        [1359936000000, 27.44],
        [1360022400000, 27.5],
        [1360108800000, 27.34],
        [1360195200000, 27.28],
        [1360281600000, 27.55],
        [1360540800000, 27.86],
        [1360627200000, 27.88],
        [1360713600000, 28.03],
        [1360800000000, 28.04],
        [1360886400000, 28.01],
        [1361232000000, 28.04],
        [1361318400000, 27.87],
        [1361404800000, 27.49],
        [1361491200000, 27.76],
        [1361750400000, 27.37],
        [1361836800000, 27.37],
        [1361923200000, 27.81],
        [1362009600000, 27.8],
        [1362096000000, 27.95],
        [1362355200000, 28.15],
        [1362441600000, 28.35],
        [1362528000000, 28.09],
        [1362614400000, 28.14],
        [1362700800000, 28],
        [1362960000000, 27.87],
        [1363046400000, 27.91],
        [1363132800000, 27.92],
        [1363219200000, 28.14],
        [1363305600000, 28.04],
        [1363564800000, 28.1],
        [1363651200000, 28.18],
        [1363737600000, 28.32],
        [1363824000000, 28.11],
        [1363910400000, 28.25],
        [1364169600000, 28.16],
        [1364256000000, 28.16],
        [1364342400000, 28.37],
        [1364428800000, 28.6],
        [1364774400000, 28.61],
        [1364860800000, 28.8],
        [1364947200000, 28.56],
        [1365033600000, 28.6],
        [1365120000000, 28.7],
        [1365379200000, 28.59],
        [1365465600000, 29.61],
        [1365552000000, 30.28],
        [1365638400000, 28.94],
        [1365724800000, 28.79],
        [1365984000000, 28.69],
        [1366070400000, 28.97],
        [1366156800000, 28.82],
        [1366243200000, 28.79],
        [1366329600000, 29.76],
        [1366588800000, 30.83],
        [1366675200000, 30.6],
        [1366761600000, 31.76],
        [1366848000000, 31.94],
        [1366934400000, 31.79],
        [1367193600000, 32.61],
        [1367280000000, 33.1],
        [1367366400000, 32.72],
        [1367452800000, 33.16],
        [1367539200000, 33.49],
        [1367798400000, 33.75],
        [1367884800000, 33.31],
        [1367971200000, 32.99],
        [1368057600000, 32.66],
        [1368144000000, 32.69],
      ],
    },
    {
      name: "Demo Persson",
      type: "spline",
      data: [
        [1147651200000, 67.79],
        [1147737600000, 64.98],
        [1147824000000, 65.26],
        [1147910400000, 63.18],
        [1147996800000, 64.51],
        [1148256000000, 63.38],
        [1148342400000, 63.15],
        [1148428800000, 63.34],
        [1148515200000, 64.33],
        [1148601600000, 63.55],
        [1148947200000, 61.22],
        [1149033600000, 59.77],
        [1149120000000, 62.17],
        [1149206400000, 61.66],
        [1149465600000, 60],
        [1149552000000, 59.72],
        [1149638400000, 58.56],
        [1149724800000, 60.76],
        [1149811200000, 59.24],
        [1150070400000, 57],
        [1150156800000, 58.33],
        [1150243200000, 57.61],
        [1150329600000, 59.38],
        [1150416000000, 57.56],
        [1150675200000, 57.2],
        [1150761600000, 57.47],
        [1150848000000, 57.86],
        [1150934400000, 59.58],
        [1151020800000, 58.83],
        [1151280000000, 58.99],
        [1151366400000, 57.43],
        [1151452800000, 56.02],
        [1151539200000, 58.97],
        [1151625600000, 57.27],
        [1151884800000, 57.95],
        [1152057600000, 57],
        [1152144000000, 55.77],
        [1152230400000, 55.4],
        [1152489600000, 55],
        [1152576000000, 55.65],
        [1152662400000, 52.96],
        [1152748800000, 52.25],
        [1152835200000, 50.67],
        [1153094400000, 52.37],
        [1153180800000, 52.9],
        [1153267200000, 54.1],
        [1153353600000, 60.5],
        [1153440000000, 60.72],
        [1153699200000, 61.42],
        [1153785600000, 61.93],
        [1153872000000, 63.87],
        [1153958400000, 63.4],
        [1154044800000, 65.59],
        [1154304000000, 67.96],
        [1154390400000, 67.18],
        [1154476800000, 68.16],
        [1154563200000, 69.59],
        [1154649600000, 68.3],
        [1154908800000, 67.21],
        [1154995200000, 64.78],
        [1155081600000, 63.59],
        [1155168000000, 64.07],
        [1155254400000, 63.65],
        [1155513600000, 63.94],
        [1155600000000, 66.45],
        [1155686400000, 67.98],
        [1155772800000, 67.59],
        [1155859200000, 67.91],
        [1156118400000, 66.56],
        [1156204800000, 67.62],
        [1156291200000, 67.31],
        [1156377600000, 67.81],
        [1156464000000, 68.75],
        [1156723200000, 66.98],
        [1156809600000, 66.48],
        [1156896000000, 66.96],
        [1156982400000, 67.85],
        [1157068800000, 68.38],
        [1157414400000, 71.48],
        [1157500800000, 70.03],
        [1157587200000, 72.8],
        [1157673600000, 72.52],
        [1157932800000, 72.5],
        [1158019200000, 72.63],
        [1158105600000, 74.2],
        [1158192000000, 74.17],
        [1158278400000, 74.1],
        [1158537600000, 73.89],
        [1158624000000, 73.77],
        [1158710400000, 75.26],
        [1158796800000, 74.65],
        [1158883200000, 73],
        [1159142400000, 75.75],
        [1159228800000, 77.61],
        [1159315200000, 76.41],
        [1159401600000, 77.01],
        [1159488000000, 76.98],
        [1159747200000, 74.86],
        [1159833600000, 74.08],
        [1159920000000, 75.38],
        [1160006400000, 74.83],
        [1160092800000, 74.22],
        [1160352000000, 74.63],
        [1160438400000, 73.81],
        [1160524800000, 73.23],
        [1160611200000, 75.26],
        [1160697600000, 75.02],
        [1160956800000, 75.4],
        [1161043200000, 74.29],
        [1161129600000, 74.53],
        [1161216000000, 78.99],
        [1161302400000, 79.95],
        [1161561600000, 81.46],
        [1161648000000, 81.05],
        [1161734400000, 81.68],
        [1161820800000, 82.19],
        [1161907200000, 80.41],
        [1162166400000, 80.42],
        [1162252800000, 81.08],
        [1162339200000, 79.16],
        [1162425600000, 78.98],
        [1162512000000, 78.29],
        [1162771200000, 79.71],
        [1162857600000, 80.51],
        [1162944000000, 82.45],
        [1163030400000, 83.34],
        [1163116800000, 83.12],
        [1163376000000, 84.35],
        [1163462400000, 85],
        [1163548800000, 84.05],
        [1163635200000, 85.61],
        [1163721600000, 85.85],
        [1163980800000, 86.47],
        [1164067200000, 88.6],
        [1164153600000, 90.31],
        [1164326400000, 91.63],
        [1164585600000, 89.54],
        [1164672000000, 91.81],
        [1164758400000, 91.8],
        [1164844800000, 91.66],
        [1164931200000, 91.32],
        [1165190400000, 91.12],
        [1165276800000, 91.27],
        [1165363200000, 89.83],
        [1165449600000, 87.04],
        [1165536000000, 88.26],
        [1165795200000, 88.75],
        [1165881600000, 86.14],
        [1165968000000, 89.05],
        [1166054400000, 88.55],
        [1166140800000, 87.72],
        [1166400000000, 85.47],
        [1166486400000, 86.31],
        [1166572800000, 84.76],
        [1166659200000, 82.9],
        [1166745600000, 82.2],
        [1167091200000, 81.51],
        [1167177600000, 81.52],
        [1167264000000, 80.87],
        [1167350400000, 84.84],
        [1167782400000, 83.8],
        [1167868800000, 85.66],
        [1167955200000, 85.05],
        [1168214400000, 85.47],
        [1168300800000, 92.57],
        [1168387200000, 97],
        [1168473600000, 95.8],
        [1168560000000, 94.62],
        [1168905600000, 97.1],
        [1168992000000, 94.95],
        [1169078400000, 89.07],
        [1169164800000, 88.5],
        [1169424000000, 86.79],
        [1169510400000, 85.7],
        [1169596800000, 86.7],
        [1169683200000, 86.25],
        [1169769600000, 85.38],
        [1170028800000, 85.94],
        [1170115200000, 85.55],
        [1170201600000, 85.73],
        [1170288000000, 84.74],
        [1170374400000, 84.75],
        [1170633600000, 83.94],
        [1170720000000, 84.15],
        [1170806400000, 86.15],
        [1170892800000, 86.18],
        [1170979200000, 83.27],
        [1171238400000, 84.88],
        [1171324800000, 84.63],
        [1171411200000, 85.3],
        [1171497600000, 85.21],
        [1171584000000, 84.83],
        [1171929600000, 85.9],
        [1172016000000, 89.2],
        [1172102400000, 89.51],
        [1172188800000, 89.07],
        [1172448000000, 88.65],
        [1172534400000, 83.93],
        [1172620800000, 84.61],
        [1172707200000, 87.06],
        [1172793600000, 85.41],
        [1173052800000, 86.32],
        [1173139200000, 88.19],
        [1173225600000, 87.72],
        [1173312000000, 88],
        [1173398400000, 87.97],
        [1173657600000, 89.87],
        [1173744000000, 88.4],
        [1173830400000, 90],
        [1173916800000, 89.57],
        [1174003200000, 89.59],
        [1174262400000, 91.13],
        [1174348800000, 91.48],
        [1174435200000, 93.87],
        [1174521600000, 93.96],
        [1174608000000, 93.52],
        [1174867200000, 95.85],
        [1174953600000, 95.46],
        [1175040000000, 93.24],
        [1175126400000, 93.75],
        [1175212800000, 92.91],
        [1175472000000, 93.65],
        [1175558400000, 94.5],
        [1175644800000, 94.27],
        [1175731200000, 94.68],
        [1176076800000, 93.65],
        [1176163200000, 94.25],
        [1176249600000, 92.59],
        [1176336000000, 92.19],
        [1176422400000, 90.24],
        [1176681600000, 91.43],
        [1176768000000, 90.35],
        [1176854400000, 90.4],
        [1176940800000, 90.27],
        [1177027200000, 90.97],
        [1177286400000, 93.51],
        [1177372800000, 93.24],
        [1177459200000, 95.35],
        [1177545600000, 98.84],
        [1177632000000, 99.92],
        [1177891200000, 99.8],
        [1177977600000, 99.47],
        [1178064000000, 100.39],
        [1178150400000, 100.4],
        [1178236800000, 100.81],
        [1178496000000, 103.92],
        [1178582400000, 105.06],
        [1178668800000, 106.88],
        [1178755200000, 107.34],
        [1178841600000, 108.74],
        [1179100800000, 109.36],
        [1179187200000, 107.52],
        [1179273600000, 107.34],
        [1179360000000, 109.44],
        [1179446400000, 110.02],
        [1179705600000, 111.98],
        [1179792000000, 113.54],
        [1179878400000, 112.89],
        [1179964800000, 110.69],
        [1180051200000, 113.62],
        [1180396800000, 114.35],
        [1180483200000, 118.77],
        [1180569600000, 121.19],
        [1180656000000, 118.4],
        [1180915200000, 121.33],
        [1181001600000, 122.67],
        [1181088000000, 123.64],
        [1181174400000, 124.07],
        [1181260800000, 124.49],
        [1181520000000, 120.19],
        [1181606400000, 120.38],
        [1181692800000, 117.5],
        [1181779200000, 118.75],
        [1181865600000, 120.5],
        [1182124800000, 125.09],
        [1182211200000, 123.66],
        [1182297600000, 121.55],
        [1182384000000, 123.9],
        [1182470400000, 123],
        [1182729600000, 122.34],
        [1182816000000, 119.65],
        [1182902400000, 121.89],
        [1182988800000, 120.56],
        [1183075200000, 122.04],
        [1183334400000, 121.26],
        [1183420800000, 127.17],
        [1183593600000, 132.75],
        [1183680000000, 132.3],
        [1183939200000, 130.33],
        [1184025600000, 132.35],
        [1184112000000, 132.39],
        [1184198400000, 134.07],
        [1184284800000, 137.73],
        [1184544000000, 138.1],
        [1184630400000, 138.91],
        [1184716800000, 138.12],
        [1184803200000, 140],
        [1184889600000, 143.75],
        [1185148800000, 143.7],
        [1185235200000, 134.89],
        [1185321600000, 137.26],
        [1185408000000, 146],
        [1185494400000, 143.85],
        [1185753600000, 141.43],
        [1185840000000, 131.76],
        [1185926400000, 135],
        [1186012800000, 136.49],
        [1186099200000, 131.85],
        [1186358400000, 135.25],
        [1186444800000, 135.03],
        [1186531200000, 134.01],
        [1186617600000, 126.39],
        [1186704000000, 125],
        [1186963200000, 127.79],
        [1187049600000, 124.03],
        [1187136000000, 119.9],
        [1187222400000, 117.05],
        [1187308800000, 122.06],
        [1187568000000, 122.22],
        [1187654400000, 127.57],
        [1187740800000, 132.51],
        [1187827200000, 131.07],
        [1187913600000, 135.3],
        [1188172800000, 132.25],
        [1188259200000, 126.82],
        [1188345600000, 134.08],
        [1188432000000, 136.25],
        [1188518400000, 138.48],
        [1188864000000, 144.16],
        [1188950400000, 136.76],
        [1189036800000, 135.01],
        [1189123200000, 131.77],
        [1189382400000, 136.71],
        [1189468800000, 135.49],
        [1189555200000, 136.85],
        [1189641600000, 137.2],
        [1189728000000, 138.81],
        [1189987200000, 138.41],
        [1190073600000, 140.92],
        [1190160000000, 140.77],
        [1190246400000, 140.31],
        [1190332800000, 144.15],
        [1190592000000, 148.28],
        [1190678400000, 153.18],
        [1190764800000, 152.77],
        [1190851200000, 154.5],
        [1190937600000, 153.47],
        [1191196800000, 156.34],
        [1191283200000, 158.45],
        [1191369600000, 157.92],
        [1191456000000, 156.24],
        [1191542400000, 161.45],
        [1191801600000, 167.91],
        [1191888000000, 167.86],
        [1191974400000, 166.79],
        [1192060800000, 162.23],
        [1192147200000, 167.25],
        [1192406400000, 166.98],
        [1192492800000, 169.58],
        [1192579200000, 172.75],
        [1192665600000, 173.5],
        [1192752000000, 170.42],
        [1193011200000, 174.36],
        [1193097600000, 186.16],
        [1193184000000, 185.93],
        [1193270400000, 182.78],
        [1193356800000, 184.7],
        [1193616000000, 185.09],
        [1193702400000, 187],
        [1193788800000, 189.95],
        [1193875200000, 187.44],
        [1193961600000, 187.87],
        [1194220800000, 186.18],
        [1194307200000, 191.79],
        [1194393600000, 186.3],
        [1194480000000, 175.47],
        [1194566400000, 165.37],
        [1194825600000, 153.76],
        [1194912000000, 169.96],
        [1194998400000, 166.11],
        [1195084800000, 164.3],
        [1195171200000, 166.39],
        [1195430400000, 163.95],
        [1195516800000, 168.85],
        [1195603200000, 168.46],
        [1195776000000, 171.54],
        [1196035200000, 172.54],
        [1196121600000, 174.81],
        [1196208000000, 180.22],
        [1196294400000, 184.29],
        [1196380800000, 182.22],
        [1196640000000, 178.86],
        [1196726400000, 179.81],
        [1196812800000, 185.5],
        [1196899200000, 189.95],
        [1196985600000, 194.3],
        [1197244800000, 194.21],
        [1197331200000, 188.54],
        [1197417600000, 190.86],
        [1197504000000, 191.83],
        [1197590400000, 190.39],
        [1197849600000, 184.4],
        [1197936000000, 182.98],
        [1198022400000, 183.12],
        [1198108800000, 187.21],
        [1198195200000, 193.91],
        [1198454400000, 198.8],
        [1198627200000, 198.95],
        [1198713600000, 198.57],
        [1198800000000, 199.83],
        [1199059200000, 198.08],
        [1199232000000, 194.84],
        [1199318400000, 194.93],
        [1199404800000, 180.05],
        [1199664000000, 177.64],
        [1199750400000, 171.25],
        [1199836800000, 179.4],
        [1199923200000, 178.02],
        [1200009600000, 172.69],
        [1200268800000, 178.78],
        [1200355200000, 169.04],
        [1200441600000, 159.64],
        [1200528000000, 160.89],
        [1200614400000, 161.36],
        [1200960000000, 155.64],
        [1201046400000, 139.07],
        [1201132800000, 135.6],
        [1201219200000, 130.01],
        [1201478400000, 130.01],
        [1201564800000, 131.54],
        [1201651200000, 132.18],
        [1201737600000, 135.36],
        [1201824000000, 133.75],
        [1202083200000, 131.65],
        [1202169600000, 129.36],
        [1202256000000, 122],
        [1202342400000, 121.24],
        [1202428800000, 125.48],
        [1202688000000, 129.45],
        [1202774400000, 124.86],
        [1202860800000, 129.4],
        [1202947200000, 127.46],
        [1203033600000, 124.63],
        [1203379200000, 122.18],
        [1203465600000, 123.82],
        [1203552000000, 121.54],
        [1203638400000, 119.46],
        [1203897600000, 119.74],
        [1203984000000, 119.15],
        [1204070400000, 122.96],
        [1204156800000, 129.91],
        [1204243200000, 125.02],
        [1204502400000, 121.73],
        [1204588800000, 124.62],
        [1204675200000, 124.49],
        [1204761600000, 120.93],
        [1204848000000, 122.25],
        [1205107200000, 119.69],
        [1205193600000, 127.35],
        [1205280000000, 126.03],
        [1205366400000, 127.94],
        [1205452800000, 126.61],
        [1205712000000, 126.73],
        [1205798400000, 132.82],
        [1205884800000, 129.67],
        [1205971200000, 133.27],
        [1206316800000, 139.53],
        [1206403200000, 140.98],
        [1206489600000, 145.06],
        [1206576000000, 140.25],
        [1206662400000, 143.01],
        [1206921600000, 143.5],
        [1207008000000, 149.53],
        [1207094400000, 147.49],
        [1207180800000, 151.61],
        [1207267200000, 153.08],
        [1207526400000, 155.89],
        [1207612800000, 152.84],
        [1207699200000, 151.44],
        [1207785600000, 154.55],
        [1207872000000, 147.14],
        [1208131200000, 147.78],
        [1208217600000, 148.38],
        [1208304000000, 153.7],
        [1208390400000, 154.49],
        [1208476800000, 161.04],
        [1208736000000, 168.16],
        [1208822400000, 160.2],
        [1208908800000, 162.89],
        [1208995200000, 168.94],
        [1209081600000, 169.73],
        [1209340800000, 172.24],
        [1209427200000, 175.05],
        [1209513600000, 173.95],
        [1209600000000, 180],
        [1209686400000, 180.94],
        [1209945600000, 184.73],
        [1210032000000, 186.66],
        [1210118400000, 182.59],
        [1210204800000, 185.06],
        [1210291200000, 183.45],
        [1210550400000, 188.16],
        [1210636800000, 189.96],
        [1210723200000, 186.26],
        [1210809600000, 189.73],
        [1210896000000, 187.62],
        [1211155200000, 183.6],
        [1211241600000, 185.9],
        [1211328000000, 178.19],
        [1211414400000, 177.05],
        [1211500800000, 181.17],
        [1211846400000, 186.43],
        [1211932800000, 187.01],
        [1212019200000, 186.69],
        [1212105600000, 188.75],
        [1212364800000, 186.1],
        [1212451200000, 185.37],
        [1212537600000, 185.19],
        [1212624000000, 189.43],
        [1212710400000, 185.64],
        [1212969600000, 181.61],
        [1213056000000, 185.64],
        [1213142400000, 180.81],
        [1213228800000, 173.26],
        [1213315200000, 172.37],
        [1213574400000, 176.84],
        [1213660800000, 181.43],
        [1213747200000, 178.75],
        [1213833600000, 180.9],
        [1213920000000, 175.27],
        [1214179200000, 173.16],
        [1214265600000, 173.25],
        [1214352000000, 177.39],
        [1214438400000, 168.26],
        [1214524800000, 170.09],
        [1214784000000, 167.44],
        [1214870400000, 174.68],
        [1214956800000, 168.18],
        [1215043200000, 170.12],
        [1215388800000, 175.16],
        [1215475200000, 179.55],
        [1215561600000, 174.25],
        [1215648000000, 176.63],
        [1215734400000, 172.58],
        [1215993600000, 173.88],
        [1216080000000, 169.64],
        [1216166400000, 172.81],
        [1216252800000, 171.81],
        [1216339200000, 165.15],
        [1216598400000, 166.29],
        [1216684800000, 162.02],
        [1216771200000, 166.26],
        [1216857600000, 159.03],
        [1216944000000, 162.12],
        [1217203200000, 154.4],
        [1217289600000, 157.08],
        [1217376000000, 159.88],
        [1217462400000, 158.95],
        [1217548800000, 156.66],
        [1217808000000, 153.23],
        [1217894400000, 160.64],
        [1217980800000, 164.19],
        [1218067200000, 163.57],
        [1218153600000, 169.55],
        [1218412800000, 173.56],
        [1218499200000, 176.73],
        [1218585600000, 179.3],
        [1218672000000, 179.32],
        [1218758400000, 175.74],
        [1219017600000, 175.39],
        [1219104000000, 173.53],
        [1219190400000, 175.84],
        [1219276800000, 174.29],
        [1219363200000, 176.79],
        [1219622400000, 172.55],
        [1219708800000, 173.64],
        [1219795200000, 174.67],
        [1219881600000, 173.74],
        [1219968000000, 169.53],
        [1220313600000, 166.19],
        [1220400000000, 166.96],
        [1220486400000, 161.22],
        [1220572800000, 160.18],
        [1220832000000, 157.92],
        [1220918400000, 151.68],
        [1221004800000, 151.61],
        [1221091200000, 152.65],
        [1221177600000, 148.94],
        [1221436800000, 140.36],
        [1221523200000, 139.88],
        [1221609600000, 127.83],
        [1221696000000, 134.09],
        [1221782400000, 140.91],
        [1222041600000, 131.05],
        [1222128000000, 126.84],
        [1222214400000, 128.71],
        [1222300800000, 131.93],
        [1222387200000, 128.24],
        [1222646400000, 105.26],
        [1222732800000, 113.66],
        [1222819200000, 109.12],
        [1222905600000, 100.1],
        [1222992000000, 97.07],
        [1223251200000, 98.14],
        [1223337600000, 89.16],
        [1223424000000, 89.79],
        [1223510400000, 88.74],
        [1223596800000, 96.8],
        [1223856000000, 110.26],
        [1223942400000, 104.08],
        [1224028800000, 97.95],
        [1224115200000, 101.89],
        [1224201600000, 97.4],
        [1224460800000, 98.44],
        [1224547200000, 91.49],
        [1224633600000, 96.87],
        [1224720000000, 98.23],
        [1224806400000, 96.38],
        [1225065600000, 92.09],
        [1225152000000, 99.91],
        [1225238400000, 104.55],
        [1225324800000, 111.04],
        [1225411200000, 107.59],
        [1225670400000, 106.96],
        [1225756800000, 110.99],
        [1225843200000, 103.3],
        [1225929600000, 99.1],
        [1226016000000, 98.24],
        [1226275200000, 95.88],
        [1226361600000, 94.77],
        [1226448000000, 90.12],
        [1226534400000, 96.44],
        [1226620800000, 90.24],
        [1226880000000, 88.14],
        [1226966400000, 89.91],
        [1227052800000, 86.29],
        [1227139200000, 80.49],
        [1227225600000, 82.58],
        [1227484800000, 92.95],
        [1227571200000, 90.8],
        [1227657600000, 95],
        [1227744000000, 95],
        [1227830400000, 92.67],
        [1228089600000, 88.93],
        [1228176000000, 92.47],
        [1228262400000, 95.9],
        [1228348800000, 91.41],
        [1228435200000, 94],
        [1228694400000, 99.72],
        [1228780800000, 100.06],
        [1228867200000, 98.21],
        [1228953600000, 95],
        [1229040000000, 98.27],
        [1229299200000, 94.75],
        [1229385600000, 95.43],
        [1229472000000, 89.16],
        [1229558400000, 89.43],
        [1229644800000, 90],
        [1229904000000, 85.74],
        [1229990400000, 86.38],
        [1230076800000, 85.04],
        [1230163200000, 85.04],
        [1230249600000, 85.81],
        [1230508800000, 86.61],
        [1230595200000, 86.29],
        [1230681600000, 85.35],
        [1230768000000, 85.35],
        [1230854400000, 90.75],
        [1231113600000, 94.58],
        [1231200000000, 93.02],
        [1231286400000, 91.01],
        [1231372800000, 92.7],
        [1231459200000, 90.58],
        [1231718400000, 88.66],
        [1231804800000, 87.71],
        [1231891200000, 85.33],
        [1231977600000, 83.38],
        [1232064000000, 82.33],
        [1232409600000, 78.2],
        [1232496000000, 82.83],
        [1232582400000, 88.36],
        [1232668800000, 88.36],
        [1232928000000, 89.64],
        [1233014400000, 90.73],
        [1233100800000, 94.2],
        [1233187200000, 93],
        [1233273600000, 90.13],
        [1233532800000, 91.51],
        [1233619200000, 92.98],
        [1233705600000, 93.55],
        [1233792000000, 96.46],
        [1233878400000, 99.72],
        [1234137600000, 102.51],
        [1234224000000, 97.83],
        [1234310400000, 96.82],
        [1234396800000, 99.27],
        [1234483200000, 99.16],
        [1234828800000, 94.53],
        [1234915200000, 94.37],
        [1235001600000, 90.64],
        [1235088000000, 91.2],
        [1235347200000, 86.95],
        [1235433600000, 90.25],
        [1235520000000, 91.16],
        [1235606400000, 89.19],
        [1235692800000, 89.31],
        [1235952000000, 87.94],
        [1236038400000, 88.37],
        [1236124800000, 91.17],
        [1236211200000, 88.84],
        [1236297600000, 85.3],
        [1236556800000, 83.11],
        [1236643200000, 88.63],
        [1236729600000, 92.68],
        [1236816000000, 96.35],
        [1236902400000, 95.93],
        [1237161600000, 95.42],
        [1237248000000, 99.66],
        [1237334400000, 101.52],
        [1237420800000, 101.62],
        [1237507200000, 101.59],
        [1237766400000, 107.66],
        [1237852800000, 106.5],
        [1237939200000, 106.49],
        [1238025600000, 109.87],
        [1238112000000, 106.85],
        [1238371200000, 104.49],
        [1238457600000, 105.12],
        [1238544000000, 108.69],
        [1238630400000, 112.71],
        [1238716800000, 115.99],
        [1238976000000, 118.45],
        [1239062400000, 115],
        [1239148800000, 116.32],
        [1239235200000, 119.57],
        [1239321600000, 119.57],
        [1239580800000, 120.22],
        [1239667200000, 118.31],
        [1239753600000, 117.64],
        [1239840000000, 121.45],
        [1239926400000, 123.42],
        [1240185600000, 120.5],
        [1240272000000, 121.76],
        [1240358400000, 121.51],
        [1240444800000, 125.4],
        [1240531200000, 123.9],
        [1240790400000, 124.73],
        [1240876800000, 123.9],
        [1240963200000, 125.14],
        [1241049600000, 125.83],
        [1241136000000, 127.24],
        [1241395200000, 132.07],
        [1241481600000, 132.71],
        [1241568000000, 132.5],
        [1241654400000, 129.06],
        [1241740800000, 129.19],
        [1242000000000, 129.57],
        [1242086400000, 124.42],
        [1242172800000, 119.49],
        [1242259200000, 122.95],
        [1242345600000, 122.42],
        [1242604800000, 126.65],
        [1242691200000, 127.45],
        [1242777600000, 125.87],
        [1242864000000, 124.18],
        [1242950400000, 122.5],
        [1243296000000, 130.78],
        [1243382400000, 133.05],
        [1243468800000, 135.07],
        [1243555200000, 135.81],
        [1243814400000, 139.35],
        [1243900800000, 139.49],
        [1243987200000, 140.95],
        [1244073600000, 143.74],
        [1244160000000, 144.67],
        [1244419200000, 143.85],
        [1244505600000, 142.72],
        [1244592000000, 140.25],
        [1244678400000, 139.95],
        [1244764800000, 136.97],
        [1245024000000, 136.09],
        [1245110400000, 136.35],
        [1245196800000, 135.58],
        [1245283200000, 135.88],
        [1245369600000, 139.48],
        [1245628800000, 137.37],
        [1245715200000, 134.01],
        [1245801600000, 136.22],
        [1245888000000, 139.86],
        [1245974400000, 142.44],
        [1246233600000, 141.97],
        [1246320000000, 142.43],
        [1246406400000, 142.83],
        [1246492800000, 140.02],
        [1246579200000, 140.02],
        [1246838400000, 138.61],
        [1246924800000, 135.4],
        [1247011200000, 137.22],
        [1247097600000, 136.36],
        [1247184000000, 138.52],
        [1247443200000, 142.34],
        [1247529600000, 142.27],
        [1247616000000, 146.88],
        [1247702400000, 147.52],
        [1247788800000, 151.75],
        [1248048000000, 152.91],
        [1248134400000, 151.51],
        [1248220800000, 156.74],
        [1248307200000, 157.82],
        [1248393600000, 159.99],
        [1248652800000, 160.1],
        [1248739200000, 160],
        [1248825600000, 160.03],
        [1248912000000, 162.79],
        [1248998400000, 163.39],
        [1249257600000, 166.43],
        [1249344000000, 165.55],
        [1249430400000, 165.11],
        [1249516800000, 163.91],
        [1249603200000, 165.51],
        [1249862400000, 164.72],
        [1249948800000, 162.83],
        [1250035200000, 165.31],
        [1250121600000, 168.42],
        [1250208000000, 166.78],
        [1250467200000, 159.59],
        [1250553600000, 164],
        [1250640000000, 164.6],
        [1250726400000, 166.33],
        [1250812800000, 169.22],
        [1251072000000, 169.06],
        [1251158400000, 169.4],
        [1251244800000, 167.41],
        [1251331200000, 169.45],
        [1251417600000, 170.05],
        [1251676800000, 168.21],
        [1251763200000, 165.3],
        [1251849600000, 165.18],
        [1251936000000, 166.55],
        [1252022400000, 170.31],
        [1252368000000, 172.93],
        [1252454400000, 171.14],
        [1252540800000, 172.56],
        [1252627200000, 172.16],
        [1252886400000, 173.72],
        [1252972800000, 175.16],
        [1253059200000, 181.87],
        [1253145600000, 184.55],
        [1253232000000, 185.02],
        [1253491200000, 184.02],
        [1253577600000, 184.48],
        [1253664000000, 185.5],
        [1253750400000, 183.82],
        [1253836800000, 182.37],
        [1254096000000, 186.15],
        [1254182400000, 185.38],
        [1254268800000, 185.35],
        [1254355200000, 180.86],
        [1254441600000, 184.9],
        [1254700800000, 186.02],
        [1254787200000, 190.01],
        [1254873600000, 190.25],
        [1254960000000, 189.27],
        [1255046400000, 190.47],
        [1255305600000, 190.81],
        [1255392000000, 190.02],
        [1255478400000, 191.29],
        [1255564800000, 190.56],
        [1255651200000, 188.05],
        [1255910400000, 189.86],
        [1255996800000, 198.76],
        [1256083200000, 204.92],
        [1256169600000, 205.2],
        [1256256000000, 203.94],
        [1256515200000, 202.48],
        [1256601600000, 197.37],
        [1256688000000, 192.4],
        [1256774400000, 196.35],
        [1256860800000, 188.5],
        [1257120000000, 189.31],
        [1257206400000, 188.75],
        [1257292800000, 190.81],
        [1257379200000, 194.03],
        [1257465600000, 194.34],
        [1257724800000, 201.46],
        [1257811200000, 202.98],
        [1257897600000, 203.25],
        [1257984000000, 201.99],
        [1258070400000, 204.45],
        [1258329600000, 206.63],
        [1258416000000, 207],
        [1258502400000, 205.96],
        [1258588800000, 200.51],
        [1258675200000, 199.92],
        [1258934400000, 205.88],
        [1259020800000, 204.44],
        [1259107200000, 204.19],
        [1259193600000, 204.19],
        [1259280000000, 200.59],
        [1259539200000, 199.91],
        [1259625600000, 196.97],
        [1259712000000, 196.23],
        [1259798400000, 196.48],
        [1259884800000, 193.32],
        [1260144000000, 188.95],
        [1260230400000, 189.87],
        [1260316800000, 197.8],
        [1260403200000, 196.43],
        [1260489600000, 194.67],
        [1260748800000, 196.98],
        [1260835200000, 194.17],
        [1260921600000, 195.03],
        [1261008000000, 191.86],
        [1261094400000, 195.43],
        [1261353600000, 198.23],
        [1261440000000, 200.36],
        [1261526400000, 202.1],
        [1261612800000, 209.04],
        [1261699200000, 209.04],
        [1261958400000, 211.61],
        [1262044800000, 209.1],
        [1262131200000, 211.64],
        [1262217600000, 210.73],
        [1262304000000, 210.73],
        [1262563200000, 214.01],
        [1262649600000, 214.38],
        [1262736000000, 210.97],
        [1262822400000, 210.58],
        [1262908800000, 211.98],
        [1263168000000, 210.11],
        [1263254400000, 207.72],
        [1263340800000, 210.65],
        [1263427200000, 209.43],
        [1263513600000, 205.93],
        [1263772800000, 205.93],
        [1263859200000, 215.04],
        [1263945600000, 211.72],
        [1264032000000, 208.07],
        [1264118400000, 197.75],
        [1264377600000, 203.08],
        [1264464000000, 205.94],
        [1264550400000, 207.88],
        [1264636800000, 199.29],
        [1264723200000, 192.06],
        [1264982400000, 194.73],
        [1265068800000, 195.86],
        [1265155200000, 199.23],
        [1265241600000, 192.05],
        [1265328000000, 195.46],
        [1265587200000, 194.12],
        [1265673600000, 196.19],
        [1265760000000, 195.12],
        [1265846400000, 198.67],
        [1265932800000, 200.38],
        [1266192000000, 200.38],
        [1266278400000, 203.4],
        [1266364800000, 202.55],
        [1266451200000, 202.93],
        [1266537600000, 201.67],
        [1266796800000, 200.42],
        [1266883200000, 197.06],
        [1266969600000, 200.66],
        [1267056000000, 202],
        [1267142400000, 204.62],
        [1267401600000, 208.99],
        [1267488000000, 208.85],
        [1267574400000, 209.33],
        [1267660800000, 210.71],
        [1267747200000, 218.95],
        [1268006400000, 219.08],
        [1268092800000, 223.02],
        [1268179200000, 224.84],
        [1268265600000, 225.5],
        [1268352000000, 226.6],
        [1268611200000, 223.84],
        [1268697600000, 224.45],
        [1268784000000, 224.12],
        [1268870400000, 224.65],
        [1268956800000, 222.25],
        [1269216000000, 224.75],
        [1269302400000, 228.36],
        [1269388800000, 229.37],
        [1269475200000, 226.65],
        [1269561600000, 230.9],
        [1269820800000, 232.39],
        [1269907200000, 235.84],
        [1269993600000, 235],
        [1270080000000, 235.97],
        [1270166400000, 235.97],
        [1270425600000, 238.49],
        [1270512000000, 239.54],
        [1270598400000, 240.6],
        [1270684800000, 239.95],
        [1270771200000, 241.79],
        [1271030400000, 242.29],
        [1271116800000, 242.43],
        [1271203200000, 245.69],
        [1271289600000, 248.92],
        [1271376000000, 247.4],
        [1271635200000, 247.07],
        [1271721600000, 244.59],
        [1271808000000, 259.22],
        [1271894400000, 266.47],
        [1271980800000, 270.83],
        [1272240000000, 269.5],
        [1272326400000, 262.04],
        [1272412800000, 261.6],
        [1272499200000, 268.64],
        [1272585600000, 261.09],
        [1272844800000, 266.35],
        [1272931200000, 258.68],
        [1273017600000, 255.98],
        [1273104000000, 246.25],
        [1273190400000, 235.86],
        [1273449600000, 253.99],
        [1273536000000, 256.52],
        [1273622400000, 262.09],
        [1273708800000, 258.36],
        [1273795200000, 253.82],
        [1274054400000, 254.22],
        [1274140800000, 252.36],
        [1274227200000, 248.34],
        [1274313600000, 237.76],
        [1274400000000, 242.32],
        [1274659200000, 246.76],
        [1274745600000, 245.22],
        [1274832000000, 244.11],
        [1274918400000, 253.35],
        [1275004800000, 256.88],
        [1275264000000, 256.88],
        [1275350400000, 260.83],
        [1275436800000, 263.95],
        [1275523200000, 263.12],
        [1275609600000, 255.96],
        [1275868800000, 250.94],
        [1275955200000, 249.33],
        [1276041600000, 243.2],
        [1276128000000, 250.51],
        [1276214400000, 253.51],
        [1276473600000, 254.28],
        [1276560000000, 259.69],
        [1276646400000, 267.25],
        [1276732800000, 271.87],
        [1276819200000, 274.07],
        [1277078400000, 270.17],
        [1277164800000, 273.85],
        [1277251200000, 270.97],
        [1277337600000, 269],
        [1277424000000, 266.7],
        [1277683200000, 268.3],
        [1277769600000, 256.17],
        [1277856000000, 251.53],
        [1277942400000, 248.48],
        [1278028800000, 246.94],
        [1278288000000, 246.94],
        [1278374400000, 248.63],
        [1278460800000, 258.66],
        [1278547200000, 258.09],
        [1278633600000, 259.62],
        [1278892800000, 257.28],
        [1278979200000, 251.8],
        [1279065600000, 252.73],
        [1279152000000, 251.45],
        [1279238400000, 249.9],
        [1279497600000, 245.58],
        [1279584000000, 251.89],
        [1279670400000, 254.24],
        [1279756800000, 259.02],
        [1279843200000, 259.94],
        [1280102400000, 259.28],
        [1280188800000, 264.08],
        [1280275200000, 260.96],
        [1280361600000, 258.11],
        [1280448000000, 257.25],
        [1280707200000, 261.85],
        [1280793600000, 261.93],
        [1280880000000, 262.98],
        [1280966400000, 261.7],
        [1281052800000, 260.09],
        [1281312000000, 261.75],
        [1281398400000, 259.41],
        [1281484800000, 250.19],
        [1281571200000, 251.79],
        [1281657600000, 249.1],
        [1281916800000, 247.64],
        [1282003200000, 251.97],
        [1282089600000, 253.07],
        [1282176000000, 249.88],
        [1282262400000, 249.64],
        [1282521600000, 245.8],
        [1282608000000, 239.93],
        [1282694400000, 242.89],
        [1282780800000, 240.28],
        [1282867200000, 241.62],
        [1283126400000, 242.5],
        [1283212800000, 243.1],
        [1283299200000, 250.33],
        [1283385600000, 252.17],
        [1283472000000, 258.77],
        [1283731200000, 258.77],
        [1283817600000, 257.81],
        [1283904000000, 262.92],
        [1283990400000, 263.07],
        [1284076800000, 263.41],
        [1284336000000, 267.04],
        [1284422400000, 268.06],
        [1284508800000, 270.22],
        [1284595200000, 276.57],
        [1284681600000, 275.37],
        [1284940800000, 283.23],
        [1285027200000, 283.77],
        [1285113600000, 287.75],
        [1285200000000, 288.92],
        [1285286400000, 292.32],
        [1285545600000, 291.16],
        [1285632000000, 286.86],
        [1285718400000, 287.37],
        [1285804800000, 283.75],
        [1285891200000, 282.52],
        [1286150400000, 278.64],
        [1286236800000, 288.94],
        [1286323200000, 289.19],
        [1286409600000, 289.22],
        [1286496000000, 294.07],
        [1286755200000, 295.36],
        [1286841600000, 298.54],
        [1286928000000, 300.14],
        [1287014400000, 302.31],
        [1287100800000, 314.74],
        [1287360000000, 318],
        [1287446400000, 309.49],
        [1287532800000, 310.53],
        [1287619200000, 309.52],
        [1287705600000, 307.47],
        [1287964800000, 308.84],
        [1288051200000, 308.05],
        [1288137600000, 307.83],
        [1288224000000, 305.24],
        [1288310400000, 300.98],
        [1288569600000, 304.18],
        [1288656000000, 309.36],
        [1288742400000, 312.8],
        [1288828800000, 318.27],
        [1288915200000, 317.13],
        [1289174400000, 318.62],
        [1289260800000, 316.08],
        [1289347200000, 318.03],
        [1289433600000, 316.66],
        [1289520000000, 308.03],
        [1289779200000, 307.04],
        [1289865600000, 301.59],
        [1289952000000, 300.5],
        [1290038400000, 308.43],
        [1290124800000, 306.73],
        [1290384000000, 313.36],
        [1290470400000, 308.73],
        [1290556800000, 314.8],
        [1290729600000, 315],
        [1290988800000, 316.87],
        [1291075200000, 311.15],
        [1291161600000, 316.4],
        [1291248000000, 318.15],
        [1291334400000, 317.44],
        [1291593600000, 320.15],
        [1291680000000, 318.21],
        [1291766400000, 321.01],
        [1291852800000, 319.76],
        [1291939200000, 320.56],
        [1292198400000, 321.67],
        [1292284800000, 320.29],
        [1292371200000, 320.36],
        [1292457600000, 321.25],
        [1292544000000, 320.61],
        [1292803200000, 322.21],
        [1292889600000, 324.2],
        [1292976000000, 325.16],
        [1293062400000, 323.6],
        [1293408000000, 324.68],
        [1293494400000, 325.47],
        [1293580800000, 325.29],
        [1293667200000, 323.66],
        [1293753600000, 322.56],
        [1294012800000, 329.57],
        [1294099200000, 331.29],
        [1294185600000, 334],
        [1294272000000, 333.73],
        [1294358400000, 336.12],
        [1294617600000, 342.46],
        [1294704000000, 341.64],
        [1294790400000, 344.42],
        [1294876800000, 345.68],
        [1294963200000, 348.48],
        [1295308800000, 340.65],
        [1295395200000, 338.84],
        [1295481600000, 332.68],
        [1295568000000, 326.72],
        [1295827200000, 337.45],
        [1295913600000, 341.4],
        [1296000000000, 343.85],
        [1296086400000, 343.21],
        [1296172800000, 336.1],
        [1296432000000, 339.32],
        [1296518400000, 345.03],
        [1296604800000, 344.32],
        [1296691200000, 343.44],
        [1296777600000, 346.5],
        [1297036800000, 351.88],
        [1297123200000, 355.2],
        [1297209600000, 358.16],
        [1297296000000, 354.54],
        [1297382400000, 356.85],
        [1297641600000, 359.18],
        [1297728000000, 359.9],
        [1297814400000, 363.13],
        [1297900800000, 358.3],
        [1297987200000, 350.56],
        [1298332800000, 338.61],
        [1298419200000, 342.62],
        [1298505600000, 342.88],
        [1298592000000, 348.16],
        [1298851200000, 353.21],
        [1298937600000, 349.31],
        [1299024000000, 352.12],
        [1299110400000, 359.56],
        [1299196800000, 360],
        [1299456000000, 355.36],
        [1299542400000, 355.76],
        [1299628800000, 352.47],
        [1299715200000, 346.67],
        [1299801600000, 351.99],
        [1300060800000, 353.56],
        [1300147200000, 345.43],
        [1300233600000, 330.01],
        [1300320000000, 334.64],
        [1300406400000, 330.67],
        [1300665600000, 339.3],
        [1300752000000, 341.2],
        [1300838400000, 339.19],
        [1300924800000, 344.97],
        [1301011200000, 351.54],
        [1301270400000, 350.44],
        [1301356800000, 350.96],
        [1301443200000, 348.63],
        [1301529600000, 348.51],
        [1301616000000, 344.56],
        [1301875200000, 341.19],
        [1301961600000, 338.89],
        [1302048000000, 338.04],
        [1302134400000, 338.08],
        [1302220800000, 335.06],
        [1302480000000, 330.8],
        [1302566400000, 332.4],
        [1302652800000, 336.13],
        [1302739200000, 332.42],
        [1302825600000, 327.46],
        [1303084800000, 331.85],
        [1303171200000, 337.86],
        [1303257600000, 342.41],
        [1303344000000, 350.7],
        [1303689600000, 353.01],
        [1303776000000, 350.42],
        [1303862400000, 350.15],
        [1303948800000, 346.75],
        [1304035200000, 350.13],
        [1304294400000, 346.28],
        [1304380800000, 348.2],
        [1304467200000, 349.57],
        [1304553600000, 346.75],
        [1304640000000, 346.66],
        [1304899200000, 347.6],
        [1304985600000, 349.45],
        [1305072000000, 347.23],
        [1305158400000, 346.57],
        [1305244800000, 340.5],
        [1305504000000, 333.3],
        [1305590400000, 336.14],
        [1305676800000, 339.87],
        [1305763200000, 340.53],
        [1305849600000, 335.22],
        [1306108800000, 334.4],
        [1306195200000, 332.19],
        [1306281600000, 336.78],
        [1306368000000, 335],
        [1306454400000, 337.41],
        [1306800000000, 347.83],
        [1306886400000, 345.51],
        [1306972800000, 346.1],
        [1307059200000, 343.44],
        [1307318400000, 338.04],
        [1307404800000, 332.04],
        [1307491200000, 332.24],
        [1307577600000, 331.49],
        [1307664000000, 325.9],
        [1307923200000, 326.6],
        [1308009600000, 332.44],
        [1308096000000, 326.75],
        [1308182400000, 325.16],
        [1308268800000, 320.26],
        [1308528000000, 315.32],
        [1308614400000, 325.3],
        [1308700800000, 322.61],
        [1308787200000, 331.23],
        [1308873600000, 326.35],
        [1309132800000, 332.04],
        [1309219200000, 335.26],
        [1309305600000, 334.04],
        [1309392000000, 335.67],
        [1309478400000, 343.26],
        [1309824000000, 349.43],
        [1309910400000, 351.76],
        [1309996800000, 357.2],
        [1310083200000, 359.71],
        [1310342400000, 354],
        [1310428800000, 353.75],
        [1310515200000, 358.02],
        [1310601600000, 357.77],
        [1310688000000, 364.92],
        [1310947200000, 373.8],
        [1311033600000, 376.85],
        [1311120000000, 386.9],
        [1311206400000, 387.29],
        [1311292800000, 393.3],
        [1311552000000, 398.5],
        [1311638400000, 403.41],
        [1311724800000, 392.59],
        [1311811200000, 391.82],
        [1311897600000, 390.48],
        [1312156800000, 396.75],
        [1312243200000, 388.21],
        [1312329600000, 392.57],
        [1312416000000, 377.37],
        [1312502400000, 373.62],
        [1312761600000, 353.21],
        [1312848000000, 374.01],
        [1312934400000, 363.69],
        [1313020800000, 373.7],
        [1313107200000, 376.99],
        [1313366400000, 383.41],
        [1313452800000, 380.48],
        [1313539200000, 380.44],
        [1313625600000, 366.05],
        [1313712000000, 356.03],
        [1313971200000, 356.44],
        [1314057600000, 373.6],
        [1314144000000, 376.18],
        [1314230400000, 373.72],
        [1314316800000, 383.58],
        [1314576000000, 389.97],
        [1314662400000, 389.99],
        [1314748800000, 384.83],
        [1314835200000, 381.03],
        [1314921600000, 374.05],
        [1315267200000, 379.74],
        [1315353600000, 383.93],
        [1315440000000, 384.14],
        [1315526400000, 377.48],
        [1315785600000, 379.94],
        [1315872000000, 384.62],
        [1315958400000, 389.3],
        [1316044800000, 392.96],
        [1316131200000, 400.5],
        [1316390400000, 411.63],
        [1316476800000, 413.45],
        [1316563200000, 412.14],
        [1316649600000, 401.82],
        [1316736000000, 404.3],
        [1316995200000, 403.17],
        [1317081600000, 399.26],
        [1317168000000, 397.01],
        [1317254400000, 390.57],
        [1317340800000, 381.32],
        [1317600000000, 374.6],
        [1317686400000, 372.5],
        [1317772800000, 378.25],
        [1317859200000, 377.37],
        [1317945600000, 369.8],
        [1318204800000, 388.81],
        [1318291200000, 400.29],
        [1318377600000, 402.19],
        [1318464000000, 408.43],
        [1318550400000, 422],
        [1318809600000, 419.99],
        [1318896000000, 422.24],
        [1318982400000, 398.62],
        [1319068800000, 395.31],
        [1319155200000, 392.87],
        [1319414400000, 405.77],
        [1319500800000, 397.77],
        [1319587200000, 400.6],
        [1319673600000, 404.69],
        [1319760000000, 404.95],
        [1320019200000, 404.78],
        [1320105600000, 396.51],
        [1320192000000, 397.41],
        [1320278400000, 403.07],
        [1320364800000, 400.24],
        [1320624000000, 399.73],
        [1320710400000, 406.23],
        [1320796800000, 395.28],
        [1320883200000, 385.22],
        [1320969600000, 384.62],
        [1321228800000, 379.26],
        [1321315200000, 388.83],
        [1321401600000, 384.77],
        [1321488000000, 377.41],
        [1321574400000, 374.94],
        [1321833600000, 369.01],
        [1321920000000, 376.51],
        [1322006400000, 366.99],
        [1322179200000, 363.57],
        [1322438400000, 376.12],
        [1322524800000, 373.2],
        [1322611200000, 382.2],
        [1322697600000, 387.93],
        [1322784000000, 389.7],
        [1323043200000, 393.01],
        [1323129600000, 390.95],
        [1323216000000, 389.09],
        [1323302400000, 390.66],
        [1323388800000, 393.62],
        [1323648000000, 391.84],
        [1323734400000, 388.81],
        [1323820800000, 380.19],
        [1323907200000, 378.94],
        [1323993600000, 381.02],
        [1324252800000, 382.21],
        [1324339200000, 395.95],
        [1324425600000, 396.44],
        [1324512000000, 398.55],
        [1324598400000, 403.33],
        [1324944000000, 406.53],
        [1325030400000, 402.64],
        [1325116800000, 405.12],
        [1325203200000, 405],
        [1325548800000, 411.23],
        [1325635200000, 413.44],
        [1325721600000, 418.03],
        [1325808000000, 422.4],
        [1326067200000, 421.73],
        [1326153600000, 423.24],
        [1326240000000, 422.55],
        [1326326400000, 421.39],
        [1326412800000, 419.81],
        [1326758400000, 424.7],
        [1326844800000, 429.11],
        [1326931200000, 427.75],
        [1327017600000, 420.3],
        [1327276800000, 427.41],
        [1327363200000, 420.41],
        [1327449600000, 446.66],
        [1327536000000, 444.63],
        [1327622400000, 447.28],
        [1327881600000, 453.01],
        [1327968000000, 456.48],
        [1328054400000, 456.19],
        [1328140800000, 455.12],
        [1328227200000, 459.68],
        [1328486400000, 463.97],
        [1328572800000, 468.83],
        [1328659200000, 476.68],
        [1328745600000, 493.17],
        [1328832000000, 493.42],
        [1329091200000, 502.6],
        [1329177600000, 509.46],
        [1329264000000, 497.67],
        [1329350400000, 502.21],
        [1329436800000, 502.12],
        [1329782400000, 514.85],
        [1329868800000, 513.04],
        [1329955200000, 516.39],
        [1330041600000, 522.41],
        [1330300800000, 525.76],
        [1330387200000, 535.41],
        [1330473600000, 542.44],
        [1330560000000, 544.47],
        [1330646400000, 545.18],
        [1330905600000, 533.16],
        [1330992000000, 530.26],
        [1331078400000, 530.69],
        [1331164800000, 541.99],
        [1331251200000, 545.17],
        [1331510400000, 552],
        [1331596800000, 568.1],
        [1331683200000, 589.58],
        [1331769600000, 585.56],
        [1331856000000, 585.57],
        [1332115200000, 601.1],
        [1332201600000, 605.96],
        [1332288000000, 602.5],
        [1332374400000, 599.34],
        [1332460800000, 596.05],
        [1332720000000, 606.98],
        [1332806400000, 614.48],
        [1332892800000, 617.62],
        [1332979200000, 609.86],
        [1333065600000, 599.55],
        [1333324800000, 618.63],
        [1333411200000, 629.32],
        [1333497600000, 624.31],
        [1333584000000, 633.68],
        [1333929600000, 636.23],
        [1334016000000, 628.44],
        [1334102400000, 626.2],
        [1334188800000, 622.77],
        [1334275200000, 605.23],
        [1334534400000, 580.13],
        [1334620800000, 609.7],
        [1334707200000, 608.34],
        [1334793600000, 587.44],
        [1334880000000, 572.98],
        [1335139200000, 571.7],
        [1335225600000, 560.28],
        [1335312000000, 610],
        [1335398400000, 607.7],
        [1335484800000, 603],
        [1335744000000, 583.98],
        [1335830400000, 582.13],
        [1335916800000, 585.98],
        [1336003200000, 581.82],
        [1336089600000, 565.25],
        [1336348800000, 569.48],
        [1336435200000, 568.18],
        [1336521600000, 569.18],
        [1336608000000, 570.52],
        [1336694400000, 566.71],
        [1336953600000, 558.22],
        [1337040000000, 553.17],
        [1337126400000, 546.08],
        [1337212800000, 530.12],
        [1337299200000, 530.38],
        [1337558400000, 561.28],
        [1337644800000, 556.97],
        [1337731200000, 570.56],
        [1337817600000, 565.32],
        [1337904000000, 562.29],
        [1338249600000, 572.27],
        [1338336000000, 579.17],
        [1338422400000, 577.73],
        [1338508800000, 560.99],
        [1338768000000, 564.29],
        [1338854400000, 562.83],
        [1338940800000, 571.46],
        [1339027200000, 571.72],
        [1339113600000, 580.32],
        [1339372800000, 571.17],
        [1339459200000, 576.16],
        [1339545600000, 572.16],
        [1339632000000, 571.53],
        [1339718400000, 574.13],
        [1339977600000, 585.78],
        [1340064000000, 587.41],
        [1340150400000, 585.74],
        [1340236800000, 577.67],
        [1340323200000, 582.1],
        [1340582400000, 570.76],
        [1340668800000, 572.02],
        [1340755200000, 574.5],
        [1340841600000, 569.05],
        [1340928000000, 584],
        [1341187200000, 592.52],
        [1341273600000, 599.41],
        [1341446400000, 609.94],
        [1341532800000, 605.88],
        [1341792000000, 613.89],
        [1341878400000, 608.21],
        [1341964800000, 604.43],
        [1342051200000, 598.9],
        [1342137600000, 604.97],
        [1342396800000, 606.91],
        [1342483200000, 606.94],
        [1342569600000, 606.26],
        [1342656000000, 614.32],
        [1342742400000, 604.3],
        [1343001600000, 603.83],
        [1343088000000, 600.92],
        [1343174400000, 574.97],
        [1343260800000, 574.88],
        [1343347200000, 585.16],
        [1343606400000, 595.03],
        [1343692800000, 610.76],
        [1343779200000, 606.81],
        [1343865600000, 607.79],
        [1343952000000, 615.7],
        [1344211200000, 622.55],
        [1344297600000, 620.91],
        [1344384000000, 619.86],
        [1344470400000, 620.73],
        [1344556800000, 621.7],
        [1344816000000, 630],
        [1344902400000, 631.69],
        [1344988800000, 630.83],
        [1345075200000, 636.34],
        [1345161600000, 648.11],
        [1345420800000, 665.15],
        [1345507200000, 656.06],
        [1345593600000, 668.87],
        [1345680000000, 662.63],
        [1345766400000, 663.22],
        [1346025600000, 675.68],
        [1346112000000, 674.8],
        [1346198400000, 673.47],
        [1346284800000, 663.87],
        [1346371200000, 665.24],
        [1346716800000, 674.97],
        [1346803200000, 670.23],
        [1346889600000, 676.27],
        [1346976000000, 680.44],
        [1347235200000, 662.74],
        [1347321600000, 660.59],
        [1347408000000, 669.79],
        [1347494400000, 682.98],
        [1347580800000, 691.28],
        [1347840000000, 699.78],
        [1347926400000, 701.91],
        [1348012800000, 702.1],
        [1348099200000, 698.7],
        [1348185600000, 700.1],
        [1348444800000, 690.79],
        [1348531200000, 673.54],
        [1348617600000, 665.18],
        [1348704000000, 681.32],
        [1348790400000, 667.1],
        [1349049600000, 659.39],
        [1349136000000, 661.31],
        [1349222400000, 671.45],
        [1349308800000, 666.8],
        [1349395200000, 652.59],
        [1349654400000, 638.17],
        [1349740800000, 635.85],
        [1349827200000, 640.91],
        [1349913600000, 628.1],
        [1350000000000, 629.71],
        [1350259200000, 634.76],
        [1350345600000, 649.79],
        [1350432000000, 644.61],
        [1350518400000, 632.64],
        [1350604800000, 609.84],
        [1350864000000, 634.03],
        [1350950400000, 613.36],
        [1351036800000, 616.83],
        [1351123200000, 609.54],
        [1351209600000, 604],
        [1351641600000, 595.32],
        [1351728000000, 596.54],
        [1351814400000, 576.8],
        [1352073600000, 584.62],
        [1352160000000, 582.85],
        [1352246400000, 558],
        [1352332800000, 537.75],
        [1352419200000, 547.06],
        [1352678400000, 542.83],
        [1352764800000, 542.9],
        [1352851200000, 536.88],
        [1352937600000, 525.62],
        [1353024000000, 527.68],
        [1353283200000, 565.73],
        [1353369600000, 560.91],
        [1353456000000, 561.7],
        [1353628800000, 571.5],
        [1353888000000, 589.53],
        [1353974400000, 584.78],
        [1354060800000, 582.94],
        [1354147200000, 589.36],
        [1354233600000, 585.28],
        [1354492800000, 586.19],
        [1354579200000, 575.85],
        [1354665600000, 538.79],
        [1354752000000, 547.24],
        [1354838400000, 533.25],
        [1355097600000, 529.82],
        [1355184000000, 541.39],
        [1355270400000, 539],
        [1355356800000, 529.69],
        [1355443200000, 509.79],
        [1355702400000, 518.83],
        [1355788800000, 533.9],
        [1355875200000, 526.31],
        [1355961600000, 521.73],
        [1356048000000, 519.33],
        [1356307200000, 520.17],
        [1356480000000, 513],
        [1356566400000, 515.06],
        [1356652800000, 509.59],
        [1356912000000, 532.17],
        [1357084800000, 549.03],
        [1357171200000, 542.1],
        [1357257600000, 527],
        [1357516800000, 523.9],
        [1357603200000, 525.31],
        [1357689600000, 517.1],
        [1357776000000, 523.51],
        [1357862400000, 520.3],
        [1358121600000, 501.75],
        [1358208000000, 485.92],
        [1358294400000, 506.09],
        [1358380800000, 502.68],
        [1358467200000, 500],
        [1358812800000, 504.77],
        [1358899200000, 514],
        [1358985600000, 450.5],
        [1359072000000, 439.88],
        [1359331200000, 449.83],
        [1359417600000, 458.27],
        [1359504000000, 456.83],
        [1359590400000, 455.49],
        [1359676800000, 453.62],
        [1359936000000, 442.32],
        [1360022400000, 457.84],
        [1360108800000, 457.35],
        [1360195200000, 468.22],
        [1360281600000, 474.98],
        [1360540800000, 479.93],
        [1360627200000, 467.9],
        [1360713600000, 467.01],
        [1360800000000, 466.59],
        [1360886400000, 460.16],
        [1361232000000, 459.99],
        [1361318400000, 448.85],
        [1361404800000, 446.06],
        [1361491200000, 450.81],
        [1361750400000, 442.8],
        [1361836800000, 448.97],
        [1361923200000, 444.57],
        [1362009600000, 441.4],
        [1362096000000, 430.47],
        [1362355200000, 420.05],
        [1362441600000, 431.14],
        [1362528000000, 425.66],
        [1362614400000, 430.58],
        [1362700800000, 431.72],
        [1362960000000, 437.87],
        [1363046400000, 428.43],
        [1363132800000, 428.35],
        [1363219200000, 432.5],
        [1363305600000, 443.66],
        [1363564800000, 455.72],
        [1363651200000, 454.49],
        [1363737600000, 452.08],
        [1363824000000, 452.73],
        [1363910400000, 461.91],
        [1364169600000, 463.58],
        [1364256000000, 461.14],
        [1364342400000, 452.08],
        [1364428800000, 442.66],
        [1364774400000, 428.91],
        [1364860800000, 429.79],
        [1364947200000, 431.99],
        [1365033600000, 427.72],
        [1365120000000, 423.2],
        [1365379200000, 426.21],
        [1365465600000, 426.98],
        [1365552000000, 435.69],
        [1365638400000, 434.33],
        [1365724800000, 429.8],
        [1365984000000, 419.85],
        [1366070400000, 426.24],
        [1366156800000, 402.8],
        [1366243200000, 392.05],
        [1366329600000, 390.53],
        [1366588800000, 398.67],
        [1366675200000, 406.13],
        [1366761600000, 405.46],
        [1366848000000, 408.38],
        [1366934400000, 417.2],
        [1367193600000, 430.12],
        [1367280000000, 442.78],
        [1367366400000, 439.29],
        [1367452800000, 445.52],
        [1367539200000, 449.98],
        [1367798400000, 460.71],
        [1367884800000, 458.66],
        [1367971200000, 463.84],
        [1368057600000, 456.77],
        [1368144000000, 452.97],
      ],
    },
    {
      name: "Demo Persson1",
      type: "spline",
      data: [
        [1147651200000, 376.2],
        [1147737600000, 371.3],
        [1147824000000, 374.5],
        [1147910400000, 370.99],
        [1147996800000, 370.02],
        [1148256000000, 370.95],
        [1148342400000, 375.58],
        [1148428800000, 381.25],
        [1148515200000, 382.99],
        [1148601600000, 381.35],
        [1148947200000, 371.94],
        [1149033600000, 371.82],
        [1149120000000, 382.62],
        [1149206400000, 379.44],
        [1149465600000, 374.44],
        [1149552000000, 389.99],
        [1149638400000, 386.51],
        [1149724800000, 393.3],
        [1149811200000, 386.57],
        [1150070400000, 381.54],
        [1150156800000, 386.52],
        [1150243200000, 384.39],
        [1150329600000, 391],
        [1150416000000, 390.7],
        [1150675200000, 388.14],
        [1150761600000, 387.17],
        [1150848000000, 402.13],
        [1150934400000, 399.95],
        [1151020800000, 404.86],
        [1151280000000, 404.22],
        [1151366400000, 402.32],
        [1151452800000, 406.11],
        [1151539200000, 417.81],
        [1151625600000, 419.33],
        [1151884800000, 423.2],
        [1152057600000, 421.46],
        [1152144000000, 423.19],
        [1152230400000, 420.45],
        [1152489600000, 418.2],
        [1152576000000, 424.56],
        [1152662400000, 417.25],
        [1152748800000, 408.83],
        [1152835200000, 403.5],
        [1153094400000, 407.89],
        [1153180800000, 403.05],
        [1153267200000, 399],
        [1153353600000, 387.12],
        [1153440000000, 390.11],
        [1153699200000, 390.9],
        [1153785600000, 389.36],
        [1153872000000, 385.5],
        [1153958400000, 382.4],
        [1154044800000, 388.12],
        [1154304000000, 386.6],
        [1154390400000, 375.51],
        [1154476800000, 367.23],
        [1154563200000, 375.39],
        [1154649600000, 373.85],
        [1154908800000, 377.95],
        [1154995200000, 381],
        [1155081600000, 376.94],
        [1155168000000, 374.2],
        [1155254400000, 368.5],
        [1155513600000, 369.43],
        [1155600000000, 380.97],
        [1155686400000, 387.72],
        [1155772800000, 385.8],
        [1155859200000, 383.36],
        [1156118400000, 377.3],
        [1156204800000, 378.29],
        [1156291200000, 373.43],
        [1156377600000, 373.73],
        [1156464000000, 373.26],
        [1156723200000, 380.95],
        [1156809600000, 378.95],
        [1156896000000, 380.75],
        [1156982400000, 378.53],
        [1157068800000, 378.6],
        [1157414400000, 384.36],
        [1157500800000, 380.14],
        [1157587200000, 378.49],
        [1157673600000, 377.85],
        [1157932800000, 384.09],
        [1158019200000, 391.9],
        [1158105600000, 406.57],
        [1158192000000, 403.98],
        [1158278400000, 409.88],
        [1158537600000, 414.69],
        [1158624000000, 403.81],
        [1158710400000, 397],
        [1158796800000, 406.85],
        [1158883200000, 403.78],
        [1159142400000, 403.98],
        [1159228800000, 406.87],
        [1159315200000, 402.92],
        [1159401600000, 403.58],
        [1159488000000, 401.9],
        [1159747200000, 401.44],
        [1159833600000, 404.04],
        [1159920000000, 415.7],
        [1160006400000, 411.81],
        [1160092800000, 420.5],
        [1160352000000, 429],
        [1160438400000, 426.65],
        [1160524800000, 426.5],
        [1160611200000, 427.44],
        [1160697600000, 427.3],
        [1160956800000, 421.75],
        [1161043200000, 420.64],
        [1161129600000, 419.31],
        [1161216000000, 426.06],
        [1161302400000, 459.67],
        [1161561600000, 480.78],
        [1161648000000, 473.31],
        [1161734400000, 486.6],
        [1161820800000, 485.1],
        [1161907200000, 475.2],
        [1162166400000, 476.57],
        [1162252800000, 476.39],
        [1162339200000, 467.5],
        [1162425600000, 469.91],
        [1162512000000, 471.8],
        [1162771200000, 476.95],
        [1162857600000, 472.57],
        [1162944000000, 475],
        [1163030400000, 472.63],
        [1163116800000, 473.55],
        [1163376000000, 481.03],
        [1163462400000, 489.3],
        [1163548800000, 491.93],
        [1163635200000, 495.9],
        [1163721600000, 498.79],
        [1163980800000, 495.05],
        [1164067200000, 509.65],
        [1164153600000, 508.01],
        [1164326400000, 505],
        [1164585600000, 484.75],
        [1164672000000, 489.5],
        [1164758400000, 484.65],
        [1164844800000, 484.81],
        [1164931200000, 480.8],
        [1165190400000, 484.85],
        [1165276800000, 487],
        [1165363200000, 488.71],
        [1165449600000, 482.64],
        [1165536000000, 484.11],
        [1165795200000, 483.93],
        [1165881600000, 481.78],
        [1165968000000, 478.99],
        [1166054400000, 482.12],
        [1166140800000, 480.3],
        [1166400000000, 462.8],
        [1166486400000, 468.63],
        [1166572800000, 462.9],
        [1166659200000, 456.2],
        [1166745600000, 455.58],
        [1167091200000, 457.53],
        [1167177600000, 468.03],
        [1167264000000, 462.56],
        [1167350400000, 460.48],
        [1167782400000, 467.59],
        [1167868800000, 483.26],
        [1167955200000, 487.19],
        [1168214400000, 483.58],
        [1168300800000, 485.5],
        [1168387200000, 489.46],
        [1168473600000, 499.72],
        [1168560000000, 505],
        [1168905600000, 504.28],
        [1168992000000, 497.28],
        [1169078400000, 487.83],
        [1169164800000, 489.75],
        [1169424000000, 480.84],
        [1169510400000, 479.05],
        [1169596800000, 499.07],
        [1169683200000, 488.09],
        [1169769600000, 495.84],
        [1170028800000, 492.47],
        [1170115200000, 494.32],
        [1170201600000, 501.5],
        [1170288000000, 481.75],
        [1170374400000, 481.5],
        [1170633600000, 467.16],
        [1170720000000, 471.48],
        [1170806400000, 470.01],
        [1170892800000, 471.03],
        [1170979200000, 461.89],
        [1171238400000, 458.29],
        [1171324800000, 459.1],
        [1171411200000, 465.93],
        [1171497600000, 461.47],
        [1171584000000, 469.94],
        [1171929600000, 472.1],
        [1172016000000, 475.86],
        [1172102400000, 475.85],
        [1172188800000, 470.62],
        [1172448000000, 464.93],
        [1172534400000, 448.77],
        [1172620800000, 449.45],
        [1172707200000, 448.23],
        [1172793600000, 438.68],
        [1173052800000, 440.94],
        [1173139200000, 457.55],
        [1173225600000, 455.64],
        [1173312000000, 454.72],
        [1173398400000, 452.96],
        [1173657600000, 454.75],
        [1173744000000, 443.03],
        [1173830400000, 448],
        [1173916800000, 446.19],
        [1174003200000, 440.85],
        [1174262400000, 447.23],
        [1174348800000, 445.28],
        [1174435200000, 456.55],
        [1174521600000, 462.04],
        [1174608000000, 461.83],
        [1174867200000, 465],
        [1174953600000, 463.62],
        [1175040000000, 461.88],
        [1175126400000, 460.92],
        [1175212800000, 458.16],
        [1175472000000, 458.53],
        [1175558400000, 472.6],
        [1175644800000, 471.02],
        [1175731200000, 471.51],
        [1176076800000, 468.21],
        [1176163200000, 466.5],
        [1176249600000, 464.53],
        [1176336000000, 467.39],
        [1176422400000, 466.29],
        [1176681600000, 474.27],
        [1176768000000, 472.8],
        [1176854400000, 476.01],
        [1176940800000, 471.65],
        [1177027200000, 482.48],
        [1177286400000, 479.08],
        [1177372800000, 477.53],
        [1177459200000, 477.99],
        [1177545600000, 481.18],
        [1177632000000, 479.01],
        [1177891200000, 471.38],
        [1177977600000, 469],
        [1178064000000, 465.78],
        [1178150400000, 473.23],
        [1178236800000, 471.12],
        [1178496000000, 467.27],
        [1178582400000, 466.81],
        [1178668800000, 469.25],
        [1178755200000, 461.47],
        [1178841600000, 466.74],
        [1179100800000, 461.78],
        [1179187200000, 458],
        [1179273600000, 472.61],
        [1179360000000, 470.96],
        [1179446400000, 470.32],
        [1179705600000, 470.6],
        [1179792000000, 475.86],
        [1179878400000, 473.97],
        [1179964800000, 474.33],
        [1180051200000, 483.52],
        [1180396800000, 487.11],
        [1180483200000, 498.6],
        [1180569600000, 497.91],
        [1180656000000, 500.4],
        [1180915200000, 507.07],
        [1181001600000, 518.84],
        [1181088000000, 518.25],
        [1181174400000, 515.06],
        [1181260800000, 515.49],
        [1181520000000, 511.34],
        [1181606400000, 504.77],
        [1181692800000, 505.24],
        [1181779200000, 502.84],
        [1181865600000, 505.89],
        [1182124800000, 515.2],
        [1182211200000, 514.31],
        [1182297600000, 509.97],
        [1182384000000, 514.11],
        [1182470400000, 524.98],
        [1182729600000, 527.42],
        [1182816000000, 530.26],
        [1182902400000, 526.29],
        [1182988800000, 525.01],
        [1183075200000, 522.7],
        [1183334400000, 530.38],
        [1183420800000, 534.34],
        [1183593600000, 541.63],
        [1183680000000, 539.4],
        [1183939200000, 542.56],
        [1184025600000, 543.34],
        [1184112000000, 544.47],
        [1184198400000, 545.33],
        [1184284800000, 552.16],
        [1184544000000, 552.99],
        [1184630400000, 555],
        [1184716800000, 549.5],
        [1184803200000, 548.59],
        [1184889600000, 520.12],
        [1185148800000, 512.51],
        [1185235200000, 514],
        [1185321600000, 509.76],
        [1185408000000, 508],
        [1185494400000, 511.89],
        [1185753600000, 516.11],
        [1185840000000, 510],
        [1185926400000, 512.94],
        [1186012800000, 511.01],
        [1186099200000, 503],
        [1186358400000, 510],
        [1186444800000, 516.02],
        [1186531200000, 525.78],
        [1186617600000, 514.73],
        [1186704000000, 515.75],
        [1186963200000, 515.5],
        [1187049600000, 508.6],
        [1187136000000, 497.55],
        [1187222400000, 491.52],
        [1187308800000, 500.04],
        [1187568000000, 497.92],
        [1187654400000, 506.61],
        [1187740800000, 512.75],
        [1187827200000, 512.19],
        [1187913600000, 515],
        [1188172800000, 513.26],
        [1188259200000, 506.4],
        [1188345600000, 512.88],
        [1188432000000, 511.4],
        [1188518400000, 515.25],
        [1188864000000, 525.15],
        [1188950400000, 527.8],
        [1189036800000, 523.52],
        [1189123200000, 519.35],
        [1189382400000, 514.48],
        [1189468800000, 521.33],
        [1189555200000, 522.65],
        [1189641600000, 524.78],
        [1189728000000, 528.75],
        [1189987200000, 525.3],
        [1190073600000, 535.27],
        [1190160000000, 546.85],
        [1190246400000, 552.83],
        [1190332800000, 560.1],
        [1190592000000, 568.02],
        [1190678400000, 569],
        [1190764800000, 568.16],
        [1190851200000, 567.5],
        [1190937600000, 567.27],
        [1191196800000, 582.55],
        [1191283200000, 584.39],
        [1191369600000, 584.02],
        [1191456000000, 579.03],
        [1191542400000, 594.05],
        [1191801600000, 609.62],
        [1191888000000, 615.18],
        [1191974400000, 625.39],
        [1192060800000, 622],
        [1192147200000, 637.39],
        [1192406400000, 620.11],
        [1192492800000, 616],
        [1192579200000, 633.48],
        [1192665600000, 639.62],
        [1192752000000, 644.71],
        [1193011200000, 650.75],
        [1193097600000, 675.77],
        [1193184000000, 675.82],
        [1193270400000, 668.51],
        [1193356800000, 674.6],
        [1193616000000, 679.23],
        [1193702400000, 694.77],
        [1193788800000, 707],
        [1193875200000, 703.21],
        [1193961600000, 711.25],
        [1194220800000, 725.65],
        [1194307200000, 741.79],
        [1194393600000, 732.94],
        [1194480000000, 693.84],
        [1194566400000, 663.97],
        [1194825600000, 632.07],
        [1194912000000, 660.55],
        [1194998400000, 641.68],
        [1195084800000, 629.65],
        [1195171200000, 633.63],
        [1195430400000, 625.85],
        [1195516800000, 648.54],
        [1195603200000, 660.52],
        [1195776000000, 676.7],
        [1196035200000, 666],
        [1196121600000, 673.57],
        [1196208000000, 692.26],
        [1196294400000, 697],
        [1196380800000, 693],
        [1196640000000, 681.53],
        [1196726400000, 684.16],
        [1196812800000, 698.51],
        [1196899200000, 715.26],
        [1196985600000, 714.87],
        [1197244800000, 718.42],
        [1197331200000, 699.2],
        [1197417600000, 699.35],
        [1197504000000, 694.05],
        [1197590400000, 689.96],
        [1197849600000, 669.23],
        [1197936000000, 673.35],
        [1198022400000, 677.37],
        [1198108800000, 689.69],
        [1198195200000, 696.69],
        [1198454400000, 700.73],
        [1198627200000, 710.84],
        [1198713600000, 700.74],
        [1198800000000, 702.53],
        [1199059200000, 691.48],
        [1199232000000, 685.19],
        [1199318400000, 685.33],
        [1199404800000, 657],
        [1199664000000, 649.25],
        [1199750400000, 631.68],
        [1199836800000, 653.2],
        [1199923200000, 646.73],
        [1200009600000, 638.25],
        [1200268800000, 653.82],
        [1200355200000, 637.65],
        [1200441600000, 615.95],
        [1200528000000, 600.79],
        [1200614400000, 600.25],
        [1200960000000, 584.35],
        [1201046400000, 548.62],
        [1201132800000, 574.49],
        [1201219200000, 566.4],
        [1201478400000, 555.98],
        [1201564800000, 550.52],
        [1201651200000, 548.27],
        [1201737600000, 564.3],
        [1201824000000, 515.9],
        [1202083200000, 495.43],
        [1202169600000, 506.8],
        [1202256000000, 501.71],
        [1202342400000, 504.95],
        [1202428800000, 516.69],
        [1202688000000, 521.16],
        [1202774400000, 518.09],
        [1202860800000, 534.62],
        [1202947200000, 532.25],
        [1203033600000, 529.64],
        [1203379200000, 508.95],
        [1203465600000, 509],
        [1203552000000, 502.86],
        [1203638400000, 507.8],
        [1203897600000, 486.44],
        [1203984000000, 464.19],
        [1204070400000, 472.86],
        [1204156800000, 475.39],
        [1204243200000, 471.18],
        [1204502400000, 457.02],
        [1204588800000, 444.6],
        [1204675200000, 447.7],
        [1204761600000, 432.7],
        [1204848000000, 433.35],
        [1205107200000, 413.62],
        [1205193600000, 439.84],
        [1205280000000, 440.18],
        [1205366400000, 443.01],
        [1205452800000, 437.92],
        [1205712000000, 419.87],
        [1205798400000, 439.16],
        [1205884800000, 432],
        [1205971200000, 433.55],
        [1206316800000, 460.56],
        [1206403200000, 450.78],
        [1206489600000, 458.19],
        [1206576000000, 444.08],
        [1206662400000, 438.08],
        [1206921600000, 440.47],
        [1207008000000, 465.71],
        [1207094400000, 465.7],
        [1207180800000, 455.12],
        [1207267200000, 471.09],
        [1207526400000, 476.82],
        [1207612800000, 467.81],
        [1207699200000, 464.19],
        [1207785600000, 469.08],
        [1207872000000, 457.45],
        [1208131200000, 451.66],
        [1208217600000, 446.84],
        [1208304000000, 455.03],
        [1208390400000, 449.54],
        [1208476800000, 539.41],
        [1208736000000, 537.79],
        [1208822400000, 555],
        [1208908800000, 546.49],
        [1208995200000, 543.04],
        [1209081600000, 544.06],
        [1209340800000, 552.12],
        [1209427200000, 558.47],
        [1209513600000, 574.29],
        [1209600000000, 593.08],
        [1209686400000, 581.29],
        [1209945600000, 594.9],
        [1210032000000, 586.36],
        [1210118400000, 579],
        [1210204800000, 583.01],
        [1210291200000, 573.2],
        [1210550400000, 584.94],
        [1210636800000, 583],
        [1210723200000, 576.3],
        [1210809600000, 581],
        [1210896000000, 580.07],
        [1211155200000, 577.52],
        [1211241600000, 578.6],
        [1211328000000, 549.99],
        [1211414400000, 549.46],
        [1211500800000, 544.62],
        [1211846400000, 560.9],
        [1211932800000, 568.24],
        [1212019200000, 583],
        [1212105600000, 585.8],
        [1212364800000, 575],
        [1212451200000, 567.3],
        [1212537600000, 572.22],
        [1212624000000, 586.3],
        [1212710400000, 567],
        [1212969600000, 557.87],
        [1213056000000, 554.17],
        [1213142400000, 545.2],
        [1213228800000, 552.95],
        [1213315200000, 571.51],
        [1213574400000, 572.81],
        [1213660800000, 569.46],
        [1213747200000, 562.38],
        [1213833600000, 560.2],
        [1213920000000, 546.43],
        [1214179200000, 545.21],
        [1214265600000, 542.3],
        [1214352000000, 551],
        [1214438400000, 528.82],
        [1214524800000, 528.07],
        [1214784000000, 526.42],
        [1214870400000, 534.73],
        [1214956800000, 527.04],
        [1215043200000, 537],
        [1215388800000, 543.91],
        [1215475200000, 554.53],
        [1215561600000, 541.55],
        [1215648000000, 540.57],
        [1215734400000, 533.8],
        [1215993600000, 521.62],
        [1216080000000, 516.09],
        [1216166400000, 535.6],
        [1216252800000, 533.44],
        [1216339200000, 481.32],
        [1216598400000, 468.8],
        [1216684800000, 477.11],
        [1216771200000, 489.22],
        [1216857600000, 475.62],
        [1216944000000, 491.98],
        [1217203200000, 477.12],
        [1217289600000, 483.11],
        [1217376000000, 482.7],
        [1217462400000, 473.75],
        [1217548800000, 467.86],
        [1217808000000, 463],
        [1217894400000, 479.85],
        [1217980800000, 486.34],
        [1218067200000, 479.12],
        [1218153600000, 495.01],
        [1218412800000, 500.84],
        [1218499200000, 502.61],
        [1218585600000, 500.03],
        [1218672000000, 505.49],
        [1218758400000, 510.15],
        [1219017600000, 498.3],
        [1219104000000, 490.5],
        [1219190400000, 485],
        [1219276800000, 486.53],
        [1219363200000, 490.59],
        [1219622400000, 483.01],
        [1219708800000, 474.16],
        [1219795200000, 468.58],
        [1219881600000, 473.78],
        [1219968000000, 463.29],
        [1220313600000, 465.25],
        [1220400000000, 464.41],
        [1220486400000, 450.26],
        [1220572800000, 444.25],
        [1220832000000, 419.95],
        [1220918400000, 418.66],
        [1221004800000, 414.16],
        [1221091200000, 433.75],
        [1221177600000, 437.66],
        [1221436800000, 433.86],
        [1221523200000, 442.93],
        [1221609600000, 414.49],
        [1221696000000, 439.08],
        [1221782400000, 449.15],
        [1222041600000, 430.14],
        [1222128000000, 429.27],
        [1222214400000, 435.11],
        [1222300800000, 439.6],
        [1222387200000, 431.04],
        [1222646400000, 381],
        [1222732800000, 400.52],
        [1222819200000, 411.72],
        [1222905600000, 390.49],
        [1222992000000, 386.91],
        [1223251200000, 371.21],
        [1223337600000, 346.01],
        [1223424000000, 338.11],
        [1223510400000, 328.98],
        [1223596800000, 332],
        [1223856000000, 381.02],
        [1223942400000, 362.71],
        [1224028800000, 339.17],
        [1224115200000, 353.02],
        [1224201600000, 372.54],
        [1224460800000, 379.32],
        [1224547200000, 362.75],
        [1224633600000, 355.67],
        [1224720000000, 352.32],
        [1224806400000, 339.29],
        [1225065600000, 329.49],
        [1225152000000, 368.75],
        [1225238400000, 358],
        [1225324800000, 359.69],
        [1225411200000, 359.36],
        [1225670400000, 346.49],
        [1225756800000, 366.94],
        [1225843200000, 342.24],
        [1225929600000, 331.22],
        [1226016000000, 331.14],
        [1226275200000, 318.78],
        [1226361600000, 311.46],
        [1226448000000, 291],
        [1226534400000, 312.08],
        [1226620800000, 310.02],
        [1226880000000, 300.12],
        [1226966400000, 297.42],
        [1227052800000, 280.18],
        [1227139200000, 259.56],
        [1227225600000, 262.43],
        [1227484800000, 257.44],
        [1227571200000, 282.05],
        [1227657600000, 292.09],
        [1227744000000, 292.09],
        [1227830400000, 292.96],
        [1228089600000, 265.99],
        [1228176000000, 275.11],
        [1228262400000, 279.43],
        [1228348800000, 274.34],
        [1228435200000, 283.99],
        [1228694400000, 302.11],
        [1228780800000, 305.97],
        [1228867200000, 308.82],
        [1228953600000, 300.22],
        [1229040000000, 315.76],
        [1229299200000, 310.67],
        [1229385600000, 325.28],
        [1229472000000, 315.24],
        [1229558400000, 310.28],
        [1229644800000, 310.17],
        [1229904000000, 297.11],
        [1229990400000, 298.02],
        [1230076800000, 302.95],
        [1230163200000, 302.95],
        [1230249600000, 300.36],
        [1230508800000, 297.42],
        [1230595200000, 303.11],
        [1230681600000, 307.65],
        [1230768000000, 307.65],
        [1230854400000, 321.32],
        [1231113600000, 328.05],
        [1231200000000, 334.06],
        [1231286400000, 322.01],
        [1231372800000, 325.19],
        [1231459200000, 315.07],
        [1231718400000, 312.69],
        [1231804800000, 314.32],
        [1231891200000, 300.97],
        [1231977600000, 298.99],
        [1232064000000, 299.67],
        [1232409600000, 282.75],
        [1232496000000, 303.08],
        [1232582400000, 306.5],
        [1232668800000, 324.7],
        [1232928000000, 323.87],
        [1233014400000, 331.48],
        [1233100800000, 348.67],
        [1233187200000, 343.32],
        [1233273600000, 338.53],
        [1233532800000, 340.57],
        [1233619200000, 340.45],
        [1233705600000, 343],
        [1233792000000, 353.72],
        [1233878400000, 371.28],
        [1234137600000, 378.77],
        [1234224000000, 358.51],
        [1234310400000, 358.04],
        [1234396800000, 363.05],
        [1234483200000, 357.68],
        [1234828800000, 342.66],
        [1234915200000, 353.11],
        [1235001600000, 342.64],
        [1235088000000, 346.45],
        [1235347200000, 330.06],
        [1235433600000, 345.45],
        [1235520000000, 341.64],
        [1235606400000, 337.18],
        [1235692800000, 337.99],
        [1235952000000, 327.16],
        [1236038400000, 325.48],
        [1236124800000, 318.92],
        [1236211200000, 305.64],
        [1236297600000, 308.57],
        [1236556800000, 290.89],
        [1236643200000, 308.17],
        [1236729600000, 317.91],
        [1236816000000, 323.53],
        [1236902400000, 324.42],
        [1237161600000, 319.69],
        [1237248000000, 335.34],
        [1237334400000, 333.1],
        [1237420800000, 329.94],
        [1237507200000, 330.16],
        [1237766400000, 348.6],
        [1237852800000, 347.16],
        [1237939200000, 344.07],
        [1238025600000, 353.29],
        [1238112000000, 347.7],
        [1238371200000, 342.69],
        [1238457600000, 348.06],
        [1238544000000, 354.09],
        [1238630400000, 362.5],
        [1238716800000, 369.78],
        [1238976000000, 368.24],
        [1239062400000, 358.65],
        [1239148800000, 362],
        [1239235200000, 372.5],
        [1239321600000, 372.5],
        [1239580800000, 378.11],
        [1239667200000, 368.91],
        [1239753600000, 379.5],
        [1239840000000, 388.74],
        [1239926400000, 392.24],
        [1240185600000, 379.3],
        [1240272000000, 381.47],
        [1240358400000, 383.86],
        [1240444800000, 384.69],
        [1240531200000, 389.49],
        [1240790400000, 385.95],
        [1240876800000, 383.71],
        [1240963200000, 391.47],
        [1241049600000, 395.97],
        [1241136000000, 393.69],
        [1241395200000, 401.98],
        [1241481600000, 402.99],
        [1241568000000, 403.47],
        [1241654400000, 396.61],
        [1241740800000, 407.33],
        [1242000000000, 407.98],
        [1242086400000, 399.01],
        [1242172800000, 389.54],
        [1242259200000, 387.5],
        [1242345600000, 390],
        [1242604800000, 396.84],
        [1242691200000, 398.88],
        [1242777600000, 397.18],
        [1242864000000, 396.5],
        [1242950400000, 393.5],
        [1243296000000, 404.36],
        [1243382400000, 405.56],
        [1243468800000, 410.4],
        [1243555200000, 417.23],
        [1243814400000, 426.56],
        [1243900800000, 428.4],
        [1243987200000, 431.65],
        [1244073600000, 440.28],
        [1244160000000, 444.32],
        [1244419200000, 438.77],
        [1244505600000, 435.62],
        [1244592000000, 432.6],
        [1244678400000, 429],
        [1244764800000, 424.84],
        [1245024000000, 416.77],
        [1245110400000, 416],
        [1245196800000, 415.16],
        [1245283200000, 414.06],
        [1245369600000, 420.09],
        [1245628800000, 407.35],
        [1245715200000, 405.68],
        [1245801600000, 409.29],
        [1245888000000, 415.77],
        [1245974400000, 425.32],
        [1246233600000, 424.14],
        [1246320000000, 421.59],
        [1246406400000, 418.99],
        [1246492800000, 408.49],
        [1246579200000, 408.49],
        [1246838400000, 409.61],
        [1246924800000, 396.63],
        [1247011200000, 402.49],
        [1247097600000, 410.39],
        [1247184000000, 414.4],
        [1247443200000, 424.3],
        [1247529600000, 424.69],
        [1247616000000, 438.17],
        [1247702400000, 442.6],
        [1247788800000, 430.25],
        [1248048000000, 430.17],
        [1248134400000, 427.9],
        [1248220800000, 427.69],
        [1248307200000, 437.34],
        [1248393600000, 446.72],
        [1248652800000, 444.8],
        [1248739200000, 439.85],
        [1248825600000, 436.24],
        [1248912000000, 445.64],
        [1248998400000, 443.05],
        [1249257600000, 452.21],
        [1249344000000, 453.73],
        [1249430400000, 451.14],
        [1249516800000, 450.36],
        [1249603200000, 457.1],
        [1249862400000, 456.61],
        [1249948800000, 453.94],
        [1250035200000, 458.58],
        [1250121600000, 462.28],
        [1250208000000, 460],
        [1250467200000, 444.89],
        [1250553600000, 445.28],
        [1250640000000, 443.97],
        [1250726400000, 460.41],
        [1250812800000, 465.24],
        [1251072000000, 468.73],
        [1251158400000, 471.37],
        [1251244800000, 468],
        [1251331200000, 466.06],
        [1251417600000, 464.75],
        [1251676800000, 461.67],
        [1251763200000, 455.76],
        [1251849600000, 453.01],
        [1251936000000, 457.52],
        [1252022400000, 461.3],
        [1252368000000, 458.62],
        [1252454400000, 463.97],
        [1252540800000, 470.94],
        [1252627200000, 472.14],
        [1252886400000, 475.12],
        [1252972800000, 477.54],
        [1253059200000, 488.29],
        [1253145600000, 491.72],
        [1253232000000, 491.46],
        [1253491200000, 497],
        [1253577600000, 499.06],
        [1253664000000, 498.46],
        [1253750400000, 496.77],
        [1253836800000, 492.48],
        [1254096000000, 498.53],
        [1254182400000, 498.53],
        [1254268800000, 495.85],
        [1254355200000, 487.2],
        [1254441600000, 484.58],
        [1254700800000, 488.52],
        [1254787200000, 498.74],
        [1254873600000, 517.54],
        [1254960000000, 514.18],
        [1255046400000, 516.25],
        [1255305600000, 524.04],
        [1255392000000, 526.11],
        [1255478400000, 535.32],
        [1255564800000, 529.91],
        [1255651200000, 549.85],
        [1255910400000, 552.09],
        [1255996800000, 551.72],
        [1256083200000, 551.1],
        [1256169600000, 554.09],
        [1256256000000, 553.69],
        [1256515200000, 554.21],
        [1256601600000, 548.29],
        [1256688000000, 540.3],
        [1256774400000, 551.05],
        [1256860800000, 536.12],
        [1257120000000, 533.99],
        [1257206400000, 537.29],
        [1257292800000, 540.33],
        [1257379200000, 548.65],
        [1257465600000, 551.1],
        [1257724800000, 562.51],
        [1257811200000, 566.76],
        [1257897600000, 570.56],
        [1257984000000, 567.85],
        [1258070400000, 572.05],
        [1258329600000, 576.28],
        [1258416000000, 577.49],
        [1258502400000, 576.65],
        [1258588800000, 572.99],
        [1258675200000, 569.96],
        [1258934400000, 582.35],
        [1259020800000, 583.09],
        [1259107200000, 585.74],
        [1259193600000, 585.74],
        [1259280000000, 579.76],
        [1259539200000, 583],
        [1259625600000, 589.87],
        [1259712000000, 587.51],
        [1259798400000, 585.74],
        [1259884800000, 585.01],
        [1260144000000, 586.25],
        [1260230400000, 587.05],
        [1260316800000, 589.02],
        [1260403200000, 591.5],
        [1260489600000, 590.51],
        [1260748800000, 595.73],
        [1260835200000, 593.14],
        [1260921600000, 597.76],
        [1261008000000, 593.94],
        [1261094400000, 596.42],
        [1261353600000, 598.68],
        [1261440000000, 601.12],
        [1261526400000, 611.68],
        [1261612800000, 618.48],
        [1261699200000, 618.48],
        [1261958400000, 622.87],
        [1262044800000, 619.4],
        [1262131200000, 622.73],
        [1262217600000, 619.98],
        [1262304000000, 619.98],
        [1262563200000, 626.75],
        [1262649600000, 623.99],
        [1262736000000, 608.26],
        [1262822400000, 594.1],
        [1262908800000, 602.02],
        [1263168000000, 601.11],
        [1263254400000, 590.48],
        [1263340800000, 587.09],
        [1263427200000, 589.85],
        [1263513600000, 580],
        [1263772800000, 580],
        [1263859200000, 587.62],
        [1263945600000, 580.41],
        [1264032000000, 582.98],
        [1264118400000, 550.01],
        [1264377600000, 540],
        [1264464000000, 542.42],
        [1264550400000, 542.1],
        [1264636800000, 534.29],
        [1264723200000, 529.94],
        [1264982400000, 533.02],
        [1265068800000, 531.12],
        [1265155200000, 540.82],
        [1265241600000, 526.78],
        [1265328000000, 531.29],
        [1265587200000, 533.47],
        [1265673600000, 536.44],
        [1265760000000, 534.44],
        [1265846400000, 536.4],
        [1265932800000, 533.12],
        [1266192000000, 533.12],
        [1266278400000, 541.3],
        [1266364800000, 538.21],
        [1266451200000, 543.22],
        [1266537600000, 540.76],
        [1266796800000, 542.8],
        [1266883200000, 535.07],
        [1266969600000, 531.47],
        [1267056000000, 526.43],
        [1267142400000, 526.8],
        [1267401600000, 532.69],
        [1267488000000, 541.06],
        [1267574400000, 545.32],
        [1267660800000, 554.59],
        [1267747200000, 564.21],
        [1268006400000, 562.48],
        [1268092800000, 560.19],
        [1268179200000, 576.45],
        [1268265600000, 581.14],
        [1268352000000, 579.54],
        [1268611200000, 563.18],
        [1268697600000, 565.2],
        [1268784000000, 565.56],
        [1268870400000, 566.4],
        [1268956800000, 560],
        [1269216000000, 557.5],
        [1269302400000, 549],
        [1269388800000, 557.33],
        [1269475200000, 562.88],
        [1269561600000, 562.69],
        [1269820800000, 562.45],
        [1269907200000, 566.71],
        [1269993600000, 567.12],
        [1270080000000, 568.8],
        [1270166400000, 568.8],
        [1270425600000, 571.01],
        [1270512000000, 568.22],
        [1270598400000, 563.54],
        [1270684800000, 567.49],
        [1270771200000, 566.22],
        [1271030400000, 572.73],
        [1271116800000, 586.77],
        [1271203200000, 589],
        [1271289600000, 595.3],
        [1271376000000, 550.14],
        [1271635200000, 550.1],
        [1271721600000, 555.04],
        [1271808000000, 554.3],
        [1271894400000, 547.06],
        [1271980800000, 544.99],
        [1272240000000, 531.64],
        [1272326400000, 529.06],
        [1272412800000, 529.19],
        [1272499200000, 532],
        [1272585600000, 525.7],
        [1272844800000, 530.6],
        [1272931200000, 506.37],
        [1273017600000, 509.76],
        [1273104000000, 498.67],
        [1273190400000, 493.14],
        [1273449600000, 521.65],
        [1273536000000, 509.05],
        [1273622400000, 505.39],
        [1273708800000, 510.88],
        [1273795200000, 507.53],
        [1274054400000, 507.97],
        [1274140800000, 498.37],
        [1274227200000, 494.43],
        [1274313600000, 475.01],
        [1274400000000, 472.05],
        [1274659200000, 477.16],
        [1274745600000, 477.07],
        [1274832000000, 475.47],
        [1274918400000, 490.46],
        [1275004800000, 485.63],
        [1275264000000, 485.63],
        [1275350400000, 482.37],
        [1275436800000, 493.37],
        [1275523200000, 505.6],
        [1275609600000, 498.72],
        [1275868800000, 485.52],
        [1275955200000, 484.78],
        [1276041600000, 474.02],
        [1276128000000, 487.01],
        [1276214400000, 488.5],
        [1276473600000, 483.19],
        [1276560000000, 497.99],
        [1276646400000, 501.27],
        [1276732800000, 500.08],
        [1276819200000, 500.03],
        [1277078400000, 488.56],
        [1277164800000, 486.25],
        [1277251200000, 482.05],
        [1277337600000, 475.1],
        [1277424000000, 472.68],
        [1277683200000, 472.08],
        [1277769600000, 454.26],
        [1277856000000, 444.95],
        [1277942400000, 439.49],
        [1278028800000, 436.55],
        [1278288000000, 436.55],
        [1278374400000, 436.07],
        [1278460800000, 450.2],
        [1278547200000, 456.56],
        [1278633600000, 467.49],
        [1278892800000, 475.83],
        [1278979200000, 489.2],
        [1279065600000, 491.34],
        [1279152000000, 494.02],
        [1279238400000, 459.6],
        [1279497600000, 466.18],
        [1279584000000, 481.59],
        [1279670400000, 477.5],
        [1279756800000, 484.81],
        [1279843200000, 490.06],
        [1280102400000, 488.97],
        [1280188800000, 492.63],
        [1280275200000, 484.35],
        [1280361600000, 484.99],
        [1280448000000, 484.85],
        [1280707200000, 490.41],
        [1280793600000, 489.83],
        [1280880000000, 506.32],
        [1280966400000, 508.1],
        [1281052800000, 500.22],
        [1281312000000, 505.35],
        [1281398400000, 503.71],
        [1281484800000, 491.74],
        [1281571200000, 492.01],
        [1281657600000, 486.35],
        [1281916800000, 485.59],
        [1282003200000, 490.52],
        [1282089600000, 482.15],
        [1282176000000, 467.97],
        [1282262400000, 462.02],
        [1282521600000, 464.07],
        [1282608000000, 451.39],
        [1282694400000, 454.62],
        [1282780800000, 450.98],
        [1282867200000, 458.83],
        [1283126400000, 452.69],
        [1283212800000, 450.02],
        [1283299200000, 460.34],
        [1283385600000, 463.18],
        [1283472000000, 470.3],
        [1283731200000, 470.3],
        [1283817600000, 464.4],
        [1283904000000, 470.58],
        [1283990400000, 476.18],
        [1284076800000, 476.14],
        [1284336000000, 482.27],
        [1284422400000, 480.43],
        [1284508800000, 480.64],
        [1284595200000, 481.06],
        [1284681600000, 490.15],
        [1284940800000, 508.28],
        [1285027200000, 513.46],
        [1285113600000, 516],
        [1285200000000, 513.48],
        [1285286400000, 527.29],
        [1285545600000, 530.41],
        [1285632000000, 527.17],
        [1285718400000, 527.69],
        [1285804800000, 525.79],
        [1285891200000, 525.62],
        [1286150400000, 522.35],
        [1286236800000, 538.23],
        [1286323200000, 534.35],
        [1286409600000, 530.01],
        [1286496000000, 536.35],
        [1286755200000, 538.84],
        [1286841600000, 541.39],
        [1286928000000, 543.3],
        [1287014400000, 540.93],
        [1287100800000, 601.45],
        [1287360000000, 617.71],
        [1287446400000, 607.83],
        [1287532800000, 607.98],
        [1287619200000, 611.99],
        [1287705600000, 612.53],
        [1287964800000, 616.5],
        [1288051200000, 618.6],
        [1288137600000, 616.47],
        [1288224000000, 618.58],
        [1288310400000, 613.7],
        [1288569600000, 615],
        [1288656000000, 615.6],
        [1288742400000, 620.18],
        [1288828800000, 624.27],
        [1288915200000, 625.08],
        [1289174400000, 626.77],
        [1289260800000, 624.82],
        [1289347200000, 622.88],
        [1289433600000, 617.19],
        [1289520000000, 603.29],
        [1289779200000, 595.47],
        [1289865600000, 583.72],
        [1289952000000, 583.55],
        [1290038400000, 596.56],
        [1290124800000, 590.83],
        [1290384000000, 591.22],
        [1290470400000, 583.01],
        [1290556800000, 594.97],
        [1290729600000, 590],
        [1290988800000, 582.11],
        [1291075200000, 555.71],
        [1291161600000, 564.35],
        [1291248000000, 571.82],
        [1291334400000, 573],
        [1291593600000, 578.36],
        [1291680000000, 587.14],
        [1291766400000, 590.54],
        [1291852800000, 591.5],
        [1291939200000, 592.21],
        [1292198400000, 594.62],
        [1292284800000, 594.91],
        [1292371200000, 590.3],
        [1292457600000, 591.71],
        [1292544000000, 590.8],
        [1292803200000, 595.06],
        [1292889600000, 603.07],
        [1292976000000, 605.49],
        [1293062400000, 604.23],
        [1293408000000, 602.38],
        [1293494400000, 598.92],
        [1293580800000, 601],
        [1293667200000, 598.86],
        [1293753600000, 593.97],
        [1294012800000, 604.35],
        [1294099200000, 602.12],
        [1294185600000, 609.07],
        [1294272000000, 613.5],
        [1294358400000, 616.44],
        [1294617600000, 614.21],
        [1294704000000, 616.01],
        [1294790400000, 616.87],
        [1294876800000, 616.69],
        [1294963200000, 624.18],
        [1295308800000, 639.63],
        [1295395200000, 631.75],
        [1295481600000, 626.77],
        [1295568000000, 611.83],
        [1295827200000, 611.08],
        [1295913600000, 619.91],
        [1296000000000, 616.5],
        [1296086400000, 616.79],
        [1296172800000, 600.99],
        [1296432000000, 600.36],
        [1296518400000, 611.04],
        [1296604800000, 612],
        [1296691200000, 610.15],
        [1296777600000, 610.98],
        [1297036800000, 614.3],
        [1297123200000, 618.38],
        [1297209600000, 616.5],
        [1297296000000, 616.44],
        [1297382400000, 624.5],
        [1297641600000, 628.15],
        [1297728000000, 624.15],
        [1297814400000, 624.22],
        [1297900800000, 625.26],
        [1297987200000, 630.08],
        [1298332800000, 610.21],
        [1298419200000, 611.32],
        [1298505600000, 608.82],
        [1298592000000, 610.04],
        [1298851200000, 613.4],
        [1298937600000, 600.76],
        [1299024000000, 600.79],
        [1299110400000, 609.56],
        [1299196800000, 600.62],
        [1299456000000, 591.66],
        [1299542400000, 592.31],
        [1299628800000, 591.77],
        [1299715200000, 580.3],
        [1299801600000, 576.71],
        [1300060800000, 569.99],
        [1300147200000, 569.56],
        [1300233600000, 557.1],
        [1300320000000, 561.36],
        [1300406400000, 561.06],
        [1300665600000, 576.5],
        [1300752000000, 577.32],
        [1300838400000, 582.16],
        [1300924800000, 586.89],
        [1301011200000, 579.74],
        [1301270400000, 575.36],
        [1301356800000, 581.73],
        [1301443200000, 581.84],
        [1301529600000, 586.76],
        [1301616000000, 591.8],
        [1301875200000, 587.68],
        [1301961600000, 569.09],
        [1302048000000, 574.18],
        [1302134400000, 580],
        [1302220800000, 578.16],
        [1302480000000, 577.37],
        [1302566400000, 570.61],
        [1302652800000, 576.28],
        [1302739200000, 578.51],
        [1302825600000, 530.7],
        [1303084800000, 526.84],
        [1303171200000, 521.53],
        [1303257600000, 525.73],
        [1303344000000, 525.1],
        [1303689600000, 525.05],
        [1303776000000, 532.82],
        [1303862400000, 537.76],
        [1303948800000, 537.97],
        [1304035200000, 544.1],
        [1304294400000, 538.56],
        [1304380800000, 533.89],
        [1304467200000, 535.79],
        [1304553600000, 534.27],
        [1304640000000, 535.3],
        [1304899200000, 537.68],
        [1304985600000, 542.66],
        [1305072000000, 535.45],
        [1305158400000, 535.05],
        [1305244800000, 529.55],
        [1305504000000, 518.42],
        [1305590400000, 530.46],
        [1305676800000, 529.81],
        [1305763200000, 531.25],
        [1305849600000, 524.03],
        [1306108800000, 518.39],
        [1306195200000, 518.26],
        [1306281600000, 519.67],
        [1306368000000, 518.13],
        [1306454400000, 520.9],
        [1306800000000, 529.02],
        [1306886400000, 525.6],
        [1306972800000, 528.06],
        [1307059200000, 523.08],
        [1307318400000, 521.06],
        [1307404800000, 519.03],
        [1307491200000, 519.17],
        [1307577600000, 516.73],
        [1307664000000, 509.5],
        [1307923200000, 504.73],
        [1308009600000, 508.37],
        [1308096000000, 502.95],
        [1308182400000, 500.37],
        [1308268800000, 485.02],
        [1308528000000, 484.58],
        [1308614400000, 493],
        [1308700800000, 487],
        [1308787200000, 480.22],
        [1308873600000, 474.88],
        [1309132800000, 482.8],
        [1309219200000, 493.65],
        [1309305600000, 497.57],
        [1309392000000, 506.38],
        [1309478400000, 521.03],
        [1309824000000, 532.44],
        [1309910400000, 535.36],
        [1309996800000, 546.6],
        [1310083200000, 531.99],
        [1310342400000, 527.28],
        [1310428800000, 534.01],
        [1310515200000, 538.26],
        [1310601600000, 528.94],
        [1310688000000, 597.62],
        [1310947200000, 594.94],
        [1311033600000, 602.55],
        [1311120000000, 595.35],
        [1311206400000, 606.99],
        [1311292800000, 618.23],
        [1311552000000, 618.98],
        [1311638400000, 622.52],
        [1311724800000, 607.22],
        [1311811200000, 610.94],
        [1311897600000, 603.69],
        [1312156800000, 606.77],
        [1312243200000, 594.14],
        [1312329600000, 601.17],
        [1312416000000, 577.52],
        [1312502400000, 579.04],
        [1312761600000, 546.02],
        [1312848000000, 573.41],
        [1312934400000, 549.01],
        [1313020800000, 562.13],
        [1313107200000, 563.77],
        [1313366400000, 557.23],
        [1313452800000, 539],
        [1313539200000, 533.15],
        [1313625600000, 504.88],
        [1313712000000, 490.92],
        [1313971200000, 498.17],
        [1314057600000, 518.82],
        [1314144000000, 523.29],
        [1314230400000, 520.04],
        [1314316800000, 526.86],
        [1314576000000, 539.08],
        [1314662400000, 540.7],
        [1314748800000, 540.96],
        [1314835200000, 532.5],
        [1314921600000, 524.84],
        [1315267200000, 522.18],
        [1315353600000, 534.03],
        [1315440000000, 534.96],
        [1315526400000, 524.85],
        [1315785600000, 530.12],
        [1315872000000, 529.52],
        [1315958400000, 532.07],
        [1316044800000, 542.56],
        [1316131200000, 546.68],
        [1316390400000, 546.67],
        [1316476800000, 546.62],
        [1316563200000, 539.2],
        [1316649600000, 520.66],
        [1316736000000, 525.51],
        [1316995200000, 531.89],
        [1317081600000, 539.34],
        [1317168000000, 528.84],
        [1317254400000, 527.5],
        [1317340800000, 515.04],
        [1317600000000, 495.52],
        [1317686400000, 501.9],
        [1317772800000, 504.7],
        [1317859200000, 514.71],
        [1317945600000, 515.12],
        [1318204800000, 537.17],
        [1318291200000, 543.18],
        [1318377600000, 548.5],
        [1318464000000, 558.99],
        [1318550400000, 591.68],
        [1318809600000, 582.41],
        [1318896000000, 590.51],
        [1318982400000, 580.7],
        [1319068800000, 583.67],
        [1319155200000, 590.49],
        [1319414400000, 596.42],
        [1319500800000, 583.16],
        [1319587200000, 586.31],
        [1319673600000, 598.67],
        [1319760000000, 600.14],
        [1320019200000, 592.64],
        [1320105600000, 578.65],
        [1320192000000, 584.82],
        [1320278400000, 597.5],
        [1320364800000, 596.14],
        [1320624000000, 608.33],
        [1320710400000, 612.34],
        [1320796800000, 600.95],
        [1320883200000, 595.08],
        [1320969600000, 608.35],
        [1321228800000, 613],
        [1321315200000, 616.56],
        [1321401600000, 611.47],
        [1321488000000, 600.87],
        [1321574400000, 594.88],
        [1321833600000, 580.94],
        [1321920000000, 580],
        [1322006400000, 570.11],
        [1322179200000, 563],
        [1322438400000, 588.19],
        [1322524800000, 582.93],
        [1322611200000, 599.39],
        [1322697600000, 613.77],
        [1322784000000, 620.36],
        [1323043200000, 625.65],
        [1323129600000, 623.77],
        [1323216000000, 623.39],
        [1323302400000, 616.05],
        [1323388800000, 627.42],
        [1323648000000, 625.39],
        [1323734400000, 625.63],
        [1323820800000, 618.07],
        [1323907200000, 619.54],
        [1323993600000, 625.96],
        [1324252800000, 621.83],
        [1324339200000, 630.37],
        [1324425600000, 625.82],
        [1324512000000, 629.7],
        [1324598400000, 633.14],
        [1324944000000, 640.25],
        [1325030400000, 639.7],
        [1325116800000, 642.4],
        [1325203200000, 645.9],
        [1325548800000, 665.41],
        [1325635200000, 668.28],
        [1325721600000, 659.01],
        [1325808000000, 650.02],
        [1326067200000, 622.46],
        [1326153600000, 623.14],
        [1326240000000, 625.96],
        [1326326400000, 629.64],
        [1326412800000, 624.99],
        [1326758400000, 628.58],
        [1326844800000, 632.91],
        [1326931200000, 639.57],
        [1327017600000, 585.99],
        [1327276800000, 585.52],
        [1327363200000, 580.93],
        [1327449600000, 569.49],
        [1327536000000, 568.1],
        [1327622400000, 579.98],
        [1327881600000, 577.69],
        [1327968000000, 580.11],
        [1328054400000, 580.83],
        [1328140800000, 585.11],
        [1328227200000, 596.33],
        [1328486400000, 609.09],
        [1328572800000, 606.77],
        [1328659200000, 609.85],
        [1328745600000, 611.46],
        [1328832000000, 605.91],
        [1329091200000, 612.2],
        [1329177600000, 609.76],
        [1329264000000, 605.56],
        [1329350400000, 606.52],
        [1329436800000, 604.64],
        [1329782400000, 614],
        [1329868800000, 607.94],
        [1329955200000, 606.11],
        [1330041600000, 609.9],
        [1330300800000, 609.31],
        [1330387200000, 618.39],
        [1330473600000, 618.25],
        [1330560000000, 622.4],
        [1330646400000, 621.25],
        [1330905600000, 614.25],
        [1330992000000, 604.96],
        [1331078400000, 606.8],
        [1331164800000, 607.14],
        [1331251200000, 600.25],
        [1331510400000, 605.15],
        [1331596800000, 617.78],
        [1331683200000, 615.99],
        [1331769600000, 621.13],
        [1331856000000, 625.04],
        [1332115200000, 633.98],
        [1332201600000, 633.49],
        [1332288000000, 639.98],
        [1332374400000, 646.05],
        [1332460800000, 642.59],
        [1332720000000, 649.33],
        [1332806400000, 647.02],
        [1332892800000, 655.76],
        [1332979200000, 648.41],
        [1333065600000, 641.24],
        [1333324800000, 646.92],
        [1333411200000, 642.62],
        [1333497600000, 635.15],
        [1333584000000, 632.32],
        [1333929600000, 630.84],
        [1334016000000, 626.86],
        [1334102400000, 635.96],
        [1334188800000, 651.01],
        [1334275200000, 624.6],
        [1334534400000, 606.07],
        [1334620800000, 609.57],
        [1334707200000, 607.45],
        [1334793600000, 599.3],
        [1334880000000, 596.06],
        [1335139200000, 597.6],
        [1335225600000, 601.27],
        [1335312000000, 609.72],
        [1335398400000, 615.47],
        [1335484800000, 614.98],
        [1335744000000, 604.85],
        [1335830400000, 604.43],
        [1335916800000, 607.26],
        [1336003200000, 611.02],
        [1336089600000, 596.97],
        [1336348800000, 607.55],
        [1336435200000, 612.79],
        [1336521600000, 609.15],
        [1336608000000, 613.66],
        [1336694400000, 605.23],
        [1336953600000, 604],
        [1337040000000, 611.11],
        [1337126400000, 628.93],
        [1337212800000, 623.05],
        [1337299200000, 600.4],
        [1337558400000, 614.11],
        [1337644800000, 600.8],
        [1337731200000, 609.46],
        [1337817600000, 603.66],
        [1337904000000, 591.53],
        [1338249600000, 594.34],
        [1338336000000, 588.23],
        [1338422400000, 580.86],
        [1338508800000, 570.98],
        [1338768000000, 578.59],
        [1338854400000, 570.41],
        [1338940800000, 580.57],
        [1339027200000, 578.23],
        [1339113600000, 580.45],
        [1339372800000, 568.5],
        [1339459200000, 565.1],
        [1339545600000, 561.09],
        [1339632000000, 559.05],
        [1339718400000, 564.51],
        [1339977600000, 570.85],
        [1340064000000, 581.53],
        [1340150400000, 577.51],
        [1340236800000, 565.21],
        [1340323200000, 571.48],
        [1340582400000, 560.7],
        [1340668800000, 564.68],
        [1340755200000, 569.3],
        [1340841600000, 564.31],
        [1340928000000, 580.07],
        [1341187200000, 580.47],
        [1341273600000, 587.83],
        [1341446400000, 595.92],
        [1341532800000, 585.98],
        [1341792000000, 586.01],
        [1341878400000, 581.7],
        [1341964800000, 571.19],
        [1342051200000, 570.48],
        [1342137600000, 576.52],
        [1342396800000, 574.92],
        [1342483200000, 576.73],
        [1342569600000, 580.76],
        [1342656000000, 593.06],
        [1342742400000, 610.82],
        [1343001600000, 615.51],
        [1343088000000, 607.57],
        [1343174400000, 607.99],
        [1343260800000, 613.36],
        [1343347200000, 634.96],
        [1343606400000, 632.3],
        [1343692800000, 632.97],
        [1343779200000, 632.68],
        [1343865600000, 628.75],
        [1343952000000, 641.33],
        [1344211200000, 642.82],
        [1344297600000, 640.54],
        [1344384000000, 642.23],
        [1344470400000, 642.35],
        [1344556800000, 642],
        [1344816000000, 660.01],
        [1344902400000, 668.66],
        [1344988800000, 667.54],
        [1345075200000, 672.87],
        [1345161600000, 677.14],
        [1345420800000, 675.54],
        [1345507200000, 669.51],
        [1345593600000, 677.18],
        [1345680000000, 676.8],
        [1345766400000, 678.63],
        [1346025600000, 669.22],
        [1346112000000, 677.25],
        [1346198400000, 688.01],
        [1346284800000, 681.68],
        [1346371200000, 685.09],
        [1346716800000, 681.04],
        [1346803200000, 680.72],
        [1346889600000, 699.4],
        [1346976000000, 706.15],
        [1347235200000, 700.77],
        [1347321600000, 692.19],
        [1347408000000, 690.88],
        [1347494400000, 706.04],
        [1347580800000, 709.68],
        [1347840000000, 709.98],
        [1347926400000, 718.28],
        [1348012800000, 727.5],
        [1348099200000, 728.12],
        [1348185600000, 733.99],
        [1348444800000, 749.38],
        [1348531200000, 749.16],
        [1348617600000, 753.46],
        [1348704000000, 756.5],
        [1348790400000, 754.5],
        [1349049600000, 761.78],
        [1349136000000, 756.99],
        [1349222400000, 762.5],
        [1349308800000, 768.05],
        [1349395200000, 767.65],
        [1349654400000, 757.84],
        [1349740800000, 744.09],
        [1349827200000, 744.56],
        [1349913600000, 751.48],
        [1350000000000, 744.75],
        [1350259200000, 740.98],
        [1350345600000, 744.7],
        [1350432000000, 755.49],
        [1350518400000, 695],
        [1350604800000, 681.79],
        [1350864000000, 678.67],
        [1350950400000, 680.35],
        [1351036800000, 677.3],
        [1351123200000, 677.76],
        [1351209600000, 675.15],
        [1351641600000, 680.3],
        [1351728000000, 687.59],
        [1351814400000, 687.92],
        [1352073600000, 682.96],
        [1352160000000, 681.72],
        [1352246400000, 667.12],
        [1352332800000, 652.29],
        [1352419200000, 663.03],
        [1352678400000, 665.9],
        [1352764800000, 659.05],
        [1352851200000, 652.55],
        [1352937600000, 647.26],
        [1353024000000, 647.18],
        [1353283200000, 668.21],
        [1353369600000, 669.97],
        [1353456000000, 665.87],
        [1353628800000, 667.97],
        [1353888000000, 661.15],
        [1353974400000, 670.71],
        [1354060800000, 683.67],
        [1354147200000, 691.89],
        [1354233600000, 698.37],
        [1354492800000, 695.25],
        [1354579200000, 691.03],
        [1354665600000, 687.82],
        [1354752000000, 691.13],
        [1354838400000, 684.21],
        [1355097600000, 685.42],
        [1355184000000, 696.88],
        [1355270400000, 697.56],
        [1355356800000, 702.7],
        [1355443200000, 701.96],
        [1355702400000, 720.78],
        [1355788800000, 721.07],
        [1355875200000, 720.11],
        [1355961600000, 722.36],
        [1356048000000, 715.63],
        [1356307200000, 709.5],
        [1356480000000, 708.87],
        [1356566400000, 706.29],
        [1356652800000, 700.01],
        [1356912000000, 707.38],
        [1357084800000, 723.25],
        [1357171200000, 723.67],
        [1357257600000, 737.97],
        [1357516800000, 734.75],
        [1357603200000, 733.3],
        [1357689600000, 738.12],
        [1357776000000, 741.48],
        [1357862400000, 739.99],
        [1358121600000, 723.25],
        [1358208000000, 724.93],
        [1358294400000, 715.19],
        [1358380800000, 711.32],
        [1358467200000, 704.51],
        [1358812800000, 702.87],
        [1358899200000, 741.5],
        [1358985600000, 753.83],
        [1359072000000, 753.67],
        [1359331200000, 750.73],
        [1359417600000, 753.68],
        [1359504000000, 753.83],
        [1359590400000, 755.69],
        [1359676800000, 775.6],
        [1359936000000, 759.02],
        [1360022400000, 765.74],
        [1360108800000, 770.17],
        [1360195200000, 773.95],
        [1360281600000, 785.37],
        [1360540800000, 782.42],
        [1360627200000, 780.7],
        [1360713600000, 782.86],
        [1360800000000, 787.82],
        [1360886400000, 792.89],
        [1361232000000, 806.85],
        [1361318400000, 792.46],
        [1361404800000, 795.53],
        [1361491200000, 799.71],
        [1361750400000, 790.77],
        [1361836800000, 790.13],
        [1361923200000, 799.78],
        [1362009600000, 801.2],
        [1362096000000, 806.19],
        [1362355200000, 821.5],
        [1362441600000, 838.6],
        [1362528000000, 831.38],
        [1362614400000, 832.6],
        [1362700800000, 831.52],
        [1362960000000, 834.82],
        [1363046400000, 827.61],
        [1363132800000, 825.31],
        [1363219200000, 821.54],
        [1363305600000, 814.3],
        [1363564800000, 807.79],
        [1363651200000, 811.32],
        [1363737600000, 814.71],
        [1363824000000, 811.26],
        [1363910400000, 810.31],
        [1364169600000, 809.64],
        [1364256000000, 812.42],
        [1364342400000, 802.66],
        [1364428800000, 794.19],
        [1364774400000, 801.19],
        [1364860800000, 813.04],
        [1364947200000, 806.2],
        [1365033600000, 795.07],
        [1365120000000, 783.05],
        [1365379200000, 774.85],
        [1365465600000, 777.65],
        [1365552000000, 790.18],
        [1365638400000, 790.39],
        [1365724800000, 790.05],
        [1365984000000, 781.93],
        [1366070400000, 793.37],
        [1366156800000, 782.56],
        [1366243200000, 765.91],
        [1366329600000, 799.87],
        [1366588800000, 800.11],
        [1366675200000, 807.9],
        [1366761600000, 813.45],
        [1366848000000, 809.1],
        [1366934400000, 801.42],
        [1367193600000, 819.06],
        [1367280000000, 824.57],
        [1367366400000, 820.43],
        [1367452800000, 829.61],
        [1367539200000, 845.72],
        [1367798400000, 861.55],
        [1367884800000, 857.23],
        [1367971200000, 873.63],
        [1368057600000, 871.48],
        [1368144000000, 880.23],
      ],
    },
  ],
};
