import { SelectChangeEvent } from "@mui/material";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import React, { ReactElement, useState } from "react";
import { ThemeProvider } from "styled-components";
import { default as SelectCheckbox } from "components/Checkbox";

import {
  Wrapper,
  StyledSelect,
  SelectItem,
  HelperText,
  Label,
  SelectIcon,
  ErrorIcon,
  SelectAdornment,
  ErrorText,
  Container,
} from "./Select.styled";
import { SelectProps } from "./SelectProps";
import Skeleton from "components/Skeleton";

const Select = ({
  id,
  label,
  description,
  disabled,
  multiple = false,
  displayOption = "Full",
  className,
  children,
  loading,
  onChange,
  onFocus,
  onClose,
  onOpen,
  classes: MuiClasses,
  value: valueProp = undefined,
  open: openProp,
  defaultValue,
  error,
  size = "medium",
  errorMessage,
  ...restProps
}: SelectProps): ReactElement => {
  const defaultValues =
    defaultValue && defaultValue !== "" ? defaultValue : multiple ? [] : "";
  const [values, setValues] = useState(defaultValues);
  const [open, setOpen] = useState(false);

  const handleChange = (
    event: SelectChangeEvent<any>,
    child: React.ReactNode
  ): void => {
    const {
      target: { value },
    } = event;

    const newValues = multiple
      ? typeof value === "string"
        ? value.split(",")
        : value
      : value;
    setValues(newValues);

    if (onChange) {
      onChange(event, child);
    }
  };

  const handleClose = (event: React.SyntheticEvent<Element, Event>) => {
    if (onClose) {
      onClose(event);
    }
    setOpen(false);
  };

  const handleOpen = (event: React.SyntheticEvent<Element, Event>) => {
    if (onClose) {
      onClose(event);
    }
    setOpen(true);
  };

  return (
    <MuiThemeProvider theme={createTheme()}>
      <ThemeProvider
        theme={{
          displayOption: displayOption,
          open: openProp || open,
        }}
      >
        {loading ? (
          <Wrapper className={className}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={size === "small" ? "52px" : "64px"}
            ></Skeleton>
          </Wrapper>
        ) : (
          <Wrapper className={className}>
            <Container>
              {label && (
                <Label
                  disabled={disabled}
                  error={!!error}
                  id={id && `${id}-label`}
                  size={size === "small" ? "small" : "normal"}
                >
                  {label}
                </Label>
              )}
              <StyledSelect
                {...restProps}
                labelId={id && `${id}-label`}
                id={id}
                className={className}
                label={label}
                size={size}
                error={!!error}
                disabled={disabled}
                onClose={handleClose}
                onOpen={handleOpen}
                open={openProp || open}
                classes={MuiClasses}
                multiple={multiple}
                MenuProps={{ disablePortal: true }}
                IconComponent={() => null}
                renderValue={(_value: any) => {
                  if (typeof _value === "string") {
                    return (children as any[])?.find(
                      (item) => item.props.value === _value
                    )?.props.children;
                  }

                  const filteredList = (children as any[])
                    ?.filter(
                      (item1) =>
                        !!_value.find(
                          (item2: String) => item1.props.value === item2
                        )
                    )
                    .map((x) => x.props.children);

                  return (filteredList as Array<string>).join(", ");
                }}
                endAdornment={
                  <SelectAdornment>
                    {error && <ErrorIcon />}
                    <SelectIcon />
                  </SelectAdornment>
                }
                onChange={handleChange}
                value={valueProp || values}
                defaultValue={defaultValues}
                onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                  if (onFocus) {
                    onFocus(event);
                  }
                }}
              >
                {(children as any[])?.map((item, index) => {
                  return (
                    <SelectItem
                      key={`radio-${index}`}
                      value={item.props.value}
                      disabled={item.props.disabled}
                      size={size}
                    >
                      {multiple && (
                        <SelectCheckbox
                          label={null}
                          size={size}
                          checked={
                            valueProp !== undefined
                              ? (valueProp as any[]).indexOf(item.props.value) >
                                -1
                              : (values as any[]).indexOf(item.props.value) > -1
                          }
                        />
                      )}
                      <div>{item.props.children}</div>
                    </SelectItem>
                  );
                })}
              </StyledSelect>
            </Container>
            {description && (
              <HelperText disabled={disabled} error={false}>
                {description}
              </HelperText>
            )}
            {errorMessage && (
              <ErrorText>
                <span>{errorMessage}</span>
              </ErrorText>
            )}
          </Wrapper>
        )}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default React.memo(Select);
