import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { colors } from "../colors";
import { BASE_FONT_SIZE } from "../settings";

export const FONT = {
  WHITNEY: "Whitney, Helvetica, Arial, sans-serif",
};

export const GlobalStyle = createGlobalStyle`
    ${normalize};

    :root {
        ${colors}
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    :focus-visible {
        outline: 7px solid var(--color-cyan-o20);
        outline-offset: 7px;
    }

    html {
        background-color: var(--color-black-l95);
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
    }

    body {
        color: var(--color-black);
        font-family: ${FONT.WHITNEY};
        font-size: ${BASE_FONT_SIZE}px;
        font-feature-settings: "salt" on;

        &.freeze {
            overflow: hidden;
        }

        & > iframe {
            pointer-events: none;
        }

    }

    body,
    #root {
        min-height: 100vh;
    }

    code{
        display: inline-block;
        padding: 0 4px;
        color: var(--muidocs-palette-text-primary, #1C2025);
        background-color: var(--muidocs-palette-grey-50, #F3F6F9);
        border: 1px solid;
        border-color: var(--muidocs-palette-grey-200, #DAE2ED);
        border-radius: 5px;
        font-size: 0.8125rem;
        direction: ltr;
    }
`;
