import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import { Office28 } from "icons";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import { LabelS } from "style/components/Typography";
import generateSrcSet from "style/generateSrcSet";
import Image from "types/Image";
import { appendComponentWithNoBr } from "utils/appendComponentWithNoBr";
import ucFirst from "utils/ucFirst";

import {
  Container,
  Content,
  Description,
  Email,
  Heading,
  HeadingArrow,
  HeadingUrl,
  Initials,
  Office,
  Offices,
  Phone,
  PhoneSection,
  ProfileImage,
  ProfileLink,
} from "./Profile.styled";
import ProfileProps, { ProfileImageOrInitialsProps } from "./ProfileProps";
import Skeleton from "components/Skeleton";

const ProfileImageOrInitials = ({
  image,
  initials,
}: ProfileImageOrInitialsProps): ReactElement => {
  return (
    <>{image ? <ProfileImage {...image} /> : <Initials>{initials}</Initials>}</>
  );
};

const Profile = ({
  fullName,
  role,
  businessArea,
  email,
  imageUrl,
  initials,
  phoneFixed,
  phoneMobile,
  description,
  className,
  url,
  anchorName,
  size = "large",
  offices,
  theme,
  loading,
}: ProfileProps): ReactElement => {
  let image = undefined;

  if (imageUrl) {
    image = {
      url: imageUrl,
      decorative: false,
      alt: fullName,
      overlay: "UpLeft",
    } as Image;

    image.srcSet = generateSrcSet({
      image: imageUrl,
      content: [
        {
          width: 154,
          height: 154,
          breakpoint: BREAKPOINT_NUMBER.XL,
        },
        {
          width: 147,
          height: 147,
          breakpoint: BREAKPOINT_NUMBER.M,
        },
        { width: 84, height: 84 },
      ],
      format: "jpg",
    });
  }
  const exitingTheme = useContext(ThemeContext) || {};
  return (
    <ThemeProvider
      theme={{ theme: theme || exitingTheme["theme"], size: size }}
    >
      <Container id={anchorName} className={className}>
        {loading ? (
          <Skeleton variant="rectangular">
            <ProfileImageOrInitials image={image} initials={initials} />
          </Skeleton>
        ) : url ? (
          <ProfileLink url={url} title={fullName} underline="none">
            <ProfileImageOrInitials image={image} initials={initials} />
          </ProfileLink>
        ) : (
          <ProfileImageOrInitials image={image} initials={initials} />
        )}
        <Content>
          {loading ? (
            <Skeleton variant="text" width={"150px"}>
              <Heading>fullname</Heading>
            </Skeleton>
          ) : url ? (
            <HeadingUrl to={url} underline="none">
              {appendComponentWithNoBr(
                fullName,
                <HeadingArrow icon={"longArrowRight28"} aria-hidden="true" />
              )}
            </HeadingUrl>
          ) : (
            <Heading>{fullName}</Heading>
          )}
          {loading ? (
            <Skeleton variant="text" width={"250px"}>
              <LabelS>role</LabelS>
            </Skeleton>
          ) : (
            (role || businessArea) && (
              <LabelS>
                {role && <span>{role}</span>}
                {role && businessArea && " "}
                {businessArea && <span>{businessArea}</span>}
              </LabelS>
            )
          )}
          {loading ? (
            <Skeleton variant="text" width={"350px"}>
              <Email>email</Email>
            </Skeleton>
          ) : (
            email && (
              <Email email={email} obfuscateChildren={false}>
                <span>{email}</span>
              </Email>
            )
          )}
          {loading ? (
            <Skeleton variant="text" width={"350px"}>
              <PhoneSection>
                <Phone>phone</Phone>
              </PhoneSection>
            </Skeleton>
          ) : (
            (phoneMobile || phoneFixed) && (
              <PhoneSection>
                {phoneMobile && (
                  <Phone tel={phoneMobile?.replace(/\s+/g, "")}>
                    {phoneMobile}
                  </Phone>
                )}
                {phoneFixed && (
                  <Phone tel={phoneFixed?.replace(/\s+/g, "")}>
                    {phoneFixed}
                  </Phone>
                )}
              </PhoneSection>
            )
          )}
          {!loading && description && (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {!loading && offices && offices.length > 0 && (
            <Offices>
              {offices.map((office, index) => (
                <Office key={`Office${index}`}>
                  <Office28 />
                  {[ucFirst(office.city), office.addressRow1]
                    .filter(Boolean)
                    .join(", ")}
                </Office>
              ))}
            </Offices>
          )}
        </Content>
      </Container>
    </ThemeProvider>
  );
};

export default React.memo(Profile);
