import React, { ReactElement, useContext } from "react";

import { StyledSkeleton } from "./Skeleton.styled";
import SkeletonProperties from "./SkeletonProps";
import { ThemeContext, ThemeProvider } from "styled-components";

const Skeleton = ({
  width,
  variant,
  height,
  classes,
  children,
  animation = "wave",
  theme,
  contentId,
  className,
  anchorName,
}: SkeletonProperties): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};
  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
      }}
    >
      <StyledSkeleton
        width={width}
        variant={variant}
        height={height}
        classes={classes}
        animation={animation}
        className={className}
        id={contentId || anchorName}
      >
        {children}
      </StyledSkeleton>
    </ThemeProvider>
  );
};

export default React.memo(Skeleton);
