import styled from "styled-components";

import { MQ } from "../mediaQueries";

import { GUTTER } from "../grid";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<containerProps>`
  padding: 2.1875rem ${GUTTER.SM} 3.5rem ${GUTTER.SM};
  width: 100%;
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}

  ${MQ.FROM_M} {
    padding: 3.5rem ${GUTTER.MD} 6.125rem ${GUTTER.MD};
  }

  ${MQ.FROM_XL} {
    padding: 4.375rem ${GUTTER.XL} 8.75rem ${GUTTER.XL};
  }
`;

export interface containerProps {
  readonly textAlign?: "left" | "center" | "right";
}
