import React, { ReactElement, useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

import { Number, NumbersContainer, Percentage, Text } from "./KeyFigure.styled";
import KeyFigureProps from "./KeyFigureProps";
import { LocalizationEnglish, LocalizationSwedish } from "utils/settings";
import SignColorNumber from "components/SignColorNumber";
import Skeleton from "components/Skeleton";

const KeyFigure = ({
  className,
  contentId,
  keyFigure,
  text,
  start = 0,
  delay = 0,
  suffix,
  prefix,
  percentage,
  biggerSize = "M",
  animated = true,
  culture,
  loading,
}: KeyFigureProps): ReactElement => {
  const decimalSeparator =
    culture === "en-US"
      ? LocalizationEnglish.decimalPoint
      : LocalizationSwedish.decimalPoint;
  const thousandsSeparator =
    culture === "en-US"
      ? LocalizationEnglish.thousandsSep
      : LocalizationSwedish.thousandsSep;
  const isDecimal = keyFigure && keyFigure % 1 !== 0;
  
  const getBiggestNumberWithSameLength = (number: number): string => {
    let length = number.toString().length;
    var str = "9";
    while (str.length < length) {
      str = str + "0";
    }
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator ?? " ");
  };
  const removeFixedWidth = () => {
    const countUpNode = numberRef.current;
    if (countUpNode) {
      countUpNode.style.width = "";
    }
  };

  const [widthCalculated, setWidthCalculated] = useState(false);  
  const [temporalKeyFigure, setTemporalKeyFigure] = useState(
    keyFigure ? getBiggestNumberWithSameLength(keyFigure) : "0"
  );
  const numberRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const countUpNode = numberRef.current;
    if (countUpNode && !widthCalculated && animated && !loading && temporalKeyFigure !== "0") {
      const countUpWidth = countUpNode.offsetWidth + 1;
      countUpNode.style.width = `${countUpWidth}px`;
      setTemporalKeyFigure("0");
      setWidthCalculated(true);
    } else if (!animated) {
      setWidthCalculated(true);
    }
  }, [widthCalculated, animated, loading, temporalKeyFigure]);

  useEffect(() => {
    if (widthCalculated || temporalKeyFigure === "0") {
      removeFixedWidth();
      setTemporalKeyFigure(
        keyFigure ? getBiggestNumberWithSameLength(keyFigure) : "0"
      );
      setWidthCalculated(false);
    }
  }, [keyFigure, percentage, suffix, prefix]);

  useEffect(() => {
    const handleResize = () => {
      removeFixedWidth();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return loading ? (
    <Skeleton variant="rectangular" width={"180px"}>
      <Number size={biggerSize}>1</Number>
      <Text> {text || ""}</Text>
    </Skeleton>
  ) : (
    <div id={contentId} className={className} ref={numberRef}>
      <NumbersContainer>
        {keyFigure !== undefined &&
          (animated && widthCalculated ? (
            <CountUp
              decimal={decimalSeparator}
              separator={thousandsSeparator}
              duration={1}
              start={start}
              decimals={isDecimal ? 2 : 0}
              end={keyFigure || 0}
              enableScrollSpy={true}
              scrollSpyDelay={delay}
              scrollSpyOnce={true}
              delay={delay}
              suffix={suffix || ""}
              prefix={prefix || ""}
            >
              {({ countUpRef }) => (
                <Number
                  size={biggerSize}
                  ref={countUpRef as React.RefObject<HTMLDivElement>}
                >
                  {start}
                </Number>
              )}
            </CountUp>
          ) : !animated ? (
            <CountUp
              decimal={decimalSeparator}
              separator={thousandsSeparator}
              start={keyFigure}
              decimals={isDecimal ? 2 : 0}
              end={keyFigure || 0}
              suffix={suffix || ""}
              prefix={prefix || ""}
            >
              {({ countUpRef }) => (
                <Number
                  size={biggerSize}
                  ref={countUpRef as React.RefObject<HTMLDivElement>}
                >
                  {keyFigure}
                </Number>
              )}
            </CountUp>
          ) : (
            <Number size={biggerSize}>
              {prefix}
              {temporalKeyFigure}
              {suffix}
            </Number>
          ))}
        {percentage !== undefined &&
          (widthCalculated ? (
            <SignColorNumber
              number={percentage}
              animated={animated}
              biggerSize={biggerSize}
              start={start}
              delay={delay}
              suffix="%"
            ></SignColorNumber>
          ) : (
            <Percentage size={biggerSize}>
              {(Math.round(percentage * 100) / 100).toFixed(2) + "%"}
            </Percentage>
          ))}
      </NumbersContainer>

      {text && <Text> {text || ""}</Text>}
    </div>
  );
};

export default React.memo(KeyFigure);
