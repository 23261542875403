import React, { ReactElement } from "react";

import { NoBr, NoBrText } from "../style/components/NoBr";

export const appendComponentWithNoBr = (
  text: string | undefined,
  component: ReactElement,
  dangerouslySetInnerHTML = false
): ReactElement => {
  if (!text) return <></>;

  // Split the words by space as seperator
  const words = text.split(" ");
  const lastWord = words.pop();

  // Split the last word by &shy; as seperator
  const lastWordParts = lastWord?.split("&shy;");
  const lastWordPart = lastWordParts?.pop();

  // Join the last word parts with &shy; and push it back to the words array and join the words with space
  const firstTextPart = [
    ...words,
    lastWordParts?.join("&shy;") +
      (lastWord && lastWord.includes("&shy;") ? "&shy;" : ""), // Add &shy; if the last word contains it to add break option before NoBr
  ].join(" ");

  return (
    <>
      {dangerouslySetInnerHTML ? (
        <NoBr>
          {text && (
            <NoBrText
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          )}
          {component && <>&nbsp;{component}</>}
        </NoBr>
      ) : text && component ? (
        <>
          {firstTextPart || ""}
          <NoBr>
            {lastWordPart || ""}&nbsp;{component}
          </NoBr>
        </>
      ) : (
        text
      )}
    </>
  );
};
