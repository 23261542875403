import { css } from "styled-components";

import { ImageOverlay } from "types/Image";

const getGradient = (overlay: ImageOverlay) => {
  switch (overlay) {
    case "UpRight":
      return `var(--gradient-upright)`;

    case "UpLeft":
      return `var(--gradient-upleft)`;
    default:
      return "";
  }
};

const base = css`
  content: "";
  display: block;
  inset: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
`;

export const darken = () => css`
  ${base};
  background: var(--image-darken);
  z-index: 2;
`;

export const overlay = (overlay: ImageOverlay) => css`
  ${base};
  background: ${overlay && getGradient(overlay)};
  mix-blend-mode: color;
`;
