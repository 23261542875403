import { css } from "styled-components";

import { MQ } from "./mediaQueries";

export const GUTTER = {
  XL: "1.5rem",
  MD: "1rem",
  SM: "0.5rem",
};

export const GAP = {
  S: "0rem",
  M: "1rem",
  XL: "1.5rem",
  XXXL: "2rem",
};

export const PADDING = {
  S: "1rem",
  M: "1.5rem",
  XL: "2rem",
  XXXL: "4rem",
  XXXXL: "5rem",
};

export const gap = css`
  gap: ${GUTTER.SM};

  ${MQ.FROM_M} {
    gap: ${GUTTER.MD};
  }

  ${MQ.FROM_XL} {
    gap: ${GUTTER.XL};
  }
`;

export const columnGap = css`
  column-gap: ${GUTTER.SM};

  ${MQ.FROM_M} {
    column-gap: ${GUTTER.MD};
  }

  ${MQ.FROM_XL} {
    column-gap: ${GUTTER.XL};
  }
`;

export const gridCss = css`
  body {
    &::after {
      background-image: repeating-linear-gradient(
        to bottom,
        transparent 0,
        transparent 7px,
        rgba(0 0 0 / 10%) 7px,
        rgba(0 0 0 / 10%) 14px
      );
      content: "";
      display: block;
      height: 10000px;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100000;

      @media only screen and (max-width: 1365px) {
        background: none;
      }
    }

    &::before {
      background-image: repeating-linear-gradient(
        to right,
        rgba(0 0 0 / 10%) 0,
        rgba(0 0 0 / 10%) calc((100% - 616px) / 12),
        transparent calc((100% - 616px) / 12),
        transparent calc((100% - 616px) / 12 + 56px)
      );
      content: "";
      display: block;
      height: 10000px;
      left: calc((100% - 1568px) / 2);
      pointer-events: none;
      position: absolute;
      right: calc((100% - 1568px) / 2);
      top: 0;
      z-index: 100000;

      @media only screen and (max-width: 1679px) {
        left: 56px;
        right: 56px;
      }

      @media only screen and (max-width: 1365px) {
        background: none;
      }
    }
  }
`;
