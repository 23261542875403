import styled from "styled-components";
import theme from "styled-theming";

import { Home28, ArrowRight28 } from "icons";
import { default as NavLink } from "components/Link";
import { BodyM } from "style/components/Typography";
import { MQ } from "style/mediaQueries";
import { PADDING } from "style/grid";

const textColor = theme("theme", {
  default: "--color-black",
  white: "--color-black",
  lightgray: "--color-black",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

const borderColor = theme("theme", {
  default: "--color-black-o30",
  white: "--color-black-o30",
  lightgray: "--color-black-o30",
  blue: "--color-cyan-l60-o30",
  cyan: "--color-blue-o30",
});

const backgroundColor = theme("theme", {
  undefined: "transparent",
  default: "--color-black-o05",
  white: "--color-white",
  lightgray: "--color-black-l95",
  blue: "--color-blue-d10",
  cyan: "--color-cyan-l80",
  black: "--color-black",
});

const darkenColor = theme("theme", {
  white: "--color-white-o15",
  lightgray: "--color-black-o05",
  blue: "--color-black-o30",
  cyan: "--color-white-o15",
});

export const Section = styled.div`
  align-items: "center";
  background: var(${backgroundColor});
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &::after {
    background: var(${darkenColor});
    content: "";
    display: block;
    inset: 0;
    position: absolute;
  }
`;

export const Container = styled.div`
  align-self: center;
  text-align: left;
  width: 100%;
  padding: 0.875rem ${PADDING.S};

  ${MQ.FROM_M} {
    padding: 0.875rem ${PADDING.M};
  }
  ${MQ.FROM_XL} {
    padding: 1.3125rem ${PADDING.XL};
  }
  ${MQ.FROM_XXXL} {
    padding: 0.875rem ${PADDING.XXXL};
  }
  ${MQ.FROM_XXXXL} {
    padding: 0.875rem ${PADDING.XXXXL};
  }
`;

export const Links = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin: 0;
  overflow: auto;
  padding-inline-start: 0;
  width: 100%;

  ${MQ.FROM_M} {
    flex-wrap: wrap;
  }
`;

export const Arrow = styled(ArrowRight28)`
  color: var(${textColor});
  height: 1.75rem;
  margin-right: 0.375rem;
  width: 1.75rem;
`;

export const Home = styled(Home28)`
  color: inherit;
  height: 1.75rem;
  vertical-align: -0.5rem !important;
  width: 1.75rem;
`;

export const LinkWrapper = styled(BodyM)<LinkWrapperProps>`
  color: var(${textColor});
  list-style: none;
  white-space: nowrap;
  z-index: 1;
  ${(props) =>
    props.position === "middle" && props.collapseItems && `display:none;`}

  &:first-child {
    display: block;
  }

  ${MQ.FROM_M} {
    display: block;
  }
`;

export const Link = styled(NavLink)`
  && {
    border: none;
    color: var(${textColor});

    span {
      transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
      border-color: var(${borderColor});
    }

    &:hover {
      color: var(${textColor});

      span {
        border-bottom: 1px solid var(${textColor});
      }
    }
  }
`;

export const Collapsed = styled.button<CollapsedProps>`
  all: unset;
  cursor: pointer;
  display: ${(props) => (props.show ? "inline-block" : "none")};
  margin-left: 6px;
  white-space: nowrap;

  ${MQ.FROM_M} {
    display: none;
  }
`;

interface LinkWrapperProps {
  readonly position: string;
  readonly collapseItems: boolean;
}
interface CollapsedProps {
  readonly show: boolean;
}
