import styled, { css } from "styled-components";

import {
  styleBodyM,
  styleBodyS,
  styleHeadingM,
  styleHeadingS,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";
import theme from "styled-theming";

const textColor = theme("theme", {
  white: "var(--color-black-l40)",
  lightgray: "var(--color-black-l40)",
  cyan: "var(--color-blue-o60)",
  blue: "var(--color-cyan-l80)",
});

export const NumbersContainer = styled.div`
  display: flex;
  grid-column: col-start 1 / span 4;
  align-items: baseline;
`;

export const Number = styled.div<NumberProps>`
  ${styleHeadingS};
  ${(props) =>
    props.size === "M"
      ? css`
          ${MQ.FROM_L} {
            ${styleHeadingM}
          }
        `
      : css``}
`;

export const Text = styled.div`
  ${styleBodyS};
  grid-column: col-start 1 / span 4;
  grid-row: 3;
  color: ${textColor};
`;

export const Percentage = styled.span<PercentageProps>`
  ${styleBodyS};
  ${(props) =>
    props.size === "M"
      ? css`
          margin-left: 1rem;
          ${MQ.FROM_L} {
            ${styleBodyM};
            line-height: 1.5rem;
          }
        `
      : css`
          margin-left: 0.5rem;
        `}
`;

interface NumberProps {
  readonly size?: string;
}

interface PercentageProps {
  readonly size?: string;
}
