import * as React from "react";
const LongArrowRight35 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "70",
  height = "35",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 70 35"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M64.854 13.146a.5.5 0 0 0-.708.708L67.293 17H1.5a.5.5 0 0 0 0 1h65.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708z"
      clipRule="evenodd"
    />
  </svg>
);
export default LongArrowRight35;
