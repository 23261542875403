// @ts-ignore
import { SignColorNumber } from "ui-library";

export const argsProfile = {
  fullName: "Linda Andersson",
  email: "linda.andersson@soderbergpartners.se",
  initials: "LA",
  role: "Wealth Management rådgivare",
  phoneFixed: "+46 73 12 33 134",
  size: "medium" as const,
};

// export const carouselArgs = {
//   pages: [
//     {
//       id: "1",
//       name: "Page 1",
//       content: [
//         {
//           value: "<h2>Page 1</h2>",
//           type: "Static" as const,
//         },
//         {
//           value:
//             "<p>Lorem ipsum dolor sit <b>amet</b>, consectetur adipiscing elit. Duis egestas viverra felis, et vulputate leo porttitor id. Suspendisse rhoncus augue urna, sed ultrices diam auctor vitae. Suspendisse pharetra eu <em>at cursus</em>. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris sagittis vulputate tristique. Sed sit amet massa quis lorem mattis fringilla nec ut enim.</p>\n<p>Duis sodales ex ultricies elit facilisis, sed tincidunt urna rutrum.</p>",
//           type: "Static" as const,
//         },
//       ],
//     },
//     {
//       id: "2",
//       name: "Page 2",
//       content: [
//         {
//           value: "<h2>Page 2</h2>",
//           type: "Static" as const,
//         },
//         {
//           value:
//             "<p>Lorem ipsum dolor sit <b>amet</b>, consectetur adipiscing elit. Duis egestas viverra felis, et vulputate leo porttitor id. Suspendisse rhoncus augue urna, sed ultrices diam auctor vitae. Suspendisse pharetra eu <em>at cursus</em>. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris sagittis vulputate tristique. Sed sit amet massa quis lorem mattis fringilla nec ut enim.</p>\n<p>Duis sodales ex ultricies elit facilisis, sed tincidunt urna rutrum.</p>",
//           type: "Static" as const,
//         },
//       ],
//     },
//     {
//       id: "3",
//       name: "Page 3",
//       content: [
//         {
//           value: "<h2>Page 3</h2>",
//           type: "Static" as const,
//         },
//         {
//           value:
//             "<p>Lorem ipsum dolor sit <b>amet</b>, consectetur adipiscing elit. Duis egestas viverra felis, et vulputate leo porttitor id. Suspendisse rhoncus augue urna, sed ultrices diam auctor vitae. Suspendisse pharetra eu <em>at cursus</em>. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris sagittis vulputate tristique. Sed sit amet massa quis lorem mattis fringilla nec ut enim.</p>\n<p>Duis sodales ex ultricies elit facilisis, sed tincidunt urna rutrum.</p>",
//           type: "Static" as const,
//         },
//       ],
//     },
//   ],
//   contentTheme: "cyan" as const,
//   theme: "blue" as const,
//   height: 500,
// };

export const carouselWealthOnline = {
  pages: [
    {
      id: "1",
      name: "Page 1",
      content: [
        {
          value: {
            culture: "en-US" as const,
            icon: "money",
            text: "allocation",
            theme: "lightgray" as const,
            title: "Asset class",
            component: "Header",
          },
          type: "EmbeddedContent" as const,
        },
        {
          value: {
            culture: "en-US" as const,
            theme: "blue" as const,
            customOptions: {
              series: [
                {
                  dataLabels: {
                    enabled: false,
                  },
                  name: "Share",
                  type: "pie",
                  data: [
                    {
                      name: "Nordiska aktier",
                      y: 10,
                    },
                    {
                      name: "Globala aktier",
                      y: 40,
                    },
                    {
                      name: "Räntor",
                      y: 20,
                    },
                    {
                      name: "Alternativa",
                      y: 30,
                    },
                  ],
                },
              ],
              chart: {
                backgroundColor: "transparent",
              },
            },
            component: "PieChart",
          },
          type: "EmbeddedContent" as const,
        },
      ],
    },
    {
      id: "3",
      name: "Page 3",
      content: [
        {
          value: {
            culture: "en-US" as const,
            icon: "eco",
            text: "ESG score",
            theme: "lightgray" as const,
            title: "Sustainability",
            component: "Header",
          },
          type: "EmbeddedContent" as const,
        },
        {
          value: {
            culture: "en-US" as const,
            theme: "blue" as const,
            customOptions: {
              chart: {
                backgroundColor: "transparent",
              },
              colors: [
                "var(--color-traffic-green)",
                "var(--color-traffic-yellow)",
                "var(--color-traffic-red)",
              ],
              series: [
                {
                  dataLabels: {
                    enabled: false,
                  },
                  name: "Sustainability",
                  type: "pie",
                  data: [
                    {
                      name: "Good rating",
                      y: 58,
                    },
                    {
                      name: "Medium rating",
                      y: 32,
                    },
                    {
                      name: "Bad rating",
                      y: 10,
                    },
                  ],
                },
              ],
            },
            useSustainabilityIcon: true,
            component: "PieChart",
          },
          type: "EmbeddedContent" as const,
        },
      ],
    },
    {
      id: "4",
      name: "Page 4",
      content: [
        {
          value: {
            culture: "en-US" as const,
            icon: "globe",
            text: "regional distribution",
            theme: "lightgray" as const,
            title: "Top 10",
            component: "Header",
          },
          type: "EmbeddedContent" as const,
        },
        {
          value: {
            culture: "en-US" as const,
            customOptions: {
              chart: {
                backgroundColor: "transparent",
              },
              xAxis: {
                categories: [
                  "Sweden",
                  "Norway",
                  "USA",
                  "Canada",
                  "China",
                  "France",
                  "Japan",
                  "Germany",
                  "UK",
                  "Finland",
                ],
              },
              series: [
                {
                  dataLabels: {
                    enabled: false,
                  },
                  name: "Share",
                  type: "bar",
                  data: [
                    ["Sweden", 80],
                    ["Norway", 70],
                    ["USA", 60],
                    ["Canada", 50],
                    ["China", 40],
                    ["France", 30],
                    ["Japan", 20],
                    ["Germany", 15],
                    ["UK", 10],
                    ["Finland", 5],
                  ],
                },
              ],
            },
            component: "BarChart",
          },
          type: "EmbeddedContent" as const,
        },
      ],
    },
    // {
    //   id: "5",
    //   name: "Page 5",
    //   content: [
    //     {
    //       value: {
    //         title: "Insurance information",
    //         contentTheme: "cyan" as const,
    //         rows: [
    //           {
    //             key: "Policy Holder",
    //             value: "Ursula Pearson",
    //           },
    //           {
    //             key: "Insurance Number",
    //             value: "3492349-9394",
    //           },
    //           {
    //             key: "Insurance Name",
    //             value: "Villa Insurance",
    //           },
    //           {
    //             key: "Insurance Period",
    //             value: "12-02-2023 - 12-02-2024",
    //           },
    //           {
    //             key: "Actual cost",
    //             value: "100kr",
    //           },
    //           {
    //             key: "Today",
    //             value: SignColorNumber({
    //               number: -2.31,
    //               suffix: "%",
    //               biggerSize: "S",
    //               animated: false,
    //             }),
    //           },
    //           {
    //             key: "Bank account",
    //             value: "SE12939 939293921 29",
    //           },
    //         ],
    //         component: "List",
    //       },
    //       type: "EmbeddedContent" as const,
    //     },
    //   ],
    // },
  ],
  theme: "blue" as const,
  contentTheme: "cyan" as const,
  height: 550,
};


export const customOptionsPage1 = {
  culture: "en-US" as const,
  theme: "blue" as const,
  customOptions: {
    series: [
      {
        dataLabels: {
          enabled: false,
        },
        name: "Share",
        type: "pie",
        data: [
          {
            name: "Nordiska aktier",
            y: 10,
          },
          {
            name: "Globala aktier",
            y: 40,
          },
          {
            name: "Räntor",
            y: 20,
          },
          {
            name: "Alternativa",
            y: 30,
          },
        ],
      },
    ],
    chart: {
      backgroundColor: "transparent",
    },
  },
};
export const customOptionsPage2 = {  
  culture: "en-US" as const,
  theme: "blue" as const,
  customOptions: {
    chart: {
      backgroundColor: "transparent",
    },
    colors: [
      "var(--color-traffic-green)",
      "var(--color-traffic-yellow)",
      "var(--color-traffic-red)",
    ],
    series: [
      {
        dataLabels: {
          enabled: false,
        },
        name: "Sustainability",
        type: "pie",
        data: [
          {
            name: "Good rating",
            y: 58,
          },
          {
            name: "Medium rating",
            y: 32,
          },
          {
            name: "Bad rating",
            y: 10,
          },
        ],
      },
    ],
  },
  useSustainabilityIcon: true,
};

export const customOptionsPage3 = {  
  culture: "en-US" as const,
  customOptions: {
    chart: {
      backgroundColor: "transparent",
    },
    xAxis: {
      categories: [
        "Sweden",
        "Norway",
        "USA",
        "Canada",
        "China",
        "France",
        "Japan",
        "Germany",
        "UK",
        "Finland",
      ],
    },
    series: [
      {
        dataLabels: {
          enabled: false,
        },
        name: "Share",
        type: "bar",
        data: [
          ["Sweden", 80],
          ["Norway", 70],
          ["USA", 60],
          ["Canada", 50],
          ["China", 40],
          ["France", 30],
          ["Japan", 20],
          ["Germany", 15],
          ["UK", 10],
          ["Finland", 5],
        ],
      },
    ],
  },
  yAxisSuffix: "%"
};
