import * as React from "react";
const Office28 = ({
  customStyle = {
    verticalAlign: "middle",
  },
  width = "28",
  height = "28",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 28 28"
    style={customStyle}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.202 2.099a.5.5 0 0 1 .442-.078l10 3A.5.5 0 0 1 26 5.5v18a.5.5 0 0 1-.5.5h-22a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5H7V9.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5V23h7V5.872l-9-2.7V6.5a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .201-.401M7 14v9H4v-9zm1 9V10h9v13zm3.5-7a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm-.5 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m.5-6.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zM22 9.5a.5.5 0 0 0-1 0v10a.5.5 0 0 0 1 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default Office28;
