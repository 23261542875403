import React, { Children, ReactElement, useContext } from "react";

import {
  TittleText,
  Wrapper,
  HeadingContainer,
  TittleDescription,
  ProductCardContainer,
} from "./ProductCardSection.styled";
import ProductCardSectionProps from "./ProductCardSectionProps";
import { ThemeContext, ThemeProvider } from "styled-components";
import ProductCardList from "components/ProductCardList";
import getCardTheme from "utils/themeUtils";
import Skeleton from "components/Skeleton";

const ProductCardSection = ({
  id,
  theme,
  title,
  description,
  children,
  loading,
  contentId,
  className,
  anchorName,
}: ProductCardSectionProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};

  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
      }}
    >
      <Wrapper className={className} id={id || contentId || anchorName}>
        <HeadingContainer>
          {loading ? (
            <Skeleton variant="text" width={"200px"}>
              <TittleText>title</TittleText>
            </Skeleton>
          ) : (
            <TittleText>{title}</TittleText>
          )}
          {loading ? (
            <Skeleton variant="text" width={"300px"}>
              <TittleDescription>description</TittleDescription>
            </Skeleton>
          ) : (
            <TittleDescription>{description}</TittleDescription>
          )}
        </HeadingContainer>
        {loading
          ? Array.from(new Array(3)).map((child, index) => (
              <ProductCardContainer key={index}>
                <ProductCardList
                  theme={getCardTheme(theme)}
                  loading
                ></ProductCardList>
              </ProductCardContainer>
            ))
          : children &&
            Children.map(children, (child, index) => (
              <ProductCardContainer key={index}>
                <ProductCardList
                  theme={getCardTheme(theme)}
                  {...child.props}
                ></ProductCardList>
              </ProductCardContainer>
            ))}
      </Wrapper>
    </ThemeProvider>
  );
};

export default React.memo(ProductCardSection);
