import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import { Wrapper } from "./Section.styled";
import SectionProps from "./SectionProps";

const Section = ({
  id,
  theme,
  children,
  alignItems,
  ariaLabel,
  className,
  style,
}: SectionProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};

  return (
    <ThemeProvider theme={{ theme: theme || exitingTheme['theme'] }}>
      <Wrapper
        alignItems={alignItems}
        id={id}
        aria-label={ariaLabel}
        className={className}
        style={style}
      >
        {children}
      </Wrapper>
    </ThemeProvider>
  );
};

export default React.memo(Section);
