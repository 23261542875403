// Processing commands for images: https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html

import React, { ReactElement } from "react";

import { Caption, Darken, Figure } from "./Image.styled";
import ImageProps from "./ImageProps";

const Image = ({
  identifier,
  url,
  alt,
  decorative,
  overlay,
  darken,
  caption,
  cover,
  srcSet,
  className,
  maxWidth,
  lazyload = true,
  focalPoint = "0.5|0.5",
}: ImageProps): ReactElement => {
  const srcSetImage = srcSet?.filter((src) => !src.media).pop();
  const srcSetSources = srcSet?.filter((src) => !!src.media);
  const centerPosition = focalPoint
    .split("|")
    .map((num) => Number(num) * 100) as [number, number];

  if (caption) {
    darken = false;
    overlay = undefined;
  }
  return (
    <Figure
      id={identifier}
      key={identifier || `ImageContent`}
      overlay={overlay}
      darken={darken}
      cover={cover}
      className={className}
      maxWidth={maxWidth}
      hasCaption={!!caption}
      centerPosition={centerPosition}
    >
      <picture>
        {srcSetSources?.map((src, index) => (
          <source
            type="image/webp"
            media={src.media}
            srcSet={src.webpsrcset}
            key={`sourceWebp${index}`}
          />
        ))}
        {srcSetSources?.map((src, index) => (
          <source
            media={src.media}
            srcSet={src.srcset}
            key={`source${index}`}
          />
        ))}
        {srcSetImage?.webp && (
          <source key="webP" type="image/webp" srcSet={srcSetImage.webp} />
        )}
        {srcSetImage && (
          <img
            key="default"
            src={srcSetImage.src}
            srcSet={srcSetImage.srcset}
            alt={decorative ? "" : alt}
            loading={lazyload ? "lazy" : "eager"}
          />
        )}
        {!srcSetImage && url && <img src={url} alt={decorative ? "" : alt} />}
      </picture>
      {darken && <Darken />}
      {caption && (
        <Caption>
          <span>{caption}</span>
        </Caption>
      )}
    </Figure>
  );
};

export default React.memo(Image);
