import theme from "styled-theming";

import styled from "styled-components";
import { MQ } from "style/mediaQueries";
import { GAP, PADDING } from "style/grid";

const backgroundColor = theme("theme", {
  undefined: "transparent",
  default: "transparent",
  white: "var(--color-white)",
  lightgray: "var(--color-black-l95)",
  blue: "var(--color-blue-d10)",
  cyan: "var(--color-cyan-l80)",
  black: "var(--color-black)",
});

export const StyledContainer = styled.div<containerProps>`
  background-color: ${backgroundColor};
  width: 100%;
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  display: grid;
  grid-template-columns: repeat(4, [col-start] 1fr);
  ${(props) => props.hasPadding && `padding: ${PADDING.S};`}
  gap: ${GAP.S};

  ${MQ.FROM_M} {
    grid-template-columns: repeat(12, [col-start] 1fr);
    ${(props) => props.hasPadding && `padding: ${PADDING.M};`}
    gap: ${GAP.M};
  }

  ${MQ.FROM_XL} {
    ${(props) => props.hasPadding && `padding: 1.5rem ${PADDING.XL};`}
    gap: ${GAP.XL};
  }

  ${MQ.FROM_XXXL} {
    ${(props) => props.hasPadding && `padding: 2rem ${PADDING.XXXL};`}
    gap: ${GAP.XXXL};
  }

  ${MQ.FROM_XXXXL} {
    ${(props) => props.hasPadding && `padding: 2rem ${PADDING.XXXL};`}
  }

  > div {
    grid-column: 1 / span 12;
  }
`;

export interface containerProps {
  readonly textAlign?: "left" | "center" | "right";
  readonly hasPadding?: boolean;
}
