import styled from "styled-components";
import theme from "styled-theming";

interface SectionProps {
    readonly alignItems?:
        | "flex-start"
        | "flex-end"
        | "center"
        | "stretch"
        | "baseline";
}

const backgroundColor = theme("theme", {
    undefined: "transparent",
    default: "--color-black-o05",
    white: "--color-white",
    lightgray: "--color-black-l95",
    blue: "--color-blue-d10",
    cyan: "--color-cyan-l80",
    black: "--color-black",
});

const themeColor = theme("theme", {
    undefined: "--color-black",
    default: "--color-black",
    white: "--color-black",
    lightgray: "--color-black",
    blue: "--color-cyan-l60",
    cyan: "--color-blue",
    image: "--color-white",
});

export const Wrapper = styled.div`
    align-items: ${(props: SectionProps) => props.alignItems || "flex-start"};
    background: var(${backgroundColor});
    color: var(${themeColor});
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
`;
