import theme from "styled-theming";

import styled from "styled-components";
import { MQ } from "style/mediaQueries";

const backgroundColor = theme("theme", {
  undefined: "var(--color-white)",
  default: "var(--color-white)",
  transparent: "transparent",
  white: "var(--color-white)",
  lightgray: "var(--color-black-l95)",
  blue: "var(--color-blue-d10)",
  cyan: "var(--color-cyan-l80)",
  black: "var(--color-black)",
});

export const StyledCard = styled.div`
  background-color: ${backgroundColor};
  width: 100%;
  padding: 0.75rem;

  ${MQ.FROM_M} {
    padding: 1rem;
  }

  ${MQ.FROM_XXXL} {
    padding: 1.5rem;
  }
`;
