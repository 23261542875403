import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import PageCard from "components/PageCard";

import {
  CardWrapper,
  ContentContainer,
  PageCardItems,
  TitleHeading,
} from "./PageCardSection.styled";
import PageCardSectionProps from "./PageCardSectionProps";

const PageCardSection = ({
  id,
  theme = "lightgray",
  title,
  pageCardList,
  contentId,
  className,
  anchorName,
}: PageCardSectionProps): ReactElement => {
  return (
    <ThemeProvider theme={{ theme: theme }}>
      {pageCardList !== undefined && pageCardList.length > 0 && (
        <ContentContainer
          className={className}
          id={id || contentId || anchorName}
        >
          {title && <TitleHeading>{title}</TitleHeading>}
          <PageCardItems>
            {pageCardList?.map((cardData, index) => (
              <CardWrapper key={`PageCard${index}`}>
                <PageCard {...cardData} size="small" />
              </CardWrapper>
            ))}
          </PageCardItems>
        </ContentContainer>
      )}
    </ThemeProvider>
  );
};

export default React.memo(PageCardSection);
