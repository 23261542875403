import React, { Children, ReactElement, useContext } from "react";
import AccordionProps from "./AccordionProps";
import AccordionItem from "components/AccordionItem";
import { ThemeContext, ThemeProvider } from "styled-components";
import {
  Container,
  Heading,
} from "./Accordion.styled";

const Accordion = ({
  identifier,
  heading,
  label,
  theme,
  contentTheme,
  expandedButtonLeft = false,
  slim = false,
  styleVariant = "standard",
  children,
  contentId,
  className,
  anchorName
}: AccordionProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};

  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
        contentTheme: contentTheme || exitingTheme["contentTheme"],
      }}
    >
      <Container id={identifier || contentId || anchorName} className={className}>
        {heading && <Heading dangerouslySetInnerHTML={{ __html: heading }} />}
        {children &&
          Children.map(children, (accordionItem, index) => {
            return (
              <AccordionItem
                aria-label={label}
                key={`AccordionItem${index}`}
                {...accordionItem.props}
                expandedButtonLeft={
                  accordionItem.props.expandedButtonLeft || expandedButtonLeft
                }
                slim={accordionItem.props.slim || slim}
                styleVariant={accordionItem.props.styleVariant || styleVariant}
                id={
                  identifier
                    ? `${identifier}-${index}`
                    : `Accordion-Item-${index}`
                }
              />
            );
          })}
      </Container>
    </ThemeProvider>
  );
};

export default React.memo(Accordion);
