import styled from "styled-components";
import { Container as GridContainer } from "style/components/Page";
import { columnGap } from "style/grid";
import { styleBodyM, styleHeadingM } from "style/components/Typography";
import theme from "styled-theming";
import { MQ } from "style/mediaQueries";

const backgroundColor = theme("theme", {
  white: "var(--color-white)",
  lightgray: "var(--color-black-l95)",
  blue: "var(--color-blue-d10)",
});

const textColor = theme("theme", {
  undefined: "var(--color-black)",
  default: "var(--color-black)",
  white: "var(--color-black)",
  lightgray: "var(--color-black)",
  blue: "var(--color-cyan-l60)",
});

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  position: relative;
  width: 100%;
  background-color: ${backgroundColor};

`;

export const HeadingContainer = styled(GridContainer)`
  ${columnGap}
  grid-column: col-start 1 / span 12;
  width: 100%;
  padding: 0rem 0rem 1rem 0;
  ${MQ.FROM_M} {
    padding: 0rem 0rem 1.5rem 0;
  }
`;

export const TittleText = styled.div`
  ${styleHeadingM};
  color: ${textColor};

  ${MQ.FROM_M} {
    padding-bottom: 0.5rem;
  }
`;

export const TittleDescription = styled.div`
  ${styleBodyM};
  color: ${textColor};
`;

export const ProductCardContainer = styled.div`
  grid-column: col-start 1 / span 12;
  padding-bottom: 1rem;
`;
