import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";
import CardProps from "./CardProps";
import { StyledCard } from "./Card.styled";

const Card = ({
  children,
  theme,
  id,
  className,
  contentId,
  anchorName,
}: CardProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};

  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
      }}
    >
      <StyledCard className={className} id={id || contentId || anchorName}>
        {children}
      </StyledCard>
    </ThemeProvider>
  );
};

export default React.memo(Card);
