import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";
import ContainerProps from "./ContainerProps";
import { StyledContainer } from "./Container.styled";

const Container = ({
  children,
  theme,
  textAlign,
  id,
  hasPadding = true,
  className,
  contentId,
  anchorName,
}: ContainerProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};

  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
      }}
    >
      <StyledContainer
        className={className}
        hasPadding={hasPadding}
        id={id || contentId || anchorName}
        textAlign={textAlign}
      >
        {children}
      </StyledContainer>
    </ThemeProvider>
  );
};

export default React.memo(Container);
