import React, { ReactElement } from "react";
import PieChartProps from "./PieChartProps";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  GradientColorObject,
  PatternObject,
} from "highcharts/highstock";
import {
  LocalizationEnglish,
  LocalizationSwedish,
  HighChartsTheme,
  GetDefaultPieChartOptions,
} from "utils/settings";

import { merge } from "lodash";
import Skeleton from "components/Skeleton";
import { SkeletonSection } from "./PieChart.styled";

const PieChart = ({
  customOptions,
  culture,
  contentTheme = "blue",
  useSustainabilityIcon,
  loading,
  contentId,
  className,
  anchorName,
}: PieChartProps): ReactElement => {
  switch (culture) {
    case "en-US":
      HighChartsTheme.lang = LocalizationEnglish;
      break;
    case "sv-SE":
    default:
      HighChartsTheme.lang = LocalizationSwedish;
      break;
  }

  const defaultPieChartOptions = GetDefaultPieChartOptions(contentTheme);

  const leafIconSvg = (
    color: string | GradientColorObject | PatternObject | undefined
  ) => {
    return `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="${color}"
        d="M17 8C8 10 5.9 16.17 3.82 21.34l1.89.66l.95-2.3c.48.17.98.3 1.34.3C19 20 22 3 22 3c-1 2-8 2.25-13 3.25S2 11.5 2 13.5s1.75 3.75 1.75 3.75C7 8 17 8 17 8Z"
      />
    </svg>`;
  };

  const legendFormatter: Highcharts.Options = {
    legend: {
      symbolWidth: 0,
      symbolHeight: 0,
      symbolRadius: 0,
      squareSymbol: false,
      labelFormatter: function () {
        const point = this as Highcharts.Point;
        return `<div style="display: flex; align-items: center;">${leafIconSvg(
          point.color
        )} <span style="margin-left: 8px;">${
          point.name
        }</span> <span style="color: ${
          defaultPieChartOptions.percentageColor
        };font-size: 14px; font-weight: 500; margin-left: 5px; height: 25px;">${
          point.y
        }%</span></div>`;
      },
    },
  };

  const options: Highcharts.Options = merge(
    { ...defaultPieChartOptions.chartOptions },
    { ...customOptions },
    useSustainabilityIcon ? { ...legendFormatter } : {}
  );

  Highcharts.setOptions(HighChartsTheme);

  return loading ? (
    <SkeletonSection>
      <Skeleton variant="rectangular" width="100%" height="350px" />
    </SkeletonSection>
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"chart"}
      options={options}
      className={className}
      id={contentId || anchorName}
    />
  );
};

export default React.memo(PieChart);
