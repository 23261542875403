export const bannerArgs = {
  heading: "Asset & Wealth",
  text: "Welcome to your wealth & assets dashboard! This page provides an overview of your investment portfolio, including your company and private depots. Monitor your investments' performance, market value and read the latest analysis and news related to your profile.",
  contentTheme: "white" as const,
  backgroundImage: {
    url: "https://picsum.photos/2000/1000",
    decorative: true,
    overlay: "UpLeft" as const,
    darken: false,
  },
};

export const breadcrumbsArgs = {
  items: [
    { displayName: "Home", url: "/" },
    { displayName: "Asset & Wealth", url: "/" },
  ],
};

export const argsFooter = {
  logoTitle: "Logo title",
  navigation: {
    identifier: "footer",
    items: [
      {
        displayName: "Chick links",
        properties: {},
        items: [
          {
            displayName: "About Söderberg & Partners",
            url: "/",
            properties: {},
            items: [],
          },
          {
            displayName: "Legal information",
            url: "/",
            properties: {},
            items: [],
          },
        ],
      },
      {
        displayName: "My pages",
        properties: {},
        items: [
          {
            displayName: "My profile",
            url: "/",
            properties: {},
            items: [],
          },
          {
            displayName: "My documents",
            url: "/",
            properties: {},
            items: [],
          },
          {
            displayName: "My cases",
            url: "/",
            properties: {},
            items: [],
          },
        ],
      },
    ],
  },
  bottomLinks: {
    identifier: "bottomLinks",
    items: [
      {
        displayName: "Personal Data & Cookies",
        url: "/",
        properties: {
          title: "PersonalData",
        },
        items: [],
      },
      {
        displayName: "Update cookie consents",
        url: "/",
        properties: {
          title: "UpdateCookieConsents",
        },
      },
    ],
  },
};
