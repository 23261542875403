import styled from "styled-components";
import { Skeleton } from "@mui/material";
import theme from "styled-theming";
import Section from "components/Section";

const backgroundColor = theme("theme", {
  undefined: "var(--color-black-l90)",
  default: "var(--color-black-l95)",
  white: "var(--color-black-l95)",
  lightgray: "var(--color-black-l90)",
  blue: "var(--color-white-o50)",
  cyan: "var(--color-white-o50)",
});

export const StyledSkeleton = styled(Skeleton)`
  && {
    background-color: ${backgroundColor};
    ${({ variant }) =>
      variant !== "circular" &&
      variant !== "rounded" &&
      `border-radius: 0.25rem;`}
  }
`;

export const StackSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StoriesSection = styled(Section)`
  padding-left: 1rem;
`;
