import styled, { css } from "styled-components";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import theme from "styled-theming";
import { base } from "style/components/Typography";

const backgroundColor = theme("theme", {
  undefined: "transparent",
  default: "var(--color-black-o05)",
  white: "var(--color-white)",
  lightgray: "var(--color-black-l95)",
  blue: "var(--color-blue-d10)",
  cyan: "var(--color-cyan-l80)",
  black: "var(--color-black)",
});

export const iconColor = theme("contentTheme", {
  undefined: "inherit",
  white: "var(--color-white)",
  black: "var(--color-black)",
  lightgray: "var(--color-black)",
  cyan: "var(--color-cyan-l60)",
  blue: "var(--color-blue)",
  coral: "var(--color-coral)",
  image: "var(--color-white)",
});

export const borderColor = theme("contentTheme", {
  undefined: "var(--color-black-o15)",
  default: "var(--color-black-o15)",
  white: "var(--color-white-o15)",
  black: "var(--color-black-o15)",
  lightgray: "var(--color-black-o15)",
  cyan: "var(--color-cyan-o20)",
  blue: "var(--color-blue-o15)",
  image: "var(--color-white-o15)",
});

export const StyledAccordionItem = styled(MuiAccordion)<AccordionItemProps>`
  && {
    ${base}
    width: 100%;
    background: ${backgroundColor};

    ${(props) =>
      props.$variant === "outlined" &&
      css`
        border-top: 1px solid ${borderColor};
        &:last-of-type {
          border-bottom: 1px solid ${borderColor};
        }
      `}
  }

  &.MuiAccordion-root:before {
    display:none;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)<AccordionItemProps>`
  && {
    ${base}

    & > .MuiAccordionSummary-expandIconWrapper {
      color: ${iconColor};
    }

    ${(props) =>
      props.$slim &&
      css`
        padding-left: 0;

        & > .MuiAccordionSummary-content {
          margin: 0;
        }
      `}

    ${(props) =>
      props.$expandedButtonLeft &&
      css`
        flex-direction: row-reverse;
        & > .MuiAccordionSummary-expandIconWrapper {
          transform: rotate(-90deg);
        }
        & > .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
          transform: rotate(0deg);
        }
      `}
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)<AccordionItemProps>`
  && {
    ${base}

    ${(props) =>
      props.$slim &&
      css`
        && {
          padding: 0;
        }
      `}

      ${(props) =>
      props.$expandedButtonLeft &&
      css`
        && {
          padding-left: 2.75rem;
        }
      `}
  }
`;

interface AccordionItemProps {
  readonly $variant?: string;
  readonly $slim?: boolean;
  readonly $expandedButtonLeft?: boolean;
}
