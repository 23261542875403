import React, { ReactElement } from "react";

import Icon from "components/Icon";
import { Container, Text, Title } from "./Header.styled";
import HeaderProps from "./HeaderProps";
import Skeleton from "components/Skeleton";

const Header = ({
  className = "Header",
  id,
  title,
  text,
  icon,
  size = "M",
  bottomBorder = true,
  loading,
  contentId,
  anchorName,
}: HeaderProps): ReactElement => {
  return (
    <Container
      bottomBorder={bottomBorder}
      id={id || contentId || anchorName|| "Header"}
      className={className}
    >
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"56px"}
          height={"56px"}
        ></Skeleton>
      ) : (
        icon &&
        (typeof icon === "string" ? (
          <Icon key={`Icon-${icon}`} icon={icon + "56"} />
        ) : (
          (icon as React.ReactElement)
        ))
      )}
      {loading ? (
        <Skeleton variant="text" width={"200px"}>
          <Title> title</Title>
        </Skeleton>
      ) : (
        title && <Title> {title}</Title>
      )}
      {loading ? (
        <Skeleton variant="text" width={"100px"}>
          <Text size={size}> text</Text>
        </Skeleton>
      ) : (
        text && <Text size={size}> {text}</Text>
      )}
    </Container>
  );
};

export default React.memo(Header);
