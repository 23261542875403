import { css } from "styled-components";

import WhitneyBlackWoff from "./fonts/Whitney-Black_Web.woff";
import WhitneyBlackWoff2 from "./fonts/Whitney-Black_Web.woff2";
import WhitneyBlackItalicWoff from "./fonts/Whitney-BlackItal_Web.woff";
import WhitneyBlackItalicWoff2 from "./fonts/Whitney-BlackItal_Web.woff2";
import WhitneyBoldWoff from "./fonts/Whitney-Bold_Web.woff";
import WhitneyBoldWoff2 from "./fonts/Whitney-Bold_Web.woff2";
import WhitneyBoldItalicWoff from "./fonts/Whitney-BoldItal_Web.woff";
import WhitneyBoldItalicWoff2 from "./fonts/Whitney-BoldItal_Web.woff2";
import WhitneyBookWoff from "./fonts/Whitney-Book_Web.woff";
import WhitneyBookWoff2 from "./fonts/Whitney-Book_Web.woff2";
import WhitneyBookItalicWoff from "./fonts/Whitney-BookItal_Web.woff";
import WhitneyBookItalicWoff2 from "./fonts/Whitney-BookItal_Web.woff2";
import WhitneyLightWoff from "./fonts/Whitney-Light_Web.woff";
import WhitneyLightWoff2 from "./fonts/Whitney-Light_Web.woff2";
import WhitneyLightItalicWoff from "./fonts/Whitney-LightItal_Web.woff";
import WhitneyLightItalicWoff2 from "./fonts/Whitney-LightItal_Web.woff2";
import WhitneyMediumWoff from "./fonts/Whitney-Medium_Web.woff";
import WhitneyMediumWoff2 from "./fonts/Whitney-Medium_Web.woff2";
import WhitneyMediumItalicWoff from "./fonts/Whitney-MediumItal_Web.woff";
import WhitneyMediumItalicWoff2 from "./fonts/Whitney-MediumItal_Web.woff2";
import WhitneySemiboldWoff from "./fonts/Whitney-Semibld_Web.woff";
import WhitneySemiboldWoff2 from "./fonts/Whitney-Semibld_Web.woff2";
import WhitneySemiboldItalicWoff from "./fonts/Whitney-SemibldItal_Web.woff";
import WhitneySemiboldItalicWoff2 from "./fonts/Whitney-SemibldItal_Web.woff2";

export const fontFace = css`
    @font-face {
        font-family: Whitney;
        src: url(${WhitneyLightItalicWoff2}) format("woff2"),
            url(${WhitneyLightItalicWoff}) format("woff");
        font-weight: 300;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyLightWoff2}) format("woff2"),
            url(${WhitneyLightWoff}) format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyBookWoff2}) format("woff2"),
            url(${WhitneyBookWoff}) format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyBookItalicWoff2}) format("woff2"),
            url(${WhitneyBookItalicWoff}) format("woff");
        font-weight: 400;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyMediumItalicWoff2}) format("woff2"),
            url(${WhitneyMediumItalicWoff}) format("woff");
        font-weight: 500;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyMediumWoff2}) format("woff2"),
            url(${WhitneyMediumWoff}) format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneySemiboldItalicWoff2}) format("woff2"),
            url(${WhitneySemiboldItalicWoff}) format("woff");
        font-weight: 600;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneySemiboldWoff2}) format("woff2"),
            url(${WhitneySemiboldWoff}) format("woff");
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyBoldWoff2}) format("woff2"),
            url(${WhitneyBoldWoff}) format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyBoldItalicWoff2}) format("woff2"),
            url(${WhitneyBoldItalicWoff}) format("woff");
        font-weight: 700;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyBlackWoff2}) format("woff2"),
            url(${WhitneyBlackWoff}) format("woff");
        font-weight: 800;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: Whitney;
        src: url(${WhitneyBlackItalicWoff2}) format("woff2"),
            url(${WhitneyBlackItalicWoff}) format("woff");
        font-weight: 800;
        font-style: italic;
        font-display: block;
    }
`;
