import { Icon as IconifyIcon } from "@iconify/react";
import styled, { css } from "styled-components";
import theme from "styled-theming";

export const Logo = styled.img`
    height: 3rem;
    width: auto;
`;

export const BaseIcon = styled(IconifyIcon)`
  vertical-align: middle;
  fill: none;
  ${theme("theme", {
    image: css`
      filter: brightness(0) saturate(100%) invert(100%);
    `,
  })};
`;
