import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Icon from "components/Icon";

import { IconWrapper } from "./Watermark.styled";
import WatermarkProps from "./WatermarkProps";

const Watermark = ({
  icon,
  variant,
  contentId,
  className,
  anchorName,
}: WatermarkProps): ReactElement => {
  return (
    <ThemeProvider theme={{ variant: variant }}>
      <IconWrapper className={className} id={contentId || anchorName}>
        {typeof icon === "string" ? (
          <Icon icon={`${icon}`} />
        ) : (
          (icon as React.ReactElement)
        )}
      </IconWrapper>
    </ThemeProvider>
  );
};

export default React.memo(Watermark);
