import styled from "styled-components";
import theme from "styled-theming";
import { default as MaterialCarousel } from "react-material-ui-carousel";
import { MQ } from "style/mediaQueries";
import Card from "components/Card";

const contentColor = theme("theme", {
  black: "--color-white-o15",
  blue: "--color-cyan-o30",
  cyan: "--color-blue-o15",
  white: "--color-black-o15",
});

const sectionColor = theme("theme", {
  black: "--color-black-o15",
  blue: "--color-blue-d10",
  cyan: "--color-cyan-o30",
  white: "--color-white",
});

const textColor = theme("contentTheme", {
  black: "--color-black",
  blue: "--color-blue",
  cyan: "--color-cyan-l60",
  white: "--color-white",
});

export const Container = styled(Card)`
  width: 100%;
`;

export const PageContainer = styled.div`
  background-color: var(${contentColor});
  height: 100%;
  padding: 1rem;
  ${MQ.FROM_M} {
    padding: 1.5rem 3.75rem 1.5rem 3.75rem;
  }
`;

export const StyledCarousel = styled(MaterialCarousel)`
  .carousel-nav-button {
    background-color: var(${sectionColor});
    margin: 0 0.5rem;
    display: none;
    ${MQ.FROM_M} {
      display: flex;
    }
  }
  .carousel-indicator-button {
    transform: scale(0.8);
    padding: 0 0.75rem;
    stroke: var(${textColor});
    stroke-width: 2px;
    color: transparent;
  }
  .carousel-indicator-button-active {
    color: var(${textColor});
  }
`;
