import { Theme, ThemeOptions, createTheme } from "@mui/material/styles";
import { FONT } from "style/components/GlobalStyle";
import { deepmerge } from "@mui/utils";
import { BREAKPOINT_NUMBER } from "style/breakpoints";

const useMaterialUIBaseTheme = (): Theme => {
    // Adding these color codes because there is a lot of hidden functionality that we do not style, 
    // and this will change the palette at least a bit, to use our greyscale and disable the ripple effect. 

    const cssVar = (name: string) =>
        getComputedStyle(document.documentElement).getPropertyValue(name).trim();

    const baseTheme = createTheme({
        breakpoints: {
            values: {
                // default
                xs: 0,
                sm: 320,
                md: 768,
                lg: 992,
                xl: 1365,
    
                // S&P
                ...BREAKPOINT_NUMBER
            },
        }, 
        typography: {
            fontFamily: FONT.WHITNEY
        },
        
        palette: {
            grey: {
                "100": cssVar("--color-black-l95"),
                "200": cssVar("--color-black-l95"),
                "300": cssVar("--color-black-l95"),
                "400": cssVar("--color-black-l90"),
                "500": cssVar("--color-black-l95"),
                "600": cssVar("--color-black-l95"),
                "700": cssVar("--color-black-l95"),
                "800": cssVar("--color-black-l70"),
                "900": cssVar("--color-black-l70")
            }
        },
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true
                }
            },
        }
    });

    const themeOptions: ThemeOptions = {
        typography: {
            "heading-xxl": {
                fontWeight: "800",
                letterSpacing: "-0.01em",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "3.0625rem",
                    lineHeight: "3.0625rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "6.125rem",
                    lineHeight: "6.125rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "8.75rem",
                    lineHeight: "8.75rem",
                },
            },
            "heading-xxl-alt": {
                fontWeight: "800",
                letterSpacing: "-0.01em",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "3.0625rem",
                    lineHeight: "3.0625rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "5.25rem",
                    lineHeight: "5.25rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "7rem",
                    lineHeight: "7rem",
                },
            },
            "heading-xl": {
                fontWeight: "800",
                letterSpacing: "-0.01em",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "2.1875rem",
                    lineHeight: "2.1875rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "3.9375rem",
                    lineHeight: "3.9375rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "5.25rem",
                    lineHeight: "5.25rem",
                },
            },
            "heading-l": {
                fontWeight: "800",
                letterSpacing: "-0.01em",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "1.75rem",
                    lineHeight: "1.75rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "2.375rem",
                    lineHeight: "2.625rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "3.25rem",
                    lineHeight: "3.5rem",
                },
            },
            "heading-m": {
                fontWeight: "700",
                letterSpacing: "-0.01em",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "1.375rem",
                    lineHeight: "1.75rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "1.5rem",
                    lineHeight: "1.75rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "1.9375rem",
                    lineHeight: "2.1875rem",
                },
            },
            "heading-s": {
                fontWeight: "700",
                lineHeight: "1.75rem",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "1.125rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "1.1875rem;",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "1.25rem",
                },
            },
            "quote-l": {
                fontWeight: "800",
                fontStyle: "italic",
                letterSpacing: "-0.01em",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "1.75rem",
                    lineHeight: "1.75rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "2.375rem",
                    lineHeight: "2.625rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "3.25rem",
                    lineHeight: "3.5rem",
                },
            },
            "quote-m": {
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "-0.01em",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "1.375rem",
                    lineHeight: "1.75rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "1.5rem",
                    lineHeight: "1.75rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "1.9375rem",
                    lineHeight: "2.1875rem",
                },
            },
            "body-l": {
                fontWeight: "400",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "1.375rem",
                    lineHeight: "1.75rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "1.5rem",
                    lineHeight: "1.75rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "1.9375rem",
                    lineHeight: "2.1875rem",
                },
            },
            "body-l-alt": {
                fontWeight: "400",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "1.375rem",
                    lineHeight: "1.75rem",
                    textIndent: "4.125rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "1.5rem",
                    lineHeight: "1.75rem",
                    textIndent: "6rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "1.9375rem",
                    lineHeight: "2.1875rem",
                    textIndent: "7.75rem",
                },
            },
            "body-m": {
                fontWeight: "400",
                lineHeight: "1.75rem",
                [baseTheme.breakpoints.down("M")]: {
                    fontSize: "1.125rem",
                },
                [baseTheme.breakpoints.between("M", "XL")]: {
                    fontSize: "1.1875rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "1.25rem",
                },
            },
            "body-s": {
                fontWeight: "400",
                lineHeight: "1.3125rem",
                fontSize: "1rem",
            },
            "body-s-spaced": {
                fontWeight: "400",
                lineHeight: "1.75rem",
                fontSize: "1rem",
            },
            "body-xs": {
                fontSize: "0.875rem",
                lineHeight: "1.05rem",
                fontWeight: "300",
            },
            "label-m": {
                fontWeight: "700",
                letterSpacing: "0.10em",                
                lineHeight: "1.75rem",
                textTransform: "uppercase",
                [baseTheme.breakpoints.down("XL")]: {
                    fontSize: "0.8125rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "0.875rem",
                },
            },
            "label-s": {
                fontWeight: "500",
                letterSpacing: "0.10em",                
                lineHeight: "1.75rem",
                textTransform: "uppercase",
                [baseTheme.breakpoints.down("XL")]: {
                    fontSize: "0.8125rem",
                },
                [baseTheme.breakpoints.up("XL")]: {
                    fontSize: "0.875rem",
                },
            },
        },
    };

    return createTheme(deepmerge(baseTheme, themeOptions));
};

export default useMaterialUIBaseTheme;
