/* stylelint-disable selector-class-pattern */
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import styled, { css } from "styled-components";
import theme from "styled-theming";

import { Error28, Select28 } from "icons";
import {
  styleBodyM,
  styleBodyS,
  styleBodySSpaced,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const labelColor = theme("theme", {
  undefined: "--color-black",
  lightgray: "--color-black",
  white: "--color-black",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

const labelFocus = theme("theme", {
  undefined: "--color-black-o60",
  lightgray: "--color-black-o60",
  white: "--color-black-o60",
  blue: "--color-cyan-l60-o60",
  cyan: "--color-blue-o60",
});

const textDisabled = theme("theme", {
  undefined: "--color-black-o30",
  lightgray: "--color-black-o30",
  white: "--color-black-o30",
  blue: "--color-cyan-l60-o30",
  cyan: "--color-blue-o30",
});

const textColor = theme("theme", {
  undefined: "--color-black",
  lightgray: "--color-black",
  white: "--color-black",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

const borderColor = theme("theme", {
  undefined: "--color-blue-o30",
  lightgray: "--color-blue-o30",
  white: "--color-blue-o30",
  blue: "--color-cyan-l60-o30",
  cyan: "--color-blue-o30",
});

const borderHover = theme("theme", {
  undefined: "--color-cyan-l40",
  lightgray: "--color-cyan-l40",
  white: "--color-cyan-l40",
  blue: "--color-cyan",
  cyan: "--color-cyan",
});

const errorColor = theme("theme", {
  undefined: "--color-coral-d20",
  lightgray: "--color-coral-d20",
  white: "--color-coral-d10",
  blue: "--color-coral",
  cyan: "--color-coral-d25",
});

const borderDisabled = theme("theme", {
  undefined: "--color-black-o15",
  lightgray: "--color-black-o15",
  white: "--color-black-o15",
  blue: "--color-cyan-l60-o20",
  cyan: "--color-blue-o15",
});

export const Wrapper = styled(FormControl)`
  && {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0;
    grid-column: col-start 1 / span 4;
    width: 100%;

    ${MQ.FROM_M} {
      grid-column: ${theme("displayOption", {
        Full: "auto / span 6",
        Half: "auto / span 3",
        OneThird: "auto / span 2",
        TwoThirds: "auto / span 4",
      })};
    }
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Label = styled(InputLabel)`
  && {
    &.MuiInputLabel {
      &-root {
        ${(props) => {
          if (props.size === "small")
            return css`
              ${styleBodySSpaced}
            `;
          else
            return css`
              ${styleBodyM}
            `;
        }}
        line-height: 2rem;
        color: var(${labelColor});

        &.Mui-focused {
          ${(props) => {
            if (props.size === "small")
              return css`
                ${styleBodySSpaced}
              `;
            else
              return css`
                ${styleBodyM}
              `;
          }}
          line-height: 2rem;
          color: var(${labelFocus});
        }

        &.Mui-disabled {
          color: var(${textDisabled});
        }
      }

      &-shrink {
        ${(props) => {
          if (props.size === "small")
            return css`
              ${styleBodySSpaced}
            `;
          else
            return css`
              ${styleBodyM}
            `;
        }}
        line-height: 2rem;
        color: var(${labelFocus});
    }
  }
`;

export const StyledSelect = styled(MuiSelect)`
  && {
    padding-right: 0;
    width: 100%;

    .MuiOutlinedInput {
      &-notchedOutline {
        border-color: var(${borderColor});
        legend {
          ${(props) => {
            if (props.size === "small")
              return css`
                font-size: 0.7rem;
              `;
            else
              return css`
                font-size: 0.875rem;
              `;
          }}
        }
      }
    }

    &:hover {
      &:not(.Mui-disabled, .Mui-error) {
        .MuiOutlinedInput {
          &-notchedOutline {
            border-color: var(${borderHover});
          }
        }
      }
    }

    &.Mui-error.Mui-focused,
    &.Mui-error {
      .MuiOutlinedInput {
        &-notchedOutline {
          border-color: var(${errorColor});
        }
      }

      .MuiInputBase-input {
        padding-right: 6.375rem !important;
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput {
        &-notchedOutline {
          border-color: var(${borderHover});
          border-width: 2px;
        }
      }
    }

    &.Mui-disabled {
      .MuiOutlinedInput {
        &-notchedOutline {
          border-color: var(${borderDisabled});
        }
      }
    }

    .MuiInputBase {
      &-input {
        ${(props) => {
          if (props.size === "small")
            return css`
              ${styleBodyS}
              padding-top:0.90625rem;
              padding-bottom: 0.90625rem;
            `;
          else
            return css`
              ${styleBodyM}
              height: 2rem;
            `;
        }}
        color: var(${textColor});
      }
    }

    .MuiPaper-root {
      background-color: var(--color-black-l95);
      border: 1px solid var(--color-black-o30);
      border-top: none;
    }
  }
`;

export const SelectItem = styled(MenuItem)<MenuItemsProps>`
  && {
    ${(props) => {
      if (props.size === "small")
        return css`
          ${styleBodyS}
        `;
      else
        return css`
          ${styleBodyM}
        `;
    }}
    &>div {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover,
    &:hover.Mui-selected {
      background-color: var(--color-black-l95);
    }

    &.Mui-selected {
      background-color: var(--color-cyan-o20);
    }
  }
`;

export const SelectAdornment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.44rem;
  margin-left: 0.4375rem;
  margin-right: 0.88rem;
  pointer-events: none;
  position: absolute;
  right: 0;
`;

export const SelectIcon = styled(Select28)`
  color: var(${labelColor});
  transition: transform 0.2s ease-in-out;
  ${theme("open", {
    true: css`
      transform: rotate3d(1, 0, 0, 180deg);
    `,
  })}
`;

export const ErrorIcon = styled(Error28)`
  color: var(${errorColor});
`;

export const ErrorText = styled(FormHelperText)`
  && {
    ${styleBodyS};
    color: var(${errorColor});
    margin: 0.4375rem 0 0;

    &.Mui-disabled {
      color: var(${textDisabled});
    }
  }
`;

export const HelperText = styled(FormHelperText)`
  && {
    ${styleBodyS};
    color: var(${labelFocus});
    margin: 0.4375rem 0 0;

    &.Mui-disabled {
      color: var(${textDisabled});
    }
  }
`;

interface MenuItemsProps {
  size: "small" | "medium";
}
