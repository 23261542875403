import React, { ReactElement, useContext } from "react";

import KeyFigure from "components/KeyFigure";
import Section from "components/Section";

import { Container, KeyFigures } from "./KeyFigureSection.styled";
import KeyFigureSectionProps from "./KeyFigureSectionProps";
import { ThemeContext, ThemeProvider } from "styled-components";

const KeyFigureSection = ({
  identifier,
  theme,
  keyFigures,
  anchorName,
  display = "grid",
}: KeyFigureSectionProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};
  return (
    <Section id={anchorName} theme={theme || exitingTheme["theme"]} alignItems="flex-start">
      <ThemeProvider
        theme={{
          theme: theme || exitingTheme["theme"]
        }}
      >
        <Container key={identifier || `KeyFigureSectionContent`}>
          <KeyFigures display={display}>
            {keyFigures?.map((keyFigure, index) => (
              <KeyFigure
                key={`KeyFigure${index}`}
                contentId={
                  anchorName
                    ? `${anchorName}-${index}`
                    : identifier
                    ? `${identifier}-${index}`
                    : ""
                }
                delay={(index + 1) * 0.2}
                biggerSize={display === "grid" ? "S" : "M"}
                {...keyFigure}
              />
            ))}
          </KeyFigures>
        </Container>
      </ThemeProvider>
    </Section>
  );
};

export default React.memo(KeyFigureSection);
