import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";
import Icon from "components/Icon";

import {
  Container,
  Section,
  ToggleBtn,
  ToggleButtonGroup,
  ToggleButtonPill,
  ToggleButtonWrapper,
} from "./ToggleButton.styled";
import ToggleButtonProps from "./ToggleButtonProps";

const ToggleButton = ({
  identifier,
  items,
  className,
  variant = "default",
  theme = "lightgray",
  contentId,
  anchorName,
}: ToggleButtonProps): ReactElement => {
  return (
    <ThemeProvider theme={{ theme: theme, variant: variant }}>
      <Section className={className} id={contentId || anchorName}>
        <Container
          role="navigation"
          aria-label="ToggleButton"
          key={identifier || `ToggleButtonContent`}
        >
          <ToggleButtonGroup as="ol">
            {items?.map((item, index) => (
              <ToggleButtonWrapper as="li" key={`item-${index}`}>
                {variant === "default" ? (
                  <ToggleBtn
                    title={item.displayName}
                    selected={item.selected}
                    onClick={item.onClick}
                  >
                    {item.icon &&
                      (typeof item.icon === "string" ? (
                        <Icon icon={`${item?.icon}28`}></Icon>
                      ) : (
                        (item.icon as React.ReactElement)
                      ))}
                    <span>{item.displayName}</span>
                  </ToggleBtn>
                ) : (
                  <ToggleButtonPill
                    title={item.displayName}
                    onClick={item.onClick}
                    selected={item.selected}
                  >
                    <span>{item.displayName}</span>
                  </ToggleButtonPill>
                )}
              </ToggleButtonWrapper>
            ))}
          </ToggleButtonGroup>
        </Container>
      </Section>
    </ThemeProvider>
  );
};

export default React.memo(ToggleButton);
