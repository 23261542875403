import React, { ReactElement } from "react";
import BarChartProps from "./BarChartProps";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
  LocalizationEnglish,
  LocalizationSwedish,
  HighChartsTheme,
  DefaultBarChartOptions,
} from "utils/settings";

import { merge } from "lodash";
import Skeleton from "components/Skeleton";
import { SkeletonSection } from "./BarChart.styled";

const BarChart = ({
  customOptions,
  culture,
  loading,
  yAxisSuffix = "",
  contentId,
  className,
  anchorName,
}: BarChartProps): ReactElement => {
  switch (culture) {
    case "en-US":
      HighChartsTheme.lang = LocalizationEnglish;
      break;
    case "sv-SE":
    default:
      HighChartsTheme.lang = LocalizationSwedish;
      break;
  }

  const options: Highcharts.Options = merge(
    { ...DefaultBarChartOptions(yAxisSuffix) },
    { ...customOptions }
  );

  Highcharts.setOptions(HighChartsTheme);

  return loading ? (
    <SkeletonSection>
      <Skeleton variant="rectangular" width="100%" height="350px" />
    </SkeletonSection>
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"chart"}
      options={options}
      id={contentId || contentId || anchorName}
      className={className}
    />
  );
};

export default React.memo(BarChart);
