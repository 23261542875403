import { BREAKPOINT } from "./breakpoints";

export const MQ = {
  FROM_M: `@media (min-width: ${BREAKPOINT.M})`,
  FROM_L: `@media (min-width: ${BREAKPOINT.L})`,
  FROM_XL: `@media (min-width: ${BREAKPOINT.XL})`,
  FROM_XXL: `@media (min-width: ${BREAKPOINT.XXL})`,
  FROM_XXXL: `@media (min-width: ${BREAKPOINT.XXXL})`,
  FROM_XXXXL: `@media (min-width: ${BREAKPOINT.XXXXL})`,
};
